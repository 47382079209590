import React, { Component } from 'react';
import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import helper_fn from '../../utils/helper'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import { Link, Redirect } from "react-router-dom"
import ReactTable from "react-table";
import _ from "underscore"
import moment from 'moment';
import 'moment-timezone';
import 'react-table/react-table.css'
import RelatorioStatus from '../../components/RelatorioStatus';

class Status extends Component {

    state = {
        detalhes: { cliente: [], monitorado: [], monitorados: [{ nome: "", id: "" }], horimetro: 0, 'reportes': [{ data_hora_dispositivo: '', 'codigo_produto': 0 }], entradasanalogicas: [], entradasdigitais: [], camposcustomizados: [], SLA: 0 },
        charts: [],
        automacao: {
            selected: [],
            selectAll: 0,
            data: [],
            pages: null,
            loading: true,
            filtered: true,
            enableDeletar: false,
            enableEditar: false,
            enableAtualizarOrdem: false,
            redirectToEdit: false,
            ordenacao: []
        },
        alerta: {
            selected: [],
            selectAll: 0,
            data: [],
            pages: null,
            loading: true,
            filtered: true,
            enableDeletar: false,
            enableEditar: false,
            redirectToEdit: false
        },
        campoCustomizado: {
            selected: [],
            selectAll: 0,
            data: [],
            pages: null,
            loading: true,
            filtered: true,
            enableDeletar: false,
            enableEditar: false,
            redirectToEdit: false
        },
        entradas: { entradasDigitais: [], entradasAnalogicas: [] },
        tabIndex: 0,
        intervalFn: false,
        existem_dados_eletricos: false,
        dict_fields: {
            "tensao_trifasica": "Tensão Trifásica",
            "tensao": "Tensão",
            "tensao_fase_fase": "Tensão Fase Fase",
            "tensao_fase_neutro": "Tensão Fase Neutro",
            "potencia_ativa_trifasica": "Potência Ativa Trifásica",
            "demanda_ativa": "Demanda Ativa",
            "potencia_reativa_trifasica": "Potência Reativa Trifásica",
            "frequencia": "Frequência",
            "energia_ativa": "Energia Ativa",
            "fator_potencia_trifasica": "Fator de Potência Trifásica",
            "corrente_trifasica": "Corrente Trifásica",
            "corrente": "Corrente",
            "potencia_aparente_trifasica": "Potência Aparente Trifásica",
            "energia_negativa": "Energia Negativa",
            "fator_potencia": "Fator de Potência",
            "sinal": "Sinal",
            "potencia_ativa_linha": "Potência Ativa Linha",
            "potencia_reativa_linha": "Potência Reativa Linha",
            "potencia_aparente_linha": "Potência Aparente Linha",
            "fator_potencia_linha": "Fator Potência Linha",
            "thd_tensao_fase": "THD Tensão Fase",
            "thd_corrente_fase": "THD Corrente Fase",
            "energia_ativa_negativa": "Energia Ativa Negativa",
            "corrente_neutro": "Corrente Neutro",
            "frequencia_iec_10s": "Frequência IEC 10S",
            "energia_reativa": "Energia Reativa",
            "energia_reativa_negativa": "Energia Reativa Negativa",
            "maxima_demanda_ativa": "Máxima Demanda Ativa",
            "maxima_demanda_aparente": "Máxima Demanda Aparente",
            "demanda_aparente": "Demanda Aparente",
            "status_carga": "Status Carga",
            "codigo_erro": "Código de Erro",
            "temperatura": "Temperatura",
            "consumo_reativo_acumulado": "Consumo Reativo Acumulado",
            "consumo_reativo_total": "Consumo Reativo",
            "consumo_ativo_acumulado": "Consumo Ativo Acumulado",
            "consumo_ativo_total": "Consumo Ativo Total"
        },
    }

    converte_data_timezone = (data_utc) => {
        let data_in_timezone_user = moment.tz(data_utc, 'UTC')
        let data_formatada = data_in_timezone_user.tz(auth.getUserInfo().timezone).format('DD/MM/YYYY HH:mm:ss')
        return data_formatada
    }

    convert_seconds_to_hours = (horimetro) => {
        let seconds_moment = moment.duration(horimetro, 'seconds')
        let horas = (seconds_moment.hours() <= 9) ? '0' + seconds_moment.hours() : seconds_moment.hours()
        let minutos = (seconds_moment.minutes() <= 9) ? '0' + seconds_moment.minutes() : seconds_moment.minutes()
        let segundos = (seconds_moment.seconds() <= 9) ? '0' + seconds_moment.seconds() : seconds_moment.seconds()

        let formatted_hour = horas + ':' + minutos + ':' + segundos
        return formatted_hour
    }

    componentDidMount = () => {
        if (this.props.location.state) {
            let tabIndex = ("tabIndex" in this.props.location.state) ? this.props.location.state.tabIndex : 0
            this.setState({ 'tabIndex': tabIndex })
        }

        let intervalFn = setInterval(this.buscar_dados.bind(this), 30000);
        this.setState({ 'intervalFn': intervalFn })
        this.buscar_dados()
    }

    componentWillUnmount = () => {
        clearInterval(this.state.intervalFn)
    }

    buscar_dados = () => {
        let userInfo = auth.getUserInfo();
        let requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/equipamento/' + this.props.match.params.imei + '/status/';

        axios({
            method: 'GET',
            url: requestURL,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                response.data['monitorado'] = []
                response.data.monitorados.forEach((v, i) => {
                    response.data['monitorado'].push(v.nome)
                })

                let reportes = JSON.parse(JSON.stringify(response.data.reportes));
                if (reportes) {
                    reportes.reverse()
                } else {
                    reportes = []
                    response.data.reportes = []
                }

                for (let index = 0; index < reportes.length; index++) {
                    delete reportes[index]['codigo_produto']
                    delete reportes[index]['dia_sem']
                    delete reportes[index]['tendencias']
                    delete reportes[index]['timestamp_dispositivo']
                    delete reportes[index]['timestamp_servidor']
                    delete reportes[index]['versao']
                    delete reportes[index]['rele']
                    delete reportes[index]['id_dispositivo']
                    reportes[index]['data_hora_dispositivo'] = this.converte_data_timezone(reportes[index]['data_hora_dispositivo']).split(' ')[1]
                }

                let campos_energia = [
                    { key: 'tensao', type: 'array' },
                    { key: 'tensao_fase_fase', type: 'array' },
                    { key: 'tensao_fase_neutro', type: 'array' },
                    { key: 'corrente', type: 'array' },
                    { key: 'potencia_ativa_linha', type: 'array' },
                    { key: 'potencia_reativa_linha', type: 'array' },
                    { key: 'potencia_aparente_linha', type: 'array' },
                    { key: 'fator_potencia_linha', type: 'array' },
                    { key: 'thd_tensao_fase', type: 'array' },
                    { key: 'thd_corrente_fase', type: 'array' },
                    { key: 'frequencia', type: 'int' },
                    { key: 'tensao_trifasica', type: 'int' },
                    { key: 'potencia_ativa_trifasica', type: 'int' },
                    { key: 'potencia_reativa_trifasica', type: 'int' },
                    { key: 'demanda_ativa', type: 'int' },
                    { key: 'corrente_trifasica', type: 'int' },
                    { key: 'fator_potencia_trifasica', type: 'int' },
                    { key: 'fator_potencia', type: 'int' },
                    { key: 'energia_ativa', type: 'int' },
                    { key: 'energia_ativa_negativa', type: 'int' },
                    { key: 'corrente_neutro', type: 'int' },
                    { key: 'potencia_aparente_trifasica', type: 'int' },
                    { key: 'frequencia_iec_10s', type: 'int' },
                    { key: 'energia_reativa', type: 'int' },
                    { key: 'energia_reativa_negativa', type: 'int' },
                    { key: 'maxima_demanda_ativa', type: 'int' },
                    { key: 'maxima_demanda_aparente', type: 'int' },
                    { key: 'demanda_aparente', type: 'int' },
                    { key: 'status_carga', type: 'int' },
                ]

                let reporte = reportes[0]
                // let teste = response.data
                // teste['reportes'][0]['sinal'] = 54
                // teste['reportes'][0]['alimentacao'] = '1'
                // teste['reportes'][0]['voltagem_bateria'] = '411'


                let dados_eletricos = true
                let existe_um_campo = campos_energia.filter((obj) => {
                    if (obj.key in reporte) {
                        return true
                    }
                    return false
                })

                if (existe_um_campo.length === 0)
                    dados_eletricos = false

                let entradas = { entradasDigitais: [], entradasAnalogicas: [] }
                entradas['entradasDigitais'] = response.data?.entradasdigitais?.map(entrada => {
                    return {
                        label: entrada?.descricao, value: entrada?.canal
                    }
                })
                entradas['entradasAnalogicas'] = response.data?.entradasanalogicas?.map(entrada => {
                    return {
                        label: entrada?.descricao, value: entrada?.canal
                    }
                })

                this.setState({ detalhes: response.data, charts: reportes, existem_dados_eletricos: dados_eletricos, entradas })
            })
            .catch((error) => console.log(error))
    }

    generateTableRowAnalogica = () => {
        return this.state.detalhes.reportes.map((reporte, idx) => {
            return <tr key={idx}>
                <td>
                    <table className="hdv-statustable-innertable">
                        {(idx === 0)
                            ?
                            <thead>
                                <tr>
                                    <td>Data e Hora da Comunicação</td>
                                </tr>
                            </thead>
                            :
                            null
                        }
                        <tbody>
                            <tr>
                                <td>{this.converte_data_timezone(reporte.data_hora_dispositivo)}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                {
                    this.state.detalhes.entradasanalogicas.map((entrada, idx_entrada) => {
                        return <td key={idx_entrada}>
                            <table className="hdv-statustable-innertable">
                                {(idx === 0)
                                    ?
                                    <thead>
                                        <tr>
                                            <td>Tendência</td>
                                            <td>Valor</td>
                                        </tr>
                                    </thead>
                                    :
                                    null
                                }
                                <tbody>
                                    <tr>
                                        <td>{(entrada.sinal_id == 9)
                                            ?
                                            (reporte.tendencias[0][(entrada.canal - 1)] === "neutro") ? <span className="hdv-status-neutro">Neutro</span> : (reporte.tendencias[0][(entrada.canal - 1)] === "subindo") ? <span className="hdv-status-desligado">Descer</span> : <span className="hdv-status-ligado">Subir</span>
                                            :
                                            (reporte.tendencias[0][(entrada.canal - 1)] === "neutro") ? <span className="hdv-status-neutro">Neutro</span> : (reporte.tendencias[0][(entrada.canal - 1)] === "subindo") ? <span className="hdv-status-ligado">Subir</span> : <span className="hdv-status-desligado">Descer</span>
                                            }</td>
                                        <td>{reporte.entradas_4a20[entrada.canal - 1].toFixed(2) + ' ' + entrada.unidade__descricao}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    })
                }
            </tr>
        })
    }

    generateTableRowDigital = () => {
        return this.state.detalhes.reportes.map((reporte, idx) => {
            return <tr key={idx}>
                <td>
                    <table className="hdv-statustable-innertable">
                        {(idx === 0)
                            ?
                            <thead>
                                <tr>
                                    <td>Data e Hora da Comunicação</td>
                                </tr>
                            </thead>
                            :
                            null
                        }
                        <tbody>
                            <tr>
                                <td>{this.converte_data_timezone(reporte.data_hora_dispositivo)}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                {
                    this.state.detalhes.entradasdigitais.map((entrada, idx_entrada) => {
                        if (entrada.tipo_entrada_logica_id === 3) {
                            return <td key={idx_entrada}>
                                <table className="hdv-statustable-innertable">
                                    {(idx === 0)
                                        ?
                                        <thead>
                                            <tr>
                                                <td>Estado da Entrada</td>
                                            </tr>
                                        </thead>
                                        :
                                        null
                                    }
                                    <tbody>
                                        <tr>
                                            <td>
                                                {
                                                    (reporte.status[entrada.canal - 1] === 1) ? <span className="hdv-status-ligado">Ligado</span> : <span className="hdv-status-desligado">Desligado</span>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        } else if (entrada.tipo_entrada_logica_id === 4) {
                            return <td key={idx_entrada}>
                                <table className="hdv-statustable-innertable">
                                    {(idx === 0)
                                        ?
                                        <thead>
                                            <tr>
                                                <td>Estado do nível</td>
                                            </tr>
                                        </thead>
                                        :
                                        null
                                    }
                                    <tbody>
                                        <tr>
                                            <td>
                                                {
                                                    (reporte.status[entrada.canal - 1] === 1) ? <span className="hdv-status-ligado">Normal</span> : <span className="hdv-status-desligado">Baixo</span>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>



                        } else if (entrada.tipo_entrada_logica_id === 5) {
                            return <td key={idx_entrada}>
                                <table className="hdv-statustable-innertable">
                                    {(idx === 0)
                                        ?
                                        <thead>
                                            <tr>
                                                <td>Volume</td>
                                                <td>Vazão</td>
                                            </tr>
                                        </thead>
                                        :
                                        null
                                    }
                                    <tbody>
                                        <tr>
                                            <td>
                                                {
                                                    String(reporte.pulso[entrada.canal - 1].toFixed(2)) + ' ' + ((entrada.tipo_entrada_logica__unidade_totalizadora) ? entrada.tipo_entrada_logica__unidade_totalizadora : '')
                                                }
                                            </td>
                                            <td>
                                                {
                                                    ('ct_pulso' in reporte) ? String(reporte.ct_pulso[entrada.canal - 1].toFixed(2)) + ' ' + ((entrada.tipo_entrada_logica__unidade_totalizadora) ? entrada.tipo_entrada_logica__unidade_totalizadora + "/h" : '') : ''
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        }
                        else if (entrada.tipo_entrada_logica_id === 2) {

                            return <td key={idx_entrada}>
                                <table className="hdv-statustable-innertable">
                                    {(idx === 0)
                                        ?
                                        <thead>
                                            <tr>
                                                <td>Horas</td>
                                            </tr>
                                        </thead>
                                        :
                                        null
                                    }
                                    <tbody>
                                        <tr>
                                            <td>
                                                {
                                                    String(helper_fn.converte_decimal_to_hhmmss(reporte.pulso[entrada.canal - 1].toFixed(3))) + ' ' + ((entrada.tipo_entrada_logica__unidade_totalizadora) ? entrada.tipo_entrada_logica__unidade_totalizadora : '')
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        } else if (entrada.tipo_entrada_logica_id === 1) {
                            return <td key={idx_entrada}>
                                <table className="hdv-statustable-innertable">
                                    {(idx === 0)
                                        ?
                                        <thead>
                                            <tr>
                                                <td>Volume</td>
                                                <td>Volume do Dia</td>
                                                <td>Vazão</td>
                                            </tr>
                                        </thead>
                                        :
                                        null
                                    }
                                    <tbody>
                                        <tr>
                                            <td>
                                                {
                                                    String(reporte.pulso[entrada.canal - 1].toFixed(2)) + ' ' + ((entrada.tipo_entrada_logica__unidade_totalizadora) ? entrada.tipo_entrada_logica__unidade_totalizadora : '')
                                                }
                                            </td>
                                            <td>
                                                {
                                                    String((reporte.pulso[entrada.canal - 1] - parseFloat(this.state.detalhes.primeiro_status_entrada_digital[entrada.canal - 1])).toFixed(2)) + ' ' + ((entrada.tipo_entrada_logica__unidade_totalizadora) ? entrada.tipo_entrada_logica__unidade_totalizadora : '')
                                                }
                                            </td>
                                            <td>
                                                {
                                                    ('ct_pulso' in reporte) ? String(reporte.ct_pulso[entrada.canal - 1].toFixed(2)) + ' ' + ((entrada.tipo_entrada_logica__unidade_totalizadora) ? entrada.tipo_entrada_logica__unidade_totalizadora + "/hora" : '') : ''
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        } else if (entrada.tipo_entrada_logica_id === 6) {
                            return <td key={idx_entrada}>
                                <table className="hdv-statustable-innertable">
                                    {(idx === 0)
                                        ?
                                        <thead>
                                            <tr>
                                                <td>Volume</td>
                                                <td>Fluxo</td>
                                            </tr>
                                        </thead>
                                        :
                                        null
                                    }
                                    <tbody>
                                        <tr>
                                            <td>
                                                {
                                                    String(helper_fn.converte_volume_min(reporte.ct_pulso[entrada.canal - 1].toFixed(2))) + ' ' + ((entrada.tipo_entrada_logica__unidade_totalizadora) ? entrada.tipo_entrada_logica__unidade_totalizadora : '')
                                                }
                                            </td>
                                            <td>
                                                {
                                                    ('ct_pulso_pure' in reporte) ? String(helper_fn.converte_fluxo_min(reporte.ct_pulso_pure[entrada.canal - 1].toFixed(2))) + ' Flu/min' : ''
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        } else {
                            return <td key={idx_entrada}>
                                <table className="hdv-statustable-innertable">
                                    {(idx === 0)
                                        ?
                                        <thead>
                                            <tr>
                                                <td>{entrada.tipo_entrada_logica__unidade_totalizadora}</td>
                                            </tr>
                                        </thead>
                                        :
                                        null
                                    }
                                    <tbody>
                                        <tr>
                                            <td>
                                                {
                                                    String(reporte.pulso[entrada.canal - 1].toFixed(2)) + ' ' + ((entrada.tipo_entrada_logica__unidade_totalizadora) ? entrada.tipo_entrada_logica__unidade_totalizadora : '')
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        }
                    })
                }
            </tr>
        })
    }

    generateTableRowEletricos = () => {
        let campos_energia = [
            { key: 'tensao', type: 'array' },
            { key: 'tensao_fase_fase', type: 'array' },
            { key: 'tensao_fase_neutro', type: 'array' },
            { key: 'corrente', type: 'array' },
            { key: 'potencia_ativa_linha', type: 'array' },
            { key: 'potencia_reativa_linha', type: 'array' },
            { key: 'potencia_aparente_linha', type: 'array' },
            { key: 'fator_potencia_linha', type: 'array' },
            { key: 'thd_tensao_fase', type: 'array' },
            { key: 'thd_corrente_fase', type: 'array' },
            { key: 'frequencia', type: 'int' },
            { key: 'tensao_trifasica', type: 'int' },
            { key: 'potencia_ativa_trifasica', type: 'int' },
            { key: 'potencia_reativa_trifasica', type: 'int' },
            { key: 'demanda_ativa', type: 'int' },
            { key: 'corrente_trifasica', type: 'int' },
            { key: 'fator_potencia_trifasica', type: 'int' },
            { key: 'fator_potencia', type: 'int' },
            { key: 'energia_ativa', type: 'int' },
            { key: 'energia_ativa_negativa', type: 'int' },
            { key: 'corrente_neutro', type: 'int' },
            { key: 'potencia_aparente_trifasica', type: 'int' },
            { key: 'frequencia_iec_10s', type: 'int' },
            { key: 'energia_reativa', type: 'int' },
            { key: 'energia_reativa_negativa', type: 'int' },
            { key: 'maxima_demanda_ativa', type: 'int' },
            { key: 'maxima_demanda_aparente', type: 'int' },
            { key: 'demanda_aparente', type: 'int' },
            { key: 'status_carga', type: 'int' },
        ]

        let reporte = this.state.detalhes.reportes[0]

        let existe_um_campo = campos_energia.filter((obj) => {
            if (obj.key in reporte)
                return true

            return false
        })

        if (existe_um_campo.length === 0) {
            return (
                <span></span>
            )
        }

        if (this.state.detalhes.reportes.length > 1) {
            return this.state.detalhes.reportes.map((pos, idx) => {
                return (
                    <tr key={idx}>
                        <td>
                            <table className="hdv-statustable-innertable">
                                {(idx === 0) ? <thead><tr><td>Data e Hora da Comunicação</td></tr></thead> : null}
                                <tbody>
                                    <tr>
                                        <td>{this.converte_data_timezone(pos.data_hora_dispositivo)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        {
                            campos_energia.map((field, idx_field) => {

                                if (field.key in pos) {

                                    if (field.type === 'array') {
                                        return (
                                            <td key={idx_field}>
                                                <table className="hdv-statustable-innertable">
                                                    {(idx === 0) ?
                                                        <thead>
                                                            <tr>
                                                                {
                                                                    pos[field.key].map((title, idx) => {
                                                                        return (
                                                                            <td key={idx}>
                                                                                Valor
                                                                                {/* {this.state.dict_fields[field.key]} {idx + 1} */}
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        : null}
                                                    <tbody>
                                                        <tr>
                                                            {
                                                                pos[field.key].map((value, idx) => {
                                                                    return (
                                                                        <td key={idx}>{value}</td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        )
                                    } else if (field.type === 'int') {
                                        return (
                                            <td key={`-${idx_field}`}>
                                                <table className="hdv-statustable-innertable">
                                                    <thead>
                                                        <tr>
                                                            {(idx === 0) ? <td>Valor</td> : null}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{pos[field.key]}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        )
                                    }
                                }
                                return null
                            })
                        }
                    </tr>
                )
            })
        }
    }

    generateTableOutrosCampos = () => {

        if (this.state.detalhes.reportes.length > 1) {
            return this.state.detalhes.reportes.map((pos, idx) => {
                return (
                    <tr key={idx}>
                        <td>
                            <table className="hdv-statustable-innertable">
                                {(idx === 0) ? <thead><tr><td>Data e Hora da Comunicação</td></tr></thead> : null}
                                <tbody>
                                    <tr>
                                        <td>{this.converte_data_timezone(pos.data_hora_dispositivo)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        {
                            this.state.detalhes.camposcustomizados.map((field, idx_field) => {

                                if (field.identificacao in pos) {
                                    if (field.operador_especial === "soma") {

                                        return (
                                            <td key={`-${idx_field}`}>
                                                <table className="hdv-statustable-innertable">
                                                    <thead>
                                                        <tr>
                                                            {(idx === 0) ? <td>Volume</td> : null}
                                                            {(idx === 0) ? <td>Volume do Dia</td> : null}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{pos[field.identificacao]} {field.unidade}</td>
                                                            <td>{(parseFloat(pos[field.identificacao]) - parseFloat(this.state.detalhes?.primeiro_registro[field.identificacao])).toFixed(2)} {field.unidade}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        )

                                    } else {
                                        return (
                                            <td key={`-${idx_field}`}>
                                                <table className="hdv-statustable-innertable">
                                                    <thead>
                                                        <tr>
                                                            {(idx === 0) ? <td>Valor</td> : null}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{pos[field.identificacao]} {field.unidade}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        )
                                    }

                                }
                                return null
                            })
                        }
                    </tr>
                )
            })
        }
    }

    decimalToTime = (num) => {
        let decimal = Number("0." + String(num).split('.')[1])
        let hours = num.toString().split('.')[0]
        let minutes = decimal * 60
        let seconds = Number("0." + String(minutes).split('.')[1]) * 60
        seconds = seconds.toFixed(0)
        minutes = String(minutes).split('.')[0]

        if (isNaN(hours)) hours = '00'
        else if (String(hours).length === 1) hours = "0" + hours

        if (isNaN(minutes)) minutes = '00'
        else if (String(minutes).length === 1) minutes = "0" + minutes

        if (isNaN(seconds)) seconds = '00'
        else if (String(seconds).length === 1) seconds = "0" + seconds

        return `${hours}:${minutes}:${seconds}`
    }

    generateTableHorimetro = () => {
        let reportes = this.state.detalhes.reportes

        if (reportes?.[0]?.horimetro?.length !== 0) {
            return reportes.map((reporte, r_id) => (
                <tr key={r_id}>
                    <td>
                        <table className="hdv-statustable-innertable">
                            {r_id === 0 ? <thead><tr><td>Data e Hora da Comunicação</td></tr></thead> : null}
                            <tbody>
                                <tr>
                                    <td>{this.converte_data_timezone(reporte.data_hora_dispositivo)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    {reportes[0]?.horimetro?.map((pos, idx) => {
                        let volumeDia = 0
                        if (this.state.detalhes.primeiro_registro) {
                            volumeDia = reporte.horimetro?.[idx] - this.state.detalhes?.primeiro_registro?.horimetro[idx]
                        }

                        return (
                            <td key={idx}>
                                <table className="hdv-statustable-innertable">
                                    <thead>
                                        <tr>
                                            {r_id === 0 ? <td>Horas</td> : null}
                                            {r_id === 0 ? <td>Horas do dia</td> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.decimalToTime(reporte?.horimetro[idx])}</td>
                                            <td>{this.decimalToTime(volumeDia)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        )
                    })}
                </tr>
            ))
        }
    }

    generateCharts = () => {
        if (this.state.charts.length > 0) {
            let pacote_campos = Object.assign({}, this.state.charts[0]);
            delete pacote_campos.data_hora_dispositivo
            let jsxCharts = []

            for (let prop in pacote_campos) {
                if (typeof (pacote_campos[prop]) !== 'object') {
                    jsxCharts.push(prop)
                }
            }


            return jsxCharts.map((v, i) => {
                return (
                    <div className="col-md-4" style={{ marginTop: '15px' }} key={i}>
                        <h5 style={{ textAlign: 'center' }}>{this.state.dict_fields[v]}</h5>
                        <div style={{ width: '100%', height: 200 }}>
                            <ResponsiveContainer>
                                <LineChart
                                    width={500}
                                    height={200}
                                    data={this.state.charts}
                                    margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="data_hora_dispositivo"
                                    />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend onClick={(e) => console.log(e)} />
                                    <Line type="monotone" dataKey={v} stroke="#8884d8" dot={false} />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>

                )
            })

        }
    }

    //FUNÇÕES AUTOMACAO

    toggleRow = (id, aba) => {
        let selecionados = this.state[aba].selected
        let editar = false
        let deletar = false

        if (selecionados.includes(id)) {
            selecionados = selecionados.filter(item => item !== id)
        } else {
            selecionados.push(id)
        }

        if (selecionados.length === 1) {
            editar = true
        }

        if (selecionados.length >= 1) {
            deletar = true
        }

        let newState = this.state[aba]
        newState['enableEditar'] = editar
        newState['enableDeletar'] = deletar
        newState['selected'] = selecionados
        newState['selectAll'] = 2

        let novo_estado = {}
        novo_estado[aba] = newState

        this.setState(novo_estado);
    }

    toggleSelectAll = (aba) => {
        let selecionados = [];
        let editar = false
        let deletar = false

        if (this.state[aba].selectAll === 0) {
            this.state[aba].data.forEach(x => {
                selecionados.push(x.id);
            });
        }

        if (selecionados.length === 1) {
            editar = true
        }

        if (selecionados.length >= 1) {
            deletar = true
        }

        let newState = this.state[aba]
        newState['enableEditar'] = editar
        newState['enableDeletar'] = deletar
        newState['selected'] = selecionados
        newState['selectAll'] = this.state[aba].selectAll === 0 ? 1 : 0

        let novo_estado = {}
        novo_estado[aba] = newState

        this.setState(novo_estado);
    }

    deletar = (aba) => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        let newState = this.state[aba]
        newState['enableEditar'] = false
        newState['enableDeletar'] = false
        newState['loading'] = true

        let novo_estado = {}
        novo_estado[aba] = newState

        this.setState(novo_estado);

        if (aba === 'campoCustomizado') {
            this.state.campoCustomizado.selected.map((value, index) =>
                requestArray.push(axios.delete(`${cfg.base_api_url + cfg.api_version}/${userInfo.empresa}/campo-customizado/${value}/`, config_me))
            )
        }
        else {
            this.state[aba].selected.map((value, index) =>
                requestArray.push(axios.delete(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + aba + '/' + value + '/', config_me))
            )
        }


        axios.all(requestArray)
            .then(axios.spread((acct, perms) => {
                if (aba === 'automacao') {
                    this.refReactTableAutomacao.fireFetchData()
                } else if (aba === 'alerta') {
                    this.refReactTableAlerta.fireFetchData()
                } else if (aba === 'campoCustomizado') {
                    this.refReactTableCampoCustomizado.fireFetchData()
                }
            }));
    }

    editar = (aba) => {
        let toGo
        if (aba === 'campoCustomizado') {
            toGo = "/campo-customizado/" + this.props.match.params.imei + "/editar/" + this.state[aba].selected[0]
        } else {
            toGo = "/" + aba + "/" + this.state[aba].selected[0] + "/editar"
        }
        let from = { pathname: toGo }
        let newState = this.state[aba]
        newState['redirectToEdit'] = from

        let novo_estado = {}
        novo_estado[aba] = newState

        this.setState(novo_estado);
    }

    getDataAutomacao = _.debounce((state, instance) => {
        if (state) {
            if ("pageSize" in state) {
                this.setState({ loading: true });
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                let userInfo = auth.getUserInfo();;

                let condicao = [...state.filtered]
                condicao.push({ "id": "equipamento_executa__imei", "value": this.props.match.params.imei })

                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/automacao/?page=' + state.page + '&pageSize=' + state.pageSize + '&filter=' + encodeURI(JSON.stringify(condicao)), config_me)
                    .then((response) => {

                        let newState = this.state.automacao
                        newState['selected'] = []
                        newState['data'] = response.data.results
                        newState['loading'] = false
                        newState['pages'] = response.data.pages
                        newState['filtered'] = true

                        this.setState({ 'automacao': newState });
                    })
            }
        }
    }, 500)

    getDataAlerta = _.debounce((state, instance) => {
        if (state) {
            if ("pageSize" in state) {
                this.setState({ loading: true });
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                let userInfo = auth.getUserInfo();;

                let condicao = [...state.filtered]
                condicao.push({ "id": "equipamento__imei", "value": this.props.match.params.imei })

                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/alerta/?page=' + state.page + '&pageSize=' + state.pageSize + '&filter=' + encodeURI(JSON.stringify(condicao)), config_me)
                    .then((response) => {
                        let newState = this.state.alerta
                        newState['selected'] = []
                        newState['data'] = response.data.results
                        newState['loading'] = false
                        newState['pages'] = response.data.pages
                        newState['filtered'] = true
                        this.setState({ 'alerta': newState });
                    })
            }
        }
    }, 500)

    getDataCampoCustomizado = _.debounce((state, instance) => {
        if (state) {
            if ("pageSize" in state) {
                this.setState({ loading: true });
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                let userInfo = auth.getUserInfo();

                let condicao = [...state.filtered]
                condicao.push({ "id": "equipamento__imei", "value": this.props.match.params.imei })

                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/campo-customizado/?page=' + state.page + '&pageSize=' + state.pageSize + '&filter=' + encodeURI(JSON.stringify(condicao)), config_me)
                    .then((response) => {
                        let newState = this.state.campoCustomizado
                        newState['selected'] = []
                        newState['data'] = response.data.results
                        newState['loading'] = false
                        newState['pages'] = response.data.pages
                        newState['filtered'] = true
                        this.setState({ 'campoCustomizado': newState });
                    })
            }
        }
    }, 500)

    reoordenar = (automacao, sentido) => {
        let data = this.state.automacao.data
        let lista_automacao = this.state.automacao
        let posicao_atual = 0
        let data_filtrada = data.filter((check_automacao, idx) => {
            if (check_automacao.id === automacao.id) {
                posicao_atual = idx
                return false
            } else {
                return true
            }
        })
        let posicao_nova = (sentido === "subir") ? posicao_atual - 1 : posicao_atual + 1
        let novo_automacao_data = []

        for (let index = 0; index < data_filtrada.length; index++) {
            if (index === posicao_nova) {
                novo_automacao_data.push(automacao)
            }
            novo_automacao_data.push(data_filtrada[index])
        }

        if ((data.length - 1) === posicao_nova) {
            novo_automacao_data.push(automacao)
        }

        lista_automacao.data = novo_automacao_data
        lista_automacao.enableAtualizarOrdem = true
        this.setState({ automacao: lista_automacao })
    }

    atualiza_ordem = () => {
        let lista_automacao = this.state.automacao
        lista_automacao.loading = true
        lista_automacao.enableAtualizarOrdem = false
        this.setState({ automacao: lista_automacao })
        //lista_automacao.enableAtualizarOrdem = false
        //this.setState({automacao: lista_automacao})
        let jsonOrdem = []

        for (let index = 0; index < lista_automacao.data.length; index++) {
            jsonOrdem.push({ "id": lista_automacao.data[index].id, "ordem": index })
        }

        let userInfo = auth.getUserInfo();
        let bodyFormData = new FormData();

        bodyFormData.append('nova_ordenacao', JSON.stringify(jsonOrdem))
        bodyFormData.append('id_dispositivo', this.props.match.params.imei)

        let requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/automacao/atualiza-ordem/';

        axios({
            method: 'POST',
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                lista_automacao.loading = false
                this.setState({ automacao: lista_automacao })
            })
            .catch((error) => {
                lista_automacao.enableAtualizarOrdem = true
                this.setState({ automacao: lista_automacao })
            })
    }

    generateSinal = sinal => {
        let badge = 'badge badge-'
        let label = ''
        if (sinal <= 73) {
            badge += 'success'
            label = 'Excelente'
        } else if (sinal > 73 && sinal < 85) {
            badge += 'primary'
            label = 'Bom'
        } else if (sinal >= 85 && sinal <= 93) {
            badge += 'warning'
            label = 'OK'
        } else if (sinal > 93) {
            badge += 'secondary'
            label = 'Marginal'
        }

        return <span className={badge} style={{ padding: '3px 15px', borderRadius: '16px', fontSize: '12px' }}>
            {sinal + ' - ' + label}
        </span>
    }

    checkStatusRele = () => {
        let status
        if (Array.isArray(this.state.detalhes?.reportes?.[0]?.rele)) {
            status = String(this.state.detalhes?.reportes?.[0]?.rele[0]) === '1' ?? false
        } else {
            status = String(this.state.detalhes?.reportes?.[0]?.rele) === '1' ?? false
        }
        return status ? <span className="hdv-status-ligado">Ligado</span> : <span className="hdv-status-desligado">Desligado</span>

    }

    render() {
        const automacaoRedirect = this.state.automacao.redirectToEdit;

        if (automacaoRedirect) {
            return <Redirect to={{
                pathname: automacaoRedirect.pathname,
                state: {
                    equipamento_selecionado: { value: this.props.match.params.imei, label: this.props.match.params.imei }
                }
            }} />;
        }

        const columnsAutomacao = [{
            Header: x => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={this.state.automacao.selectAll === 1}
                        ref={input => {
                            if (input) {
                                input.indeterminate = this.state.automacao.selectAll === 2;
                            }
                        }}
                        onChange={() => this.toggleSelectAll('automacao')}
                    />
                );
            },
            sortable: false,
            width: 55,
            accessor: "",
            filterable: false,
            Cell: ({ original }) => {
                return (
                    <div className="hdv-aligncenter-checkboxgrid">
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={(this.state.automacao.selected.includes(original.id) === true ? true : false)}
                            onChange={() => this.toggleRow(original.id, 'automacao')}
                        />
                    </div>
                );
            }
        }, {
            Header: 'Descrição',
            accessor: 'descricao'
        }, {
            Header: 'Ação',
            accessor: 'acao',
            Cell: ({ original }) => {
                if (original.acao === true) {
                    return ('Ligar')
                } else {
                    return ('Desligar')
                }
            }
        }, {
            Header: 'Reoordenar',
            Cell: row => (
                <div>
                    <button className={(row.index === 0) ? "hdv-noshow-item" : "hdv-automacao-order-button"} onClick={() => this.reoordenar(row.original, 'subir')}>Subir</button>
                    <button className={((row.index + 1) === this.state.automacao.data?.length) ? "hdv-noshow-item" : "hdv-automacao-order-button"} onClick={() => this.reoordenar(row.original, 'sair')}>Descer</button>
                </div>
            )
        }]

        //TABELA DE ALERTA

        const alertaRedirect = this.state.alerta.redirectToEdit;

        if (alertaRedirect) {

            console.log(this.state)

            return <Redirect to={{
                pathname: alertaRedirect.pathname,
                state: {
                    equipamento_selecionado: { value: this.props.match.params.imei, label: this.props.match.params.imei },
                    monitorado_selecionado: { value: this.state.detalhes.monitorados[0].id, label: this.state.detalhes.monitorados[0].nome }
                }
            }} />;
        }

        const columnsAlerta = [{
            Header: x => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={this.state.alerta.selectAll === 1}
                        ref={input => {
                            if (input) {
                                input.indeterminate = this.state.alerta.selectAll === 2;
                            }
                        }}
                        onChange={() => this.toggleSelectAll('alerta')}
                    />
                );
            },
            sortable: false,
            width: 55,
            accessor: "",
            filterable: false,
            Cell: ({ original }) => {
                return (
                    <div className="hdv-aligncenter-checkboxgrid">
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={(this.state.alerta.selected.includes(original.id) === true ? true : false)}
                            onChange={() => this.toggleRow(original.id, 'alerta')}
                        />
                    </div>
                );
            }
        }, {
            Header: 'Descrição',
            accessor: 'descricao'
        }, {
            Header: 'Severidade',
            accessor: 'severidade',
            Cell: ({ original }) => {
                if (original.severidade === 'informativo') {
                    return ('Informativo')
                } else if (original.severidade === 'nao_urgente') {
                    return ('Não urgente')
                } else if (original.severidade === 'grave') {
                    return ('Grave')
                } else if (original.severidade === 'critico') {
                    return ('Crítico')
                }
            }
        }, {
            Header: 'Equipamento',
            accessor: 'equipamento__imei'
        }]

        if (this.state.campoCustomizado.redirectToEdit) {
            return <Redirect to={this.state.campoCustomizado.redirectToEdit} />
        }

        const columnsCamposCustomizados = [{
            Header: x => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={this.state.campoCustomizado.selectAll === 1}
                        ref={input => {
                            if (input) {
                                input.indeterminate = this.state.campoCustomizado.selectAll === 2;
                            }
                        }}
                        onChange={() => this.toggleSelectAll('campoCustomizado')}
                    />
                );
            },
            sortable: false,
            width: 55,
            accessor: "",
            filterable: false,
            Cell: ({ original }) => {
                return (
                    <div className="hdv-aligncenter-checkboxgrid">
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={(this.state.campoCustomizado.selected.includes(original.id) === true ? true : false)}
                            onChange={() => this.toggleRow(original.id, 'campoCustomizado')}
                        />
                    </div>
                );
            }
        }, {
            Header: 'Equipamento',
            accessor: 'equipamento__imei'
        }, {
            Header: 'Descrição',
            accessor: 'descricao'
        }, {
            Header: 'Fórmula',
            accessor: 'formula'
        }]

        return (
            <div className="hdv-render-div">
                <RelatorioStatus imei={this.props.match.params.imei} entradas={this.state.entradas} ultimo_reporte={this.state.detalhes.reportes[0]} />


                <h4>Detalhes do Equipamento: {this.props.match.params.imei}</h4>
                <div style={{ fontSize: '12px', marginTop: '10px', marginBottom: '10px' }} className="row">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-6">Nome do Cliente: </div>
                            <div className="col-6">{this.state.detalhes.cliente.join(',')}</div>
                            <div className="col-6">Monitorado: </div>
                            <div className="col-6">{this.state.detalhes.monitorado.join(',')}</div>
                            <div className="col-6">Última Comunicação: </div>
                            <div className="col-6">{this.converte_data_timezone((this.state.detalhes.reportes.length > 1) ? this.state.detalhes.reportes[0].data_hora_dispositivo : false)}</div>
                            <div className="col-6">Estado do Relé: </div>
                            <div className="col-6">{this.checkStatusRele()}</div>
                            <div className="col-6">Horímetro Virtual: </div>
                            <div className="col-6">{this.convert_seconds_to_hours(this.state.detalhes.horimetro)}</div>
                            <div className="col-6">SLA (Ontem):</div>
                            <div className="col-6">{this.state.detalhes.SLA.toFixed(2) + '%'}</div>
                            {this.state.detalhes.reportes[0].voltagem_bateria ?
                                <>
                                    <div className="col-6">Voltagem Bateria</div>
                                    <div className="col-6">{Number(this.state.detalhes.reportes[0].voltagem_bateria).toFixed(2)}</div>
                                </>
                                : null}
                            {this.state.detalhes.reportes[0].sinal ?
                                <>
                                    <div className="col-6">Força do sinal</div>
                                    <div className="col-6">{this.generateSinal(this.state.detalhes.reportes[0].sinal)}</div>
                                </>
                                : null}
                            {this.state.detalhes.reportes[0].alimentacao ?
                                <>
                                    <div className="col-6">Alimentação</div>
                                    <div className="col-6">{this.state.detalhes.reportes[0].alimentacao === "1" ? "Alimentação Externa" : "Alimentação Interna"}</div>
                                </>
                                : null}
                        </div>
                    </div>
                </div>

                <Tabs className="tabs-style" selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>

                    <TabList>
                        <Tab tabIndex={"0"}>Estado Atual</Tab>
                        <Tab tabIndex={"1"} className={"react-tabs__tab " + auth.check_to_show(['AUT-V01', 'AUT-A02', 'AUT-E03', 'AUT-X04'])}>Automações</Tab>
                        <Tab tabIndex={"2"} className={"react-tabs__tab " + auth.check_to_show(['ALE-V01', 'ALE-A02', 'ALE-E03', 'ALE-X04'])}>Alertas</Tab>
                        <Tab tabIndex={"2"} className={"react-tabs__tab " + auth.check_to_show(['CAC-V01', 'CAC-A02', 'CAC-E03', 'CAC-X04'])}>Campos Customizados</Tab>
                    </TabList>

                    <TabPanel>
                        <div className={(this.state.detalhes.entradasanalogicas.length > 0) ? '' : 'hdv-noshow-item'}>
                            <h4>Entradas Analógicas</h4>
                            <div style={{ fontSize: '12px', marginTop: '10px' }} className="row">
                                <table className="hdv-statustable">
                                    <thead className="hdv-statustable-header">
                                        <tr>
                                            <td className="hdv-statustable-header-firsttd"></td>
                                            {
                                                this.state.detalhes.entradasanalogicas.map((entrada, idx_entrada) => {
                                                    return <td key={idx_entrada}>{'#' + entrada.canal + ' ' + entrada.descricao + ' (' + entrada.sinal__descricao + ')'}</td>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="hdv-statustable-body">
                                        {
                                            (this.state.detalhes.reportes.length > 1) ?
                                                this.generateTableRowAnalogica()
                                                :
                                                null
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className={(this.state.detalhes.entradasdigitais.length > 0) ? '' : 'hdv-noshow-item'}>
                            <h4 style={{ marginTop: '15px' }}>Entradas Digitais</h4>
                            <div style={{ fontSize: '12px', marginTop: '10px' }} className="row">
                                <table className="hdv-statustable">
                                    <thead className="hdv-statustable-header">
                                        <tr>
                                            <td className="hdv-statustable-header-firsttd"></td>
                                            {
                                                this.state.detalhes.entradasdigitais.map((entrada, idx_entrada) => {
                                                    return <td key={idx_entrada}>{'#' + entrada.canal + ' ' + entrada.descricao + ' (' + entrada.tipo_entrada_logica__descricao + ')'}</td>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="hdv-statustable-body">
                                        {this.generateTableRowDigital()}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className={(this.state.detalhes.camposcustomizados.length > 0) ? '' : 'hdv-noshow-item'}>
                            <h4 style={{ marginTop: '15px' }}>Campos Customizados</h4>
                            <div style={{ fontSize: '12px', marginTop: '10px' }} className="row">
                                <table className="hdv-statustable">
                                    <thead className="hdv-statustable-header">
                                        <tr>
                                            <td className="hdv-statustable-header-firsttd"></td>
                                            {
                                                this.state.detalhes.camposcustomizados.map((campo, idx_entrada) => {
                                                    return <td key={idx_entrada}>{campo.descricao}</td>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="hdv-statustable-body">
                                        {this.generateTableOutrosCampos()}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className={('horimetro' in this.state.detalhes.reportes?.[0]) ? '' : 'hdv-noshow-item'}>
                            <h4 style={{ marginTop: '15px' }}>Horímetros</h4>
                            <div style={{ fontSize: '12px', marginTop: '10px' }} className="row">
                                <table className="hdv-statustable">
                                    <thead className="hdv-statustable-header">
                                        <tr>
                                            <td className="hdv-statustable-header-firsttd"></td>
                                            {
                                                this.state.detalhes.reportes?.[0]?.horimetro?.map((pos, idx) => {
                                                    return <td key={idx}># {idx + 1} Horímetro</td>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="hdv-statustable-body">
                                        {this.generateTableHorimetro()}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {
                            (this.state.existem_dados_eletricos)
                                ?
                                <div>
                                    <h4 style={{ marginTop: '15px' }}>Dados Elétricos</h4>
                                    <div style={{ fontSize: '12px', marginTop: '10px' }} className="row">
                                        <table className="hdv-statustable">
                                            <thead className="hdv-statustable-header">
                                                {
                                                    (this.state.detalhes.reportes.length > 0) ?
                                                        <tr>
                                                            <td className="hdv-statustable-header-firsttd"></td>
                                                            <td className={('tensao' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Tensão</td>
                                                            <td className={('tensao_fase_fase' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Tensão Fase Fase</td>
                                                            <td className={('tensao_fase_neutro' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Tensão Fase Neutro</td>
                                                            <td className={('corrente' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Corrente</td>
                                                            <td className={('potencia_ativa_linha' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Potência Ativa Linha</td>
                                                            <td className={('potencia_reativa_linha' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Potência Reativa Linha</td>
                                                            <td className={('potencia_aparente_linha' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Potência Aparente Linha</td>
                                                            <td className={('fator_potencia_linha' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Fator Potência Linha</td>
                                                            <td className={('thd_tensao_fase' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Tensão Fase</td>
                                                            <td className={('thd_corrente_fase' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Corrente Fase</td>
                                                            <td className={('frequencia' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Frequência</td>
                                                            <td className={('tensao_trifasica' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Tensão Trifásica</td>
                                                            <td className={('potencia_ativa_trifasica' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Potência Ativa Trifásica</td>
                                                            <td className={('potencia_reativa_trifasica' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Potência Reativa Trifásica</td>
                                                            <td className={('demanda_ativa' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Demanda Ativa</td>
                                                            <td className={('corrente_trifasica' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Corrente Trifásica</td>
                                                            <td className={('fator_potencia_trifasica' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Fator de Potência Trifásica</td>
                                                            <td className={('fator_potencia' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'} >Fator de Potência</td>
                                                            <td className={('energia_ativa' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'}>Energia Ativa</td>
                                                            <td className={('energia_ativa_negativa' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'}>Energia Ativa</td>
                                                            <td className={('corrente_neutro' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'}>Energia Ativa</td>
                                                            <td className={('potencia_aparente_trifasica' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'}>Energia Ativa</td>
                                                            <td className={('frequencia_iec_10s' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'}>Energia Ativa</td>
                                                            <td className={('energia_reativa' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'}>Energia Ativa</td>
                                                            <td className={('energia_reativa_negativa' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'}>Energia Ativa</td>
                                                            <td className={('maxima_demanda_ativa' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'}>Energia Ativa</td>
                                                            <td className={('maxima_demanda_aparente' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'}>Energia Ativa</td>
                                                            <td className={('demanda_aparente' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'}>Energia Ativa</td>
                                                            <td className={('status_carga' in this.state.detalhes.reportes[0]) ? '' : 'hdv-noshow-item'}>Energia Ativa</td>
                                                        </tr>
                                                        : ""
                                                }
                                            </thead>
                                            <tbody className="hdv-statustable-body">
                                                {this.generateTableRowEletricos()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                ""
                        }

                        {
                            (this.state.existem_dados_eletricos)
                                ?
                                <div>
                                    <h4 style={{ marginTop: '15px' }}>Gráficos</h4>
                                    <div className="row" style={{ marginTop: '30px' }}>

                                        {this.generateCharts()}

                                    </div>
                                </div>
                                :
                                ""
                        }
                    </TabPanel>
                    <TabPanel>

                        <div className="hdv-btn-group">
                            <Link to={{
                                pathname: '/automacao/novo',
                                state: {
                                    equipamento_selecionado: { value: this.props.match.params.imei, label: this.props.match.params.imei },
                                    monitorado_selecionado: (this.state.detalhes.monitorados[0]) ? { value: this.state.detalhes.monitorados[0].id, label: this.state.detalhes.monitorados[0].nome } : false
                                }
                            }}>
                                <button className={"hdv-btn-forms hdv-btn-geen " + auth.check_to_show(['AUT-A02'])}>Nova Regra</button>
                            </Link>
                            <button onClick={() => { if (this.state['automacao'].enableEditar === true) { this.editar('automacao') } }} className={"hdv-btn-forms hdv-btn-yellow " + (this.state['automacao'].enableEditar === true ? "" : "hdv-btn-disable") + " " + auth.check_to_show(['AUT-E03'])}>Editar</button>
                            <button onClick={() => { if (this.state['automacao'].enableDeletar === true) { this.deletar('automacao') } }} className={"hdv-btn-forms hdv-btn-red " + (this.state['automacao'].enableDeletar === true ? "" : "hdv-btn-disable") + " " + auth.check_to_show(['AUT-X04'])}>Excluir</button>
                            <button style={{ "float": "right", "marginRight": "0px" }} onClick={() => { if (this.state['automacao'].enableAtualizarOrdem === true) { this.atualiza_ordem() } }} className={"hdv-btn-forms hdv-btn-blue " + (this.state['automacao'].enableAtualizarOrdem === true ? "" : "hdv-btn-disable") + " " + auth.check_to_show(['AUT-E03'])}>Atualizar Ordem de Execução</button>
                        </div>

                        <ReactTable
                            ref={(refReactTable) => { this.refReactTableAutomacao = refReactTable; }}
                            manual
                            filterable
                            data={this.state.automacao.data}
                            pages={this.state.automacao.pages}
                            loading={this.state.automacao.loading}
                            onFetchData={this.getDataAutomacao}
                            columns={columnsAutomacao}
                            defaultPageSize={10}
                            previousText={'Anterior'}
                            nextText={'Próximo'}
                            loadingText={'Carregando...'}
                            noDataText={'Nenhum registro para exibir'}
                            pageText={'Página'}
                            ofText={'de'}
                            rowsText={'linhas'}
                        />

                    </TabPanel>
                    <TabPanel>

                        <div className="hdv-btn-group">
                            <Link to={{
                                pathname: '/alerta/novo',
                                state: {
                                    equipamento_selecionado: { value: this.props.match.params.imei, label: this.props.match.params.imei },
                                    monitorado_selecionado: (this.state.detalhes.monitorados[0]) ? { value: this.state.detalhes.monitorados[0].id, label: this.state.detalhes.monitorados[0].nome } : false
                                }
                            }}>
                                <button className={"hdv-btn-forms hdv-btn-geen " + auth.check_to_show(['ALE-A02'])}>Novo Alerta</button>
                            </Link>
                            <button onClick={() => { if (this.state.alerta.enableEditar === true) { this.editar('alerta') } }} className={"hdv-btn-forms hdv-btn-yellow " + (this.state.alerta.enableEditar === true ? "" : "hdv-btn-disable") + " " + auth.check_to_show(['ALE-E03'])}>Editar</button>
                            <button onClick={() => { if (this.state.alerta.enableDeletar === true) { this.deletar('alerta') } }} className={"hdv-btn-forms hdv-btn-red " + (this.state.alerta.enableDeletar === true ? "" : "hdv-btn-disable") + " " + auth.check_to_show(['ALE-X04'])}>Excluir</button>
                        </div>

                        <ReactTable
                            ref={(refReactTable) => { this.refReactTableAlerta = refReactTable; }}
                            manual
                            filterable
                            data={this.state.alerta.data}
                            pages={this.state.alerta.pages}
                            loading={this.state.alerta.loading}
                            onFetchData={this.getDataAlerta}
                            columns={columnsAlerta}
                            defaultPageSize={10}
                            previousText={'Anterior'}
                            nextText={'Próximo'}
                            loadingText={'Carregando...'}
                            noDataText={'Nenhum registro para exibir'}
                            pageText={'Página'}
                            ofText={'de'}
                            rowsText={'linhas'}
                        />

                    </TabPanel>
                    <TabPanel>
                        <div>
                            <div className="hdv-btn-group">
                                <Link to={`/campo-customizado/${this.props.match.params.imei}/novo`}>
                                    <button className={"hdv-btn-forms hdv-btn-geen " + auth.check_to_show(['CAC-A02'])}>Novo</button>
                                </Link>
                                <button onClick={() => this.state.campoCustomizado.enableEditar ? this.editar('campoCustomizado') : null} className={"hdv-btn-forms hdv-btn-yellow " + (this.state.campoCustomizado.enableEditar ? "" : "hdv-btn-disable"  + " " + auth.check_to_show(['CAC-E03']))}>Editar</button>
                                <button onClick={() => this.state.campoCustomizado.enableDeletar ? this.deletar('campoCustomizado') : null} className={"hdv-btn-forms hdv-btn-red " + (this.state.campoCustomizado.enableDeletar ? "" : "hdv-btn-disable"  + " " + auth.check_to_show(['CAC-X04']))}>Excluir</button>
                            </div>
                        </div>
                        <ReactTable
                            ref={(refReactTable) => { this.refReactTableCampoCustomizado = refReactTable }}
                            manual
                            filterable
                            data={this.state.campoCustomizado.data}
                            pages={this.state.campoCustomizado.pages}
                            loading={this.state.campoCustomizado.loading}
                            onFetchData={this.getDataCampoCustomizado}
                            columns={columnsCamposCustomizados}
                            defaultPageSize={10}
                            previousText={'Anterior'}
                            nextText={'Próximo'}
                            loadingText={'Carregando...'}
                            noDataText={'Nenhum registro para exibir'}
                            pageText={'Página'}
                            ofText={'de'}
                            rowsText={'linhas'}
                        />
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}

export default Status
