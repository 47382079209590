import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import auth from "../utils/auth"

class MenuSystem extends Component {

    openSubMenu(e) {
        let submenu = e.target.querySelector('.hdv-menu-item-grupo')
        if (!submenu) {
            return
        }
        submenu.classList.toggle("hdv-menu-item-grupo-open")
    }

    check_to_show(codigo_permissao_checar) {

        if (auth.getUserInfo()['tipo'] === "admin" || auth.getUserInfo()['tipo'] === "master") {
            return ""
        }

        let permissoes = auth.getUserInfo()['permissoes']
        let perm_checar = ""
        let tem_permissao = permissoes.filter((permissao) => {
            for (perm_checar in codigo_permissao_checar) {
                if (permissao.codigo_permissao === codigo_permissao_checar[perm_checar]) {
                    return true
                }
            }
            return false
        })
        console.log(tem_permissao)
        if (tem_permissao.length === 0) {
            return "hdv-noshow-item"
        }

    }

    render() {
        console.log(this.props.pathCurrent)
        return (
            <div id="scroll-menu">
                <div style={{ color: this.props.coresCfg.fonte_grupo_menu_lateral }} className="hdv-menu-grupo">
                    Sistema
                </div>
                <Link className="hdv-menu-parent" to="/">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent === "/") ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-tachometer-alt fa-20px"></i> Dashboard
                    </div>
                </Link>
                <Link className={"hdv-menu-parent " + this.check_to_show(['MOD-LISTEQP'])} to="/ultima-comunicacao">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/ultima-comunicacao") || this.props.pathCurrent.includes("/status")) ? "hdv-menu hdv-menu-selected" : "hdv-menu"}>
                        <i className="fa fa-list fa-20px"></i> Última Comunicação
                    </div>
                </Link>
                <Link className={"hdv-menu-parent " + this.check_to_show(['REL-SUPERV'])} to="/supervisorio">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu">
                        <i className="fa fa-project-diagram fa-20px"></i> Supervisório
                    </div>
                </Link>
                <div style={{ color: this.props.coresCfg.fonte_grupo_menu_lateral }} className={"hdv-menu-grupo " + this.check_to_show(['CLI-V01', 'CLI-A02', 'CLI-E03', 'CLI-X04', 'EMP-V01', 'EMP-A02', 'EMP-E03', 'EMP-X04', 'APL-V01', 'APL-A02', 'APL-E03', 'APL-X04', 'EQP-V01', 'EQP-A02', 'EQP-E03', 'EQP-X04', 'OPE-V01', 'OPE-A02', 'OPE-E03', 'OPE-X04'])}>
                    Cadastros
                </div>
                <div className={"hdv-menu-parent " + this.check_to_show(['CLI-V01', 'CLI-A02', 'CLI-E03', 'CLI-X04', 'PAC-V01', 'PAC-A02', 'PAC-E03', 'PAC-X04'])}>
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/cliente") === true || this.props.pathCurrent.includes("/perfil/cliente") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} onClick={((e) => this.openSubMenu(e))}>
                        <i className="fa fa-users fa-20px"></i> Clientes <i className="fa fa-caret-down hdv-caret-menu"></i>
                        <div className="hdv-menu-item-grupo">
                            <Link className={"hdv-submenu " + this.check_to_show(['CLI-V01', 'CLI-A02', 'CLI-E03', 'CLI-X04'])} to="/cliente">
                                <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu-item" >
                                    <i className="fa fa-angle-double-right fa-12px"></i> Lista de Clientes
                                </div>
                            </Link>
                            <Link className={"hdv-submenu " + this.check_to_show(['PAC-V01', 'PAC-A02', 'PAC-E03', 'PAC-X04'], true)} to="/perfil/cliente">
                                <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu-item">
                                    <i className="fa fa-angle-double-right fa-12px"></i> Perfis
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                {/*<Link className={"hdv-menu-parent " + this.check_to_show(['CLI-V01', 'CLI-A02', 'CLI-E03', 'CLI-X04'])} to="/cliente">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/cliente") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-users fa-20px"></i> Clientes
                    </div>
                </Link>*/}
                {/*<div className={"hdv-menu-parent " + this.check_to_show(['APL-V01', 'APL-A02', 'APL-E03', 'APL-X04'])}>
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/aplicacao") === true || this.props.pathCurrent.includes("/setor") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} onClick={((e) => this.openSubMenu(e))}>
                        <i className="fa fa-industry fa-20px"></i> Aplicações <i className="fa fa-caret-down hdv-caret-menu"></i>
                        <div className="hdv-menu-item-grupo">
                            <Link className="hdv-submenu" to="/aplicacao">
                                <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu-item">
                                    <i className="fa fa-angle-double-right fa-12px"></i> Criar nova aplicação
                                </div>
                            </Link>
                            <Link className="hdv-submenu" to="/setor">
                                <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu-item">
                                    <i className="fa fa-angle-double-right fa-12px"></i> Setores
                                </div>
                            </Link>
                            <Link className="hdv-submenu" to="/monitorado">
                                <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu-item">
                                    <i className="fa fa-angle-double-right fa-12px"></i> Monitorados
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>*/}
                <div className={"hdv-menu-parent " + this.check_to_show(['APL-V01', 'APL-A02', 'APL-E03', 'APL-X04', 'EQP-V01', 'EQP-A02', 'EQP-E03', 'EQP-X04'])}>
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/equipamento") === true || this.props.pathCurrent.includes("/monitorado") === true) || this.props.pathCurrent.includes("/virtual") === true || this.props.pathCurrent.includes("/sideccr") === true || this.props.pathCurrent.includes("/acessos") === true ? "hdv-menu hdv-menu-selected" : "hdv-menu"} onClick={((e) => this.openSubMenu(e))}>
                        <i className="fa fa-hdd fa-20px"></i> Equipamentos <i className="fa fa-caret-down hdv-caret-menu"></i>
                        <div className="hdv-menu-item-grupo">
                            <Link className={"hdv-submenu " + this.check_to_show(['EQP-V01', 'EQP-A02', 'EQP-E03', 'EQP-X04'], true)} to="/acessos">
                                <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu-item">
                                    <i className="fa fa-angle-double-right fa-12px"></i> Acessos
                                </div>
                            </Link>
                            <Link className={"hdv-submenu " + this.check_to_show(['EQP-V01', 'EQP-A02', 'EQP-E03', 'EQP-X04'])} to="/equipamento">
                                <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu-item" >
                                    <i className="fa fa-angle-double-right fa-12px"></i> Lista de Equipamentos
                                </div>
                            </Link>
                            <Link className={"hdv-submenu " + this.check_to_show(['APL-V01', 'APL-A02', 'APL-E03', 'APL-X04'])} to="/monitorado">
                                <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu-item">
                                    <i className="fa fa-angle-double-right fa-12px"></i> Monitorados
                                </div>
                            </Link>
                            <Link className={"hdv-submenu " + this.check_to_show(['EQP-V01', 'EQP-A02', 'EQP-E03', 'EQP-X04'])} to="/virtual">
                                <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu-item" >
                                    <i className="fa fa-angle-double-right fa-12px"></i> Reporte Manual
                                </div>
                            </Link>
                            <Link className={"hdv-submenu" + this.check_to_show(['EQP-V01', 'EQP-A02', 'EQP-E03', 'EQP-X04'])} to="/sideccr">
                                <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu-item">
                                    <i className="fa fa-angle-double-right fa-12px"></i> Sideccr-R
                                </div>
                            </Link>
                            {/*<Link className="hdv-submenu" to="/item">
                                <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu-item">
                                    <i className="fa fa-angle-double-right fa-12px"></i> Itens
                                </div>
                            </Link>*/}
                        </div>
                    </div>
                </div>
                {/*<Link className={"hdv-menu-parent " + this.check_to_show(['EQP-V01', 'EQP-A02', 'EQP-E03', 'EQP-X04'])} to="/equipamento">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/equipamento") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-hdd fa-20px"></i> Equipamentos
                    </div>
                </Link>*/}
                {/*<Link className={"hdv-menu-parent " + this.check_to_show(['REL-LOGS'])} to="/captacao/">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/captacao") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-tint fa-20px"></i> Captação
                    </div>
                </Link>

                <Link className={"hdv-menu-parent " + this.check_to_show(['REL-LOGS'])} to="/gestao/">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/gestao") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-cogs fa-20px"></i> Gestão Manual
                    </div>
                </Link>*/}
                <div className={"hdv-menu-parent " + this.check_to_show(['OPE-V01', 'OPE-A02', 'OPE-E03', 'OPE-X04'])}>
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/base") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} onClick={((e) => this.openSubMenu(e))}>
                        <i className="fa fa-database fa-20px"></i> Base <i className="fa fa-caret-down hdv-caret-menu"></i>
                        <div className="hdv-menu-item-grupo">
                            <Link className={"hdv-submenu " + this.check_to_show(['OPE-V01', 'OPE-A02', 'OPE-E03', 'OPE-X04'], true)} to="/base/operadora">
                                <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu-item">
                                    <i className="fa fa-angle-double-right fa-12px"></i> Operadoras
                                </div>
                            </Link>
                            {/*<div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu-item">
                                <i className="fa fa-angle-double-right fa-12px"></i> Construtor
                            </div>
                            <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu-item">
                                <i className="fa fa-angle-double-right fa-12px"></i> UGRHI
                            </div>
                            <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu-item">
                                <i className="fa fa-angle-double-right fa-12px"></i> Fluidos
                            </div>
                            <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu-item">
                                <i className="fa fa-angle-double-right fa-12px"></i> Métodos de perfuração
                            </div>
                            <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu-item">
                                <i className="fa fa-angle-double-right fa-12px"></i> Revestimento tubo liso
                            </div>
                            <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu-item">
                                <i className="fa fa-angle-double-right fa-12px"></i> Revestimento filtro
                            </div>
                            <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu-item">
                                <i className="fa fa-angle-double-right fa-12px"></i> Pré-Filtro
                            </div>
                            <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu-item">
                                <i className="fa fa-angle-double-right fa-12px"></i> Cimentação
                            </div>
                            <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu-item">
                                <i className="fa fa-angle-double-right fa-12px"></i> Perfilagem elétrica
                            </div>
                            <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu-item">
                                <i className="fa fa-angle-double-right fa-12px"></i> GRP / Formações
                            </div>
                            <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu-item">
                                <i className="fa fa-angle-double-right fa-12px"></i> Ánalises da água
                            </div>*/}
                        </div>
                    </div>
                </div>
                <Link className={"hdv-menu-parent " + this.check_to_show(['EMP-V01', 'EMP-A02', 'EMP-E03', 'EMP-X04'])} to="/empresa">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/empresa") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-building fa-20px"></i> Empresas
                    </div>
                </Link>
                {/*<div style={{ color: this.props.coresCfg.fonte_grupo_menu_lateral }} className={"hdv-menu-grupo " + this.check_to_show(['PAR-V01', 'PAR-A02', 'PAR-E03', 'PAR-X04'])}>
                    Operações
                </div>
                <Link className={"hdv-menu-parent " + this.check_to_show(['PAR-V01', 'PAR-A02', 'PAR-E03', 'PAR-X04'])} to="/parametrizacao">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/parametrizacao") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-list fa-20px"></i> Parametrização
                    </div>
                </Link>
                <Link className={"hdv-menu-parent " + this.check_to_show(['EQP-V01', 'EQP-A02', 'EQP-E03', 'EQP-X04'])} to="/virtual">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/virtual") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-edit fa-20px"></i> Reporte Manual
                    </div>
                </Link>
                <Link className={"hdv-menu-parent " + this.check_to_show(['EQP-V01', 'EQP-A02', 'EQP-E03', 'EQP-X04'])} to="/sideccr">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={this.props.pathCurrent.includes("/sideccr") ? "hdv-menu hdv-menu-selected" : "hdv-menu"}>
                        <i className="fa fa-paste fa-20px"></i> SIDECC-R
                    </div>
                </Link>*/}
                {/*<Link className="hdv-menu-parent" to="/automacao">
                    <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu">
                        <i className="fa fa-cogs fa-20px"></i> Automações
                    </div>
                </Link>
                <Link className="hdv-menu-parent" to="/alerta">
                    <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu">
                        <i className="fa fa-exclamation-triangle fa-20px"></i> Alertas
                    </div>
                </Link>*/}
                {/*<div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu">
                    <i className="fa fa-flask fa-20px"></i> Teste de Bombeamento
                </div>
                <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu">
                    <i className="fa fa-wrench fa-20px"></i> Manutenções
                </div>
                <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu">
                    <i className="fa fa-paperclip fa-20px"></i> Anexos
                </div>*/}
                <div style={{ color: this.props.coresCfg.fonte_grupo_menu_lateral }} className={"hdv-menu-grupo " + this.check_to_show(['REL-ENTDIG', 'REL-ENTANA', 'REL-DADELE'])}>
                    Relatórios
                </div>
                <Link className={"hdv-menu-parent " + this.check_to_show(['REL-ENTDIG'])} to="/relatorio/consumo-agua">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/consumo-agua") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-tint fa-20px"></i> Entradas Dígitais
                    </div>
                </Link>
                <Link className={"hdv-menu-parent " + this.check_to_show(['REL-ENTANA'])} to="/relatorio/4a20">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/4a20") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-folder fa-20px"></i> Entradas Analógicas
                    </div>
                </Link>
                <Link className={"hdv-menu-parent " + this.check_to_show(['REL-DADELE'])} to="/relatorio/energia">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/energia") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-bolt fa-20px"></i> Dados Elétricos
                    </div>
                </Link>
                <Link className={"hdv-menu-parent " + this.check_to_show(['REL-LOGS'])} to="/relatorio/logs">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/logs") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-clipboard fa-20px"></i> Logs
                    </div>
                </Link>
                <Link className={"hdv-menu-parent " + this.check_to_show(['REL-LOGS'])} to="/relatorio/analitico">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/relatorio/lancamento-manual") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-sticky-note fa-20px"></i> Analítico
                    </div>
                </Link>
                <Link className={"hdv-menu-parent " + this.check_to_show(['REL-ENTDIG'])} to="/relatorio-customizado">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/relatorio-customizado") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-chart-bar fa-20px"></i> Relatório Customizado
                    </div>
                </Link>
                <Link className={"hdv-menu-parent " + this.check_to_show(['REL-ENTDIG'])} to="/relatorio-consumo">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/relatorio-consumo") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-chart-area fa-20px"></i> Relatório de Consumo
                    </div>
                </Link>
                {/*<Link className={"hdv-menu-parent " + this.check_to_show(['REL-LOGS'])} to="/relatorio/digital">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/relatorio/digital") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-sticky-note fa-20px"></i> Relatório Digital
                    </div>
                </Link>
                <Link className={"hdv-menu-parent " + this.check_to_show(['REL-LOGS'])} to="/relatorio/analogico">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/relatorio/analogico") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-sticky-note fa-20px"></i> Relatório Analogico
                    </div>
                </Link>
                <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu">
                    <i className="fa fa-line-chart fa-20px"></i> Eficiência
                </div>
                <div style={{color: this.props.coresCfg.fonte_menu_lateral}} className="hdv-menu">
                    <i className="fa fa-map-pin fa-20px"></i> Construtivo
                </div>*/}
                <div style={{ color: this.props.coresCfg.fonte_grupo_menu_lateral }} className={"hdv-menu-grupo " + this.check_to_show(['CFG-MINCON', 'PAA-V01', 'PAA-A02', 'PAA-E03', 'PAA-X04', 'PAC-V01', 'PAC-A02', 'PAC-E03', 'PAC-X04', 'USU-V01', 'USU-A02', 'USU-E03', 'USU-X04', 'CFG-SIS'])}>
                    Configurações
                </div>
                {/*<Link className={"hdv-menu-parent " + this.check_to_show(['CFG-MINCON'])} to="/minha-conta">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/minha-conta") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-user fa-20px"></i> Minha conta
                    </div>
                </Link>*/}
                <div className={"hdv-menu-parent " + this.check_to_show(['CFG-MINCON', 'PAA-V01', 'PAA-A02', 'PAA-E03', 'PAA-X04', 'USU-V01', 'USU-A02', 'USU-E03', 'USU-X04'])}>
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/usuario") === true || this.props.pathCurrent.includes("/minha-conta") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} onClick={((e) => this.openSubMenu(e))}>
                        <i className="fa fa-unlock-alt fa-20px"></i> Usuários <i className="fa fa-caret-down hdv-caret-menu"></i>
                        <div className="hdv-menu-item-grupo">
                            <Link className={"hdv-submenu " + this.check_to_show(['CFG-MINCON'])} to="/minha-conta">
                                <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu-item" >
                                    <i className="fa fa-angle-double-right fa-12px"></i> Minha conta
                                </div>
                            </Link>
                            <Link className={"hdv-submenu " + this.check_to_show(['USU-V01', 'USU-A02', 'USU-E03', 'USU-X04'], true)} to="/usuario">
                                <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu-item">
                                    <i className="fa fa-angle-double-right fa-12px"></i> Lista de Usuários
                                </div>
                            </Link>
                            <Link className={"hdv-submenu " + this.check_to_show(['PAA-V01', 'PAA-A02', 'PAA-E03', 'PAA-X04'], true)} to="/perfil/usuario">
                                <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-menu-item">
                                    <i className="fa fa-angle-double-right fa-12px"></i> Perfis
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                {/*<Link className={"hdv-menu-parent " + this.check_to_show(['USU-V01', 'USU-A02', 'USU-E03', 'USU-X04'])} to="/usuario">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/usuario") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"}>
                        <i className="fa fa-user-plus fa-20px"></i> Usuários
                    </div>
                </Link>*/}
                <Link className={"hdv-menu-parent " + this.check_to_show(['CFG-SIS'])} to="/configuracao">
                    <div style={{ color: this.props.coresCfg.fonte_menu_lateral }} className={(this.props.pathCurrent.includes("/configuracao") === true) ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                        <i className="fa fa-cog fa-20px"></i>  Sistema
                    </div>
                </Link>
            </div>
        )
    }
}

export default MenuSystem;