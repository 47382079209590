import React, { Component } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import _ from "underscore"
import axios from 'axios'
import ModalAlerta from '../../components/ModalAlerta'
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom'

class ListaAlertas extends Component {

    state = {
        data: [],
        pages: 1,
        loading: true,
        filtered: true,
        selected: []
    }

    showMenu = (e) => {
        (document.getElementById(e.target.getAttribute('data-id')).style.display === 'block') ?
            document.getElementById(e.target.getAttribute('data-id')).style.display = 'none' :
            document.getElementById(e.target.getAttribute('data-id')).style.display = 'block'
    }

    getData = _.debounce((state, instance) => {
        if (state) {
            if ("pageSize" in state) {
                this.setState({ loading: true });
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                let userInfo = auth.getUserInfo();

                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/alerta-historico/?page=' + state.page + '&pageSize=' + state.pageSize + '&filter=' + encodeURI(JSON.stringify(state.filtered)), config_me)
                    .then((response) => {
                        this.setState({ selected: [], data: response.data.results, loading: false, pages: response.data.pages, filtered: true });
                    })
            }
        }
    }, 500)

    render() {
        console.log(this.state)

        const columns = [{
            Header: 'Alerta',
            accessor: 'alerta__descricao',
            Cell: ({ original }) => {
                return (<ModalAlerta id_alerta={original.id} label={original.alerta__descricao} />)
            }
        }, {
            Header: 'Data do Alerta',
            accessor: 'timestamp_gerado',
            Cell: ({ original }) => {
                let data_in_timezone_user = moment.tz(original.timestamp_gerado * 1000, 'UTC')
                let data_formatada = data_in_timezone_user.tz(auth.getUserInfo().timezone).format('DD/MM/YYYY HH:mm:ss')
                return (
                    <div>
                        {data_formatada}
                    </div>
                )
            }
        }, {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ original }) => {
                return (
                    <div>
                        {original.status.charAt(0).toUpperCase() + original.status.slice(1)}
                    </div>
                )
            }
        }, {
            Header: 'Severidade',
            accessor: 'alerta__severidade',
            Cell: ({ original }) => {
                return (
                    <div>
                        {original.alerta__severidade.charAt(0).toUpperCase() + original.alerta__severidade.slice(1)}
                    </div>
                )
            }
        }, {
            Header: 'Monitorado',
            accessor: 'alerta__monitorado__nome'
        }, {
            Header: 'Equipamento',
            accessor: 'alerta__equipamento__imei',
            Cell: ({ original }) => (
                <Link to={`/equipamento/${original.alerta__equipamento__imei}/status/`}>{original.alerta__equipamento__imei}</Link>
            )
        }]

        return (
            <div className="hdv-render-div">
                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">

                    <h4>Alertas do Sistema</h4>
                    <br />
                    <ReactTable
                        ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                        manual
                        filterable
                        data={this.state.data}
                        pages={this.state.pages}
                        loading={this.state.loading}
                        onFetchData={this.getData}
                        columns={columns}
                        defaultPageSize={10}
                        previousText={'Anterior'}
                        nextText={'Próximo'}
                        loadingText={'Carregando...'}
                        noDataText={'Nenhum registro para exibir'}
                        pageText={'Página'}
                        ofText={'de'}
                        rowsText={'linhas'}
                    />

                </div>
            </div>
        );
    }
}

export default ListaAlertas;
