

import React, { Component } from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    ModalFooter
} from 'reactstrap'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Select, { components } from 'react-select'
import axios from 'axios'
import ReactLoading from 'react-loading'
import CampoCustomizado from '../../components/CampoCustomizado'
import { compile, isNaN } from 'mathjs'

export default class RelatorioCustomizado extends Component {
    config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
    baseUrl = `${cfg.base_api_url + cfg.api_version}`
    userInfo = auth.getUserInfo()

    constructor() {
        super()
        this.state = {
            tabIndex: 0,
            graficoModal: {
                ativo: false, novo: true,
                grafico: {
                    descricao: '', tipo_grafico: '', campo_referencia: '', data: [], cor_linha: '#4287f5', label: true,
                    visualizacao: { label: 'Média do período', value: 'med' }, calcular_como: { label: 'Padrão', value: 'padrao' }
                },
            },
            referenciaModal: {
                ativo: false, novo: true,
                referencia: { descricao: '', calcula_como_volume: false, cor_linha: '#20db6e', valor: 0, alerta: '', automacao: '', tipo_da_referencia: null },
            },
            resumoModal: {
                ativo: false, novo: true,
                resumo: { descricao: '', formula: '', icone: '', custo: 0.00, tipo: { label: 'Mínimo, Médio e Máximo', value: 'min_med_max' } }
            },
            confirmDelete: { ativo: false, resource: null },
            teste: { resultado: '0', invalido: false, erro: '' },
            tiposGrafico: [{ label: 'Linha', value: 'linha' }, { label: 'Barra', value: 'barra' }]
        }
    }

    removeResource = (resource) => {
        const { confirmDelete } = this.state
        const urls = {
            'grafico': this.baseUrl + `/${this.userInfo.empresa}/relatorio/${this.props.id}/grafico/${resource.id}`,
            'referencia': this.baseUrl + `/${this.userInfo.empresa}/relatorio/${this.props.id}/referencia/${resource.id}`,
            'resumo': this.baseUrl + `/${this.userInfo.empresa}/relatorio/${this.props.id}/resumo/${resource.id}`
        }

        axios.delete(urls[confirmDelete.tipo], this.config_me)
            .then(res => this.props.getData(false))
            .catch(err => console.log(err))
        this.setState({ confirmDelete: { ativo: false, resource: null } })
    }

    confirmDelete = (resource, tipo) => this.setState({ confirmDelete: { ativo: true, resource: resource, tipo } })

    saveChartConfig = () => {

        

        const { graficoModal } = this.state
        let url, verb

        if (graficoModal.novo) {
            verb = 'POST'
            url = this.baseUrl + `/${this.userInfo.empresa}/relatorio/${this.props.id}/grafico/`
        } else {
            verb = 'PATCH'
            url = this.baseUrl + `/${this.userInfo.empresa}/relatorio/${this.props.id}/grafico/${graficoModal.grafico.id}/`
        }
        
        let valid = true
        let except = ['optionEntrada', 'label', 'data', 'valido', 'unidade']
        
        if (!graficoModal.grafico['sinal'])
            delete graficoModal.grafico['sinal']

        for (let key in graficoModal.grafico) {
            if (!graficoModal.grafico[key] && !except.includes(key)) valid = false
        }
        
        if (!valid) {
            graficoModal.valido = false
            this.setState({ graficoModal })
            return
        }
        
        const bodyFormData = new FormData()
        bodyFormData.append('relatorio_id', this.props.id)
        bodyFormData.append('campo_referencia', graficoModal.grafico.campo_referencia)
        bodyFormData.append('descricao', graficoModal.grafico.descricao)
        bodyFormData.append('calcular_como', graficoModal.grafico.calcular_como.value)
        bodyFormData.append('cor_linha', graficoModal.grafico.cor_linha)
        bodyFormData.append('visualizacao', graficoModal.grafico.tipo_grafico?.value === 'barra' ? 'med' : graficoModal.grafico.visualizacao?.value)
        bodyFormData.append('tipo_grafico', graficoModal.grafico.tipo_grafico.value)

        axios({ method: verb, url: url, data: bodyFormData, ...this.config_me })
            .then(res => {
                this.props.getData(false)
                this.setState({
                    graficoModal: {
                        ativo: false, novo: true,
                        grafico: {
                            descricao: '', tipo_grafico: '', campo_referencia: '', data: [], cor_linha: '#4287f5', label: true,
                            visualizacao: { label: 'Média do período', value: 'med' },
                            calcular_como: { label: 'Padrão', value: 'padrao' }
                        },
                    },
                })
            })
            .catch(err => console.log(err))
    }

    saveReferencia = () => {
        const { referenciaModal, graficoModal } = this.state
        let verb, url
        let valid = true

        if (referenciaModal.novo) {
            verb = 'POST'
            url = this.baseUrl + `/${this.userInfo.empresa}/relatorio/${this.props.id}/referencia/`
        } else {
            verb = 'PATCH'
            url = this.baseUrl + `/${this.userInfo.empresa}/relatorio/${this.props.id}/referencia/${referenciaModal.referencia.id}/`
        }

        const except = ['valor', 'alerta', 'automacao', 'valido', 'calcula_como_volume', 'campo_referencia', 'automacao_id', 'automacao__descricao', 'alerta_id', 'alerta__descricao']
        for (let key in referenciaModal.referencia) {
            if (!referenciaModal.referencia[key] && !except.includes(key)) {
                valid = false
            }
        }

        if (!valid) return

        if (referenciaModal.referencia.tipo_da_referencia) {
            const bodyFormData = new FormData()
            bodyFormData.append('relatorio_id', this.props.id)
            bodyFormData.append('grafico_id', graficoModal.grafico.id)
            bodyFormData.append('descricao', referenciaModal.referencia.descricao)
            bodyFormData.append('cor_linha', referenciaModal.referencia.cor_linha)
            bodyFormData.append('tipo_da_referencia', referenciaModal.referencia.tipo_da_referencia?.value ?? '')
            bodyFormData.append('calcula_como_volume', referenciaModal.referencia.calcula_como_volume ? '1' : '0')

            let campos = {
                'campo_referencia': null,
                'alerta_id': null,
                'automacao_id': null,
                'valor': null
            }

            switch (referenciaModal.referencia.tipo_da_referencia?.value) {
                default: break
                case 'fixa':
                    campos['valor'] = referenciaModal.referencia?.valor ? Number(referenciaModal.referencia?.valor) : null
                    break
                case 'dinamico':
                    campos['campo_referencia'] = referenciaModal.referencia?.campo_referencia
                    break
                case 'alerta':
                    campos['alerta_id'] = referenciaModal.referencia?.alerta?.value
                    break
                case 'automacao':
                    campos['automacao_id'] = referenciaModal.referencia?.automacao?.value
                    break
            }

            if (Object.values(campos).every(e => e === null || e === undefined)) return
            else {
                Object.keys(campos).map(key => bodyFormData.append(key, campos[key]))
                axios({ method: verb, url: url, data: bodyFormData, ...this.config_me })
                    .then(res => {
                        this.setState({
                            referenciaModal: {
                                ativo: false, novo: true,
                                referencia: {
                                    descricao: '',
                                    calcula_como_volume: false,
                                    cor_linha: '#20db6e',
                                    valor: 0, alerta: '',
                                    automacao: '',
                                    tipo_da_referencia: null
                                },
                            },
                        })
                        this.props.getData(false)
                    })
                    .catch(err => console.log(err))
            }
        }
    }

    saveResumo = () => {
        const { resumoModal, graficoModal } = this.state
        let verb, url
        let valid = true

        if (resumoModal.novo) {
            verb = 'POST'
            url = this.baseUrl + `/${this.userInfo.empresa}/relatorio/${this.props.id}/resumo/`
        } else {
            verb = 'PATCH'
            url = this.baseUrl + `/${this.userInfo.empresa}/relatorio/${this.props.id}/resumo/${resumoModal.resumo.id}/`
        }

        if (resumoModal.tipo === 'formula') {
            if (!this.testarFormula()) return
        }

        const except = ['formula', 'personalizado', 'custo_unidade', 'custo']
        for (let key in resumoModal.resumo) {
            if (!resumoModal.resumo[key] && !except.includes(key)) {
                valid = false
            }
        }

        if (!valid) return

        const bodyFormData = new FormData()
        bodyFormData.append('relatorio_id', this.props.id)
        bodyFormData.append('grafico_id', graficoModal.grafico.id)
        bodyFormData.append('descricao', resumoModal.resumo.descricao)
        bodyFormData.append('icone', resumoModal.resumo.icone.value)
        bodyFormData.append('custo_unidade', resumoModal.resumo.custo_unidade ?? 0)
        bodyFormData.append('campo_referencia', resumoModal.resumo?.campo_referencia ?? graficoModal.grafico?.campo_referencia)

        if (resumoModal.resumo.tipo?.value !== 'formula') {
            bodyFormData.append('resumo_pre_definido', resumoModal.resumo.tipo.value === 'custo_unidade' ? 'custo' : resumoModal.resumo.tipo.value)
            bodyFormData.append('formula', null)
        } else {
            bodyFormData.append('formula', resumoModal.resumo.formula)
        }

        axios({ method: verb, url: url, data: bodyFormData, ...this.config_me }).
            then(res => {
                this.setState({
                    resumoModal: {
                        ativo: false, novo: true,
                        resumo: { descricao: '', formula: '', icone: '', custo: 0.00, tipo: { label: 'Mínimo, Médio e Máximo', value: 'min_med_max' } }
                    }
                })
                this.props.getData(false)
            })
            .catch(err => console.log(err))
    }

    testarFormula = () => {
        let { teste, resumoModal } = this.state
        const { ultimoReporte } = this.props.resource

        teste['invalido'] = false
        teste['erro'] = ''
        let valid = true
        this.setState({ teste })

        let formula = resumoModal.resumo?.['formula']
        formula = formula.replace(/,/g, '.')
        if (formula.length === 0) return

        try {
            let variaveis = Array.from(formula.matchAll(/\[(.*?)\]/g)).map(v => {
                let split = v[1].split('_')
                let ultimo = split[split.length - 1]
                let value = null

                if (/^\d+$/.test(ultimo)) {
                    split.pop()
                    let index = split.join('_')
                    value = ultimoReporte[index][ultimo - 1]
                }
                else
                    value = ultimoReporte[v[1]]

                return { id: `[${v[1]}]`, value: Number(value) }
            })

            variaveis.map(v => formula = formula.replace(v.id, v.value.toString()))

            const code = compile(formula)
            const resultado = code.evaluate()
            if (isNaN(resultado) || resultado === Infinity) {
                teste['invalido'] = true
                teste['erro'] = 'Fórmula Inválida!'
                valid = false
            }

            else {
                teste['invalido'] = false
                valid = true
            }

            this.setState({ teste })
        } catch (e) {
            teste['invalido'] = true
            teste['erro'] = 'Fórmula Inválida!'
            valid = false
            this.setState({ teste })
        }
        return valid
    }

    handleChangeConfig = (e, field) => {
        let { graficoModal } = this.state
        graficoModal.grafico[field] = e

        if (field === 'campo_referencia') {
            const campoReferencia = graficoModal.grafico['campo_referencia']?.replace('[', [])?.replace(']', '')
            let find = this.props.resource.entradas4a20.find(entrada => entrada.value === campoReferencia)

            if (!find) {
                find = this.props.resource.entradasLogicas.find(entrada => entrada.value === campoReferencia)
            }

            graficoModal.grafico['optionEntrada'] = find ? find : ""
        }

        this.setState({ graficoModal })
    }

    handleChangeReferencia = (e, field) => {
        let { referenciaModal } = this.state
        referenciaModal.referencia[field] = e

        this.setState({ referenciaModal })
    }

    resetarGrafico = () => {
        this.setState({
            graficoModal: {
                ativo: true, novo: true,
                grafico: {
                    descricao: '', tipo_grafico: '', campo_referencia: '', data: [], cor_linha: '#4287f5', label: true,
                    visualizacao: { label: 'Média do período', value: 'med' }, calcular_como: { label: 'Padrão', value: 'padrao' }
                },
            },
        })
    }

    handleChangeResumo = (e, field) => {
        let { resumoModal } = this.state
        resumoModal.resumo[field] = e
        this.setState({ resumoModal })
        if (field === 'tipo')
            setTimeout(this.reloadState(), 120)
    }

    gerarTipoReferencia = (state, fun, nova) => {

        return (
            <div className={state?.tipo_da_referencia?.value ? 'col-md-12 hdv-bloco-top-space' : 'hdv-noshow-item'}>
                <div className={state?.tipo_da_referencia?.value === 'automacao' ? '' : 'hdv-noshow-item'}>
                    <label>Automação</label>
                    <Select
                        value={state?.automacao}
                        onChange={(e) => fun(e, 'automacao', nova)}
                        options={this.props.resource.automacaoOptions}
                    />
                </div>
                <div className={state?.tipo_da_referencia?.value === 'alerta' ? '' : 'hdv-noshow-item'}>
                    <label>Alerta</label>
                    <Select
                        value={state?.alerta}
                        onChange={(e) => fun(e, 'alerta', nova)}
                        options={this.props.resource.alertaOptions}
                    />
                </div>
                <div className={state?.tipo_da_referencia?.value === 'fixa' ? '' : 'hdv-noshow-item'}>
                    <label>Valor Fixo</label>
                    <input
                        type="number"
                        value={state?.valor}
                        onChange={(e) => fun(e.target.value, 'valor', nova)}
                    />
                </div>
                <div className={state?.tipo_da_referencia?.value === 'dinamico' ? '' : 'hdv-noshow-item'}>
                    <div className="hdv-form-default">
                        <label>Campo de referência</label>
                        <input
                            type="text"
                            readOnly
                            value={state?.campo_referencia ?? ''}
                        />
                    </div>
                    <div className="campo-customizado" style={{ height: '180px', marginTop: '14px' }}>
                        Último Reporte
                        <CampoCustomizado
                            funcao={(variavel) => {
                                let { referenciaModal } = this.state
                                referenciaModal.referencia['campo_referencia'] = variavel
                                this.setState({ referenciaModal })
                            }}
                            data={this.props?.resource.ultimoReporte}
                            bracket={true}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderNewButton = () => {
        const { tabIndex } = this.state
        let [mensagem, funcao] = ['', null]

        switch (tabIndex) {
            case 1:
                mensagem = "Nova linha de Referência"
                funcao = () => {
                    this.setState({
                        referenciaModal: {
                            ativo: true, novo: true,
                            referencia: { descricao: '', calcula_como_volume: false, cor_linha: '#20db6e', valor: 0, alerta: '', automacao: '', tipo_da_referencia: null }
                        }
                    })
                    this.reloadState()
                }
                break
            case 2:
                mensagem = "Novo Resumo"
                funcao = () => {
                    this.setState({
                        resumoModal: {
                            ativo: true, novo: true,
                            resumo: { descricao: '', formula: '', icone: '', custo: 0.00, tipo: { label: 'Mínimo, Médio e Máximo', value: 'min_med_max' } }
                        }
                    })
                    this.reloadState()
                }
                break
        }

        return tabIndex !== 0 ? (
            <Button
                color="primary"
                onClick={funcao}
                className={this.state.graficoModal.grafico ? "d-flex align-items-center" : "hdv-noshow-item"}>
                <i className="fa fa-plus-circle fa-2x"></i> &nbsp; {mensagem}
            </Button>
        ) : null
    }

    handleChangeCusto = (e) => {
        let { resumoModal } = this.state
        let v = e.target.value.replace(/\D/g, '')
        v = (v / 100).toFixed(2) + ''
        v = v.replace(" ", ",")
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2,$3,")
        v = v.replace(/(\d)(\d{3}),/g, "$1.$2,")
        // let obj = this.state.entradalogica
        resumoModal.resumo['custo_unidade'] = v
        this.setState({ resumoModal })
    }

    selectChart = (chart) => {
        let { graficoModal } = this.state
        if (graficoModal.grafico) {
            graficoModal.grafico['valido'] = true
        }
        const campoReferencia = chart.campo_referencia?.replace('[', [])?.replace(']', '')
        let find = this.props.resource.entradas4a20.find(entrada => entrada.value === campoReferencia)

        if (!find) {
            find = this.props.resource.entradasLogicas.find(entrada => entrada.value === campoReferencia)
        }

        chart['optionEntrada'] = find
        this.setState({ graficoModal: { ativo: true, grafico: chart }, })
    }

    reloadState = () => setTimeout(() => this.setState({ reload: '1' }), 400)

    adicionarNaFormula = (variavel, novo = false) => {
        let { resumoModal } = this.state
        resumoModal.resumo['formula'] += ' ' + variavel
        this.setState({ resumoModal })
    }

    render() {
        const { charts, resumos, referencias } = this.props
        const { referenciaModal, graficoModal, resumoModal } = this.state
        const { Option } = components
        const IconOption = (props) => (
            <Option {...props} className="align-center">
                <i
                    style={{ fontSize: '22px' }}
                    className={props.data.value}></i>
                <span> {props.data.label}</span>
            </Option>
        )

        return (
            <>
                <Modal isOpen={this.state.confirmDelete.ativo} toggle={() => this.setState({ confirmDelete: { ativo: false, resource: null } })}>
                    <ModalHeader
                        toggle={() => { this.setState({ confirmDelete: { ativo: false, resource: null } }) }}>
                        Confirmar
                    </ModalHeader>
                    <ModalBody>
                        Você tem certeza que deseja deletar?
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => this.setState({ confirmDelete: { ativo: false, resource: null } })}>
                            Cancelar
                        </Button>
                        <Button
                            color="danger"
                            onClick={() => this.removeResource(this.state.confirmDelete.resource)}>
                            Sim
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={resumoModal.ativo} toggle={() => this.setState({ resumoModal: { ativo: false, resumo: null } })} className="modal-large">
                    <ModalHeader toggle={() => this.setState({ resumoModal: { ativo: false, resumo: null } })}>
                        {resumoModal.novo ? 'Novo resumo' : 'Editar resumo'}
                    </ModalHeader>
                    <ModalBody>
                        <div className="hdv-relatorio-details hdv-form-default row" style={{ border: 'none' }}>
                            <div className="col-md-8 row hdv-top15">
                                <div className="col-md-6">
                                    <label>Descrição</label>
                                    <input
                                        type="text"
                                        value={resumoModal.resumo?.descricao}
                                        onChange={(e) => this.handleChangeResumo(e.target.value, 'descricao')}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Ícone</label>
                                    <Select
                                        value={resumoModal.resumo?.icone}
                                        components={{ Option: IconOption }}
                                        onChange={(e) => this.handleChangeResumo(e, 'icone')}
                                        options={this.props.resource.resumoIconOptions}
                                    />
                                </div>
                                <div className="col-md-6 hdv-form-default">
                                    <label>Tipo de resumo</label>
                                    <Select
                                        value={resumoModal.resumo?.tipo ?? { label: 'Mínimo, Médio e Máximo', value: 'min_med_max' }}
                                        onChange={(e) => this.handleChangeResumo(e, 'tipo')}
                                        options={this.props.resource.tiposResumo}
                                    />
                                </div>
                                <div className="col-md-6 hdv-form-default">
                                    <label>Campo de referência</label>
                                    <input
                                        type="text"
                                        readOnly
                                        value={resumoModal.resumo?.campo_referencia ?? graficoModal.grafico?.campo_referencia}
                                    />
                                </div>

                                {
                                    resumoModal.resumo?.tipo?.value === 'custo'
                                        ?
                                        <div className="col-md-6 hdv-form-default">
                                            <label>Custo por unidade</label>
                                            <input
                                                type="text"
                                                value={resumoModal.resumo?.custo_unidade}
                                                onChange={(e) => this.handleChangeCusto(e, false)}
                                            />
                                        </div>
                                        :
                                        <div className="col-md-12"></div>
                                }
                                <div className="col-md-12 hdv-top15"></div>
                            </div>
                            <div className="col-md-4 hdv-top15 px-0">
                                <div className="campo-customizado" style={{ height: '240px', marginTop: '0' }}>
                                    Último Reporte
                                    <CampoCustomizado
                                        funcao={(variavel) => {
                                            let { resumoModal } = this.state
                                            resumoModal['resumo']['campo_referencia'] = variavel
                                            this.setState({ resumoModal })
                                            bracket
                                        }}
                                        data={this.props?.resource.ultimoReporte}
                                        bracket={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.teste.invalido ?
                            <span style={{ margin: '0 20px' }} id="validacao-formula">{this.state.teste.erro}</span>
                            :
                            null
                        }
                        <Button
                            color="success"
                            onClick={this.saveResumo}>
                            Salvar
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => this.setState({ resumoModal: { ativo: false, resumo: null } })}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={referenciaModal.ativo} toggle={() => this.setState({ referenciaModal: { ativo: false, referencia: null } })}>
                    <ModalHeader toggle={() => { this.setState({ referenciaModal: { ativo: false, referencia: null } }) }}>
                        {referenciaModal.novo ? 'Nova referência' : 'Editar referência'}
                    </ModalHeader>
                    <ModalBody>
                        <div className="row hdv-relatorio-details" style={{ border: 'none' }}>
                            <div className="col-md-6 hdv-form-default">
                                <label>Descrição</label>
                                <input
                                    type="text"
                                    value={referenciaModal.referencia?.descricao}
                                    onChange={(e) => this.handleChangeReferencia(e.target.value, 'descricao')}
                                />
                            </div>

                            <div className="col-md-3 align-center">
                                <label>Cor
                                    <input
                                        type="color"
                                        onChange={(e) => this.handleChangeReferencia(e.target.value, 'cor_linha')}
                                        value={referenciaModal.referencia?.cor_linha}
                                    />
                                </label>
                            </div>
                            <div className="col-md-12 hdv-form-default hdv-bloco-top-space">
                                <label>Tipo</label>
                                <Select
                                    value={referenciaModal.referencia?.tipo_da_referencia}
                                    onChange={(e) => this.handleChangeReferencia(e, 'tipo_da_referencia')}
                                    options={this.props.resource.referenciaOptions}
                                />
                            </div>

                            {this.gerarTipoReferencia(referenciaModal.referencia, this.handleChangeReferencia, referenciaModal.novo)}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="success"
                            onClick={this.saveReferencia}>
                            Salvar
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => this.setState({ referenciaModal: { ativo: false, referencia: null } })}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={graficoModal.ativo} toggle={() => this.setState({ graficoModal: { ativo: false, grafico: null } })} className="modal-large">
                    <ModalHeader toggle={() => { this.setState({ graficoModal: { ativo: false, grafico: null } }) }}>
                        {graficoModal.novo ? "Novo gráfico" : graficoModal.grafico?.descricao}
                    </ModalHeader>
                    <ModalBody >
                        <Tabs
                            selectedIndex={this.state.tabIndex}
                            style={{ minHeight: '260px' }}
                            onSelect={tabIndex => this.setState({ tabIndex })}>
                            <TabList>
                                <Tab onClick={this.reloadState}>Configurações</Tab>
                                <Tab>Referências</Tab>
                                <Tab>Resumos</Tab>
                            </TabList>
                            <TabPanel>
                                <div className="hdv-relatorio-details row" style={{ border: 'none' }}>
                                    <div className="col-md-7 hdv-form-default">
                                        <label>Descrição</label>
                                        <input
                                            type="text"
                                            value={graficoModal.grafico?.descricao}
                                            onChange={(e) => this.handleChangeConfig(e.target.value, 'descricao')}
                                        />
                                        <div className="hdv-top15">
                                            <label>Campo</label>
                                            <input
                                                type="text"
                                                value={graficoModal.grafico?.campo_referencia}
                                                readOnly
                                            />
                                        </div>
                                        <div className="row hdv-top15">
                                            <div className="col-md-6">
                                                <label>Tipo de gráfico</label>
                                                <Select
                                                    value={graficoModal.grafico?.tipo_grafico}
                                                    onChange={(e) => this.handleChangeConfig(e, 'tipo_grafico')}
                                                    options={[{ label: 'Linha', value: 'linha' }, { label: 'Barra', value: 'barra' }]}
                                                />
                                            </div>
                                            <div className={graficoModal.grafico?.tipo_grafico.value === 'linha' ? "col-md-6" : "hdv-noshow-item"}>
                                                <label>Modo de visualização</label>
                                                <Select
                                                    value={graficoModal.grafico?.visualizacao}
                                                    onChange={(e) => this.handleChangeConfig(e, 'visualizacao')}
                                                    options={[
                                                        { label: 'Média do período', value: 'med' },
                                                        { label: 'Todo o período', value: 'tudo' },
                                                        { label: 'Mínima e Máxima', value: 'min_max' },
                                                    ]}
                                                />
                                            </div>
                                        </div>

                                        <div className="row hdv-bloco-top-space">
                                            <div className="col-md-6">
                                                <label>Calcular como:                                                    </label>
                                                <Select
                                                    value={graficoModal.grafico?.calcular_como}
                                                    onChange={(e) => this.handleChangeConfig(e, 'calcular_como')}
                                                    options={[
                                                        { label: 'Padrão', value: 'padrao' },
                                                        { label: 'Volume', value: 'volume' },
                                                        { label: 'Horímetro', value: 'horimetro' },
                                                    ]}
                                                />
                                            </div>
                                            <div className="col-md-3 align-center">
                                                <label>Cor
                                                    <input
                                                        type="color"
                                                        onChange={(e) => this.handleChangeConfig(e.target.value, 'cor_linha')}
                                                        value={graficoModal.grafico?.cor_linha}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 hdv-form-default">
                                        <label>Entradas</label>
                                        <Select
                                            value={graficoModal.grafico?.optionEntrada}
                                            onChange={(e) => this.handleChangeConfig(`[${e.value}]`, 'campo_referencia')}
                                            options={[
                                                { label: 'Entradas 4a20', options: this.props.resource.entradas4a20 },
                                                { label: 'Entradas Lógicas', options: this.props.resource.entradasLogicas },
                                            ]}
                                        />
                                        <div className="campo-customizado hdv-top15" style={{ height: '240px' }}>
                                            {this.props?.resource ?
                                                <>
                                                    Último Reporte
                                                    <CampoCustomizado
                                                        funcao={(variavel) => this.handleChangeConfig(variavel, 'campo_referencia')}
                                                        data={this.props?.resource.ultimoReporte}
                                                        bracket={true}
                                                    />
                                                </>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <table className="hdv-relatorio-details table table-sm" style={{ width: '100%', border: 'none' }}>
                                    <thead>
                                        <tr>
                                            <th>Descrição</th>
                                            <th>Cor</th>
                                            <th>Tipo</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {referencias?.map((referencia, index) => {
                                            return referencia.grafico_id === graficoModal.grafico?.id ? (
                                                <tr key={index}>
                                                    <td>{referencia.descricao}</td>
                                                    <td>
                                                        <div style={{ width: '20px', height: '20px', backgroundColor: `${referencia.cor_linha}` }}></div>
                                                    </td>
                                                    <td>{referencia.tipo_da_referencia.value}</td>
                                                    <td>
                                                        <div style={{ display: 'flex' }}>
                                                            <button
                                                                onClick={() => {
                                                                    this.setState({ referenciaModal: { ativo: true, referencia } })
                                                                    this.reloadState()
                                                                }}
                                                                className="hdv-automacao-delete-button">
                                                                <i className="fa fa-pen fa-2x"></i>
                                                            </button>
                                                            <button
                                                                onClick={() => this.confirmDelete(referencia, 'referencia')}
                                                                className="hdv-automacao-delete-button">
                                                                <i className="fa fa-times fa-2x"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>)
                                                : null
                                        }
                                        )}
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel>
                                <table className="hdv-relatorio-details table table-sm" style={{ width: '100%', border: 'none' }}>
                                    <thead>
                                        <tr>
                                            <th>Descrição</th>
                                            <th>Tipo</th>
                                            <th>Campo</th>
                                            <th>Ícone</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resumos.map((resumo, index) => {

                                            return resumo.grafico_id === graficoModal.grafico?.id ? (
                                                <tr key={index}>
                                                    <td>{resumo.descricao}</td>
                                                    <td>{resumo?.tipo?.label}</td>
                                                    <td>{resumo?.campo_referencia}</td>
                                                    <td><i className={resumo?.icone?.value} /></td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <button
                                                                onClick={() => {
                                                                    this.setState({ resumoModal: { ativo: true, novo: false, resumo } })
                                                                    this.reloadState()
                                                                }}
                                                                className="hdv-automacao-delete-button">
                                                                <i className="fa fa-pen fa-2x"></i>
                                                            </button>
                                                            <button
                                                                onClick={() => this.confirmDelete(resumo, 'resumo')}
                                                                className="hdv-automacao-delete-button">
                                                                <i className="fa fa-times fa-2x"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>) :
                                                null
                                        })}
                                    </tbody>
                                </table>
                            </TabPanel>
                        </Tabs>
                    </ModalBody>
                    <ModalFooter>
                        {this.renderNewButton()}
                        <Button color="success" className={this.state.tabIndex === 0 ? "d-flex align-items-center" : "hdv-noshow-item"} onClick={this.saveChartConfig}>
                            <i className="fa fa-save fa-2x"></i> &nbsp; Salvar
                        </Button>
                        <Button color="secondary" className="d-flex align-items-center" onClick={() => this.setState({ graficoModal: { ativo: false, grafico: null } })}>
                            <i className="fa fa-times-circle fa-2x"></i> &nbsp; Fechar
                        </Button>
                    </ModalFooter>
                </Modal>

                <div className="px-3">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="mb-3">
                        <h5 className="m-0">Gráficos</h5>
                        <div className="hdv-chart-options">
                            <ReactLoading
                                className={this.props.carregandoDados ? "mb-4" : "hdv-noshow-item"}
                                type="cylon"
                                color="#589bd4"
                                height={20}
                                width={50}
                            />

                            <button
                                onClick={this.props.exportarPdf}
                                className="hdv-btn-forms hdv-btn-red"
                                disabled={!(this.props.relatorioGerado === true && this.props.exportCooldown === false)}>
                                Exportar
                            </button>

                            <button
                                className="hdv-btn-forms hdv-btn-geen mx-2 hdv-automacao-add-new-button m-0 d-flex align-items-center"
                                disabled={this.props.relatorioErro || this.props.cliente}
                                onClick={() => {
                                    this.resetarGrafico()
                                    this.props.getResource()
                                }}>
                                Novo Gráfico
                            </button>

                            <button
                                onClick={this.props.buscarDadosRelatorio}
                                className="hdv-btn-forms hdv-btn-blue m-0 hdv-automacao-add-new-button d-flex align-items-center"
                                disabled={this.props.carregandoDados === false && charts?.length === 0}>
                                Gerar Relatório
                            </button>
                        </div>
                    </div>

                    {charts.length > 0 ?
                        charts.map((chart, index) => (
                            <details key={chart.id} className="hdv-relatorio-chart" open={false} key={index}>
                                <summary>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>{chart.descricao}</span>

                                        {
                                            this.props.cliente
                                                ?
                                                null
                                                :
                                                <div className="row" style={{ marginRight: '6px' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <button
                                                            onClick={() => {
                                                                this.selectChart(chart)
                                                                this.props.getResource()
                                                            }}
                                                            style={{ color: '#333', width: '60px', border: '1px solid #cacaca' }}
                                                            className="hdv-chart-edit hdv-automacao-delete-button d-flex align-items-center">
                                                            <i className="fa fa-pen" style={{ fontSize: '22px' }}></i> Editar
                                                        </button>

                                                        <button
                                                            onClick={() => this.confirmDelete(chart, 'grafico')}
                                                            style={{ color: '#fff', width: '65px' }}
                                                            className="hdv-chart-close  hdv-btn-red hdv-automacao-delete-button d-flex align-items-center">
                                                            <i className="fa fa-times" style={{ fontSize: '22px' }}></i> Deletar
                                                        </button>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </summary>
                                <div style={{ display: 'flex' }}>
                                    <div id="hdv-content">
                                        <strong>Configurações</strong>
                                        <span>Descrição: {chart.descricao}</span>
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            Cor: <div style={{ width: '16px', height: '16px', backgroundColor: `${chart.cor_linha}`, margin: '0 4px', borderRadius: '20px' }}></div>
                                        </span>
                                        <span>Campo: {chart.campo_referencia}</span>
                                        <span>Tipo: {chart.tipo_grafico.label}</span>
                                    </div>
                                    {referencias?.filter(ref => ref.grafico_id === chart.id).length > 0
                                        ?
                                        <div id="hdv-content">
                                            <strong>Referências</strong>
                                            {referencias?.filter(ref => ref.grafico_id === chart.id)?.map((ref, index) => (
                                                <span style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                                    <div style={{ width: '16px', height: '16px', backgroundColor: `${ref?.cor_linha}`, margin: '0 4px', borderRadius: '20px' }}></div>
                                                    <span>{ref?.descricao}</span>
                                                </span>
                                            ))}

                                        </div>
                                        :
                                        null}
                                    {resumos?.filter(res => res.grafico_id === chart.id).length > 0
                                        ?
                                        <div id="hdv-content">
                                            <strong>Resumos</strong>
                                            {resumos?.filter(res => res.grafico_id === chart.id)?.map((res, index) => (
                                                <span style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                                    {res?.icone ? <i className={res.icone.value} style={{ fontSize: '24px' }} /> : null}
                                                    <span>{res?.descricao}</span>
                                                </span>
                                            ))}

                                        </div>
                                        :
                                        null}
                                </div>
                            </details>
                        ))
                        : <p style={{
                            paddingBottom: '35px',
                            opacity: '0.8'
                        }}>
                            Sem gráficos cadastrados
                        </p>
                    }
                </div>
            </>
        )
    }
}