import React from 'react'
import HeaderReportDefault from '../../components/HeaderReportDefault'
import axios from 'axios'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import CsvDownloader from 'react-csv-downloader'
import Select from 'react-select'
import moment from 'moment'

export default class RelatorioAnalitico extends React.Component {
    constructor() {
        super()
        this.state = {
            selectedOption: null,
            detalhesDefault: { data: "", cliente: "", monitorado: "", item: null },
            validacao_formulario: { data: true, cliente: true, monitorado: true, item: true, canal: true },
            hidrometrosOption: [],
            dadosProntos: false,
            configuracaoSelecionada: false,
            intervalos: [],
            dataCsv: [],
        }
    }

    componentDidMount() {
        this.buscarEntradas()
    }

    buscarEntradas = () => {
        if (this.state.detalhesDefault.item != null) {
            let userInfo = auth.getUserInfo()
            var bodyFormData = new FormData()

            bodyFormData.append('hidrohub__imei', this.state.detalhesDefault.item)

            axios({
                method: 'POST',
                url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/filtro/',
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then((response) => {
                    let optionsEntradasHidrometro = []
                    response.data.entradalogica.forEach((c) => {
                        optionsEntradasHidrometro.push({ "value": c.canal, "label": `#${c.canal} - ${c.descricao} (${c.tipo_entrada_logica__descricao})` })
                    })

                    this.setState({ "hidrometrosOption": optionsEntradasHidrometro, configuracaoSelecionada: response.data, runupdate: false })
                })
                .catch((error) => console.log(error))
        }
    }

    buscarDadosRelatorio = () => {
        this.setState({ create_pdf_link: false })
        const { data, item } = this.state.detalhesDefault
        let userInfo = auth.getUserInfo()
        let notValidate = false
        let validateForm = this.state.validacao_formulario
        for (var key in this.state.detalhesDefault) {
            if (this.state.detalhesDefault[key] === "" || this.state.detalhesDefault[key] === null) {
                validateForm[key] = false
                notValidate = true
            } else validateForm[key] = true
        }

        if (this.state.selectedOption === null) {
            validateForm['canal'] = false
            notValidate = true
        } else {
            validateForm['canal'] = true
        }

        if (notValidate === true) {
            this.setState({ validacao_formulario: validateForm, })
            return
        }

        let data_ini = data.split('-')[0].split('/')
        let data_fim = data.split('-')[1].split('/')
        data_ini = `${data_ini[2]}-${data_ini[1]}-${data_ini[0]} 00:00:00`
        data_fim = `${data_fim[2]}-${data_fim[1]}-${data_fim[0]} 23:59:59`
        let bodyFormData = new FormData()

        bodyFormData.append('timestamp_dispositivo_ini', data_ini)
        bodyFormData.append('timestamp_dispositivo_fim', data_fim)
        bodyFormData.append('id_dispositivo', item)
        bodyFormData.append('canal', this.state.selectedOption.value - 1)

        axios({
            method: 'POST',
            url: `${cfg.base_api_url}${cfg.api_version}/${userInfo.empresa}/reporte/intervalo-tempo/`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(res => {
                let data = false
                console.log(res.data)
                data = res.data.intervalos.map(intervalo => {

                    return {
                        c1: `${this.convertToDate(intervalo.tmsp_ini)}`,
                        c2: `${this.convertToDate(intervalo.tmsp_fim)}`,
                        c3: `${intervalo.vol_ini}`,
                        c4: `${intervalo.vol_fim}`,
                        c5: `${Number(intervalo.vaz_ini).toLocaleString("pt-BR", { maximumFractionDigits: 2 }).toString()}`,
                        c6: `${Number(intervalo.vaz_fim).toLocaleString("pt-BR", { maximumFractionDigits: 2 }).toString()}`,
                        c7: intervalo.vol_diff,
                        c8: this.convertMinutes(intervalo.tsmp_diff)
                    }
                })
                this.setState({ intervalos: res.data.intervalos, dataCsv: data, dadosProntos: true, })
            })
            .catch(err => console.log(err))
    }

    handleChangeHidrometroSelect = selectedOption => {
        let entrada_tipo_selecionada = this.state.configuracaoSelecionada.entradalogica.filter(entrada => {
            if (entrada.canal === selectedOption.value)
                return true
            return false
        })

        this.setState({ selectedOption, unidade_selecionada: entrada_tipo_selecionada[0].tipo_entrada_logica__unidade_totalizadora, entrada_tipo_selecionada: entrada_tipo_selecionada[0].tipo_entrada_logica_id, data: [] })
    }

    convertToDate = stamp => moment(stamp * 1000).format('DD/MM/YYYY H:mm:ss')

    convertMinutes(num) {
        let segundos = Number(num)
        const d = Math.floor(segundos / (3600 * 24))
        const h = Math.floor(segundos % (3600 * 24) / 3600)
        const m = Math.floor(segundos % 3600 / 60)
        const s = Math.floor(segundos % 60)

        const dDisplay = d > 0 ? `${d}d` : ""
        const hDisplay = h > 0 ? `${h}h` : ""
        const mDisplay = m > 0 ? `${m}m` : ""
        const sDisplay = m === 0 && s > 0 ? `${s}s` : ""

        return `${dDisplay} ${hDisplay} ${mDisplay} ${sDisplay}`
    }

    render() {
        const { selectedOption, intervalos, dataCsv } = this.state
        const columns = [
            { id: 'c1', displayName: 'Data Inicial' },
            { id: 'c2', displayName: 'Data Final' },
            { id: 'c3', displayName: 'Volume Inicial' },
            { id: 'c4', displayName: 'Volume Final' },
            { id: 'c5', displayName: 'Vazão Inicial' },
            { id: 'c6', displayName: 'Vazão Final' },
            { id: 'c7', displayName: 'Diferença do volume' },
            { id: 'c8', displayName: 'Diferença do período' }
        ]

        return (
            <section className="hdv-render-div">
                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                    <h3>Relatório Analítico</h3>
                    <div className="row hdv-header-filters">
                        <HeaderReportDefault DataChange={this.state.detalhesDefault} fn={this.buscarEntradas} validacao={this.state.validacao_formulario} />
                        <div className="col-md-12 hdv-bloco-top-space">
                            <label>Entrada Digital</label>
                            <Select
                                value={selectedOption}
                                onChange={this.handleChangeHidrometroSelect}
                                options={this.state.hidrometrosOption}
                            />
                            <span className={(this.state.validacao_formulario.canal === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                        </div>
                        <div className="col-md-12">
                            <button
                                style={{ backgroundColor: '#4da845', width: 'fit-content', height: '42px', margin: '16px 0', borderRadius: '4px', border: 'none', cursor: 'pointer' }}
                                className={(this.state.dadosProntos ? "" : "hdv-block-button")}
                                disabled={!this.state.dadosProntos}>
                                {this.state.dadosProntos
                                    ? <CsvDownloader
                                        style={{ border: 'none' }}
                                        columns={columns}
                                        datas={dataCsv}
                                        filename="rel_lancamento_manual.csv"
                                        separator=";">
                                        <p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '8px 10px' }}>
                                            <i className="fa fa-file fa-20px"></i>
                                            Exportar CSV
                                        </p>
                                    </CsvDownloader>
                                    :
                                    <p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '8px 10px' }}>
                                        <i className="fa fa-file fa-20px"></i>
                                        Exportar CSV
                                    </p>
                                }
                            </button>
                            <button
                                disabled={this.state.carregando_dados}
                                className={(this.state.carregando_dados === true) ? "hdv-header-filters-button hdv-generate-report hdv-block-button" : "hdv-header-filters-button hdv-generate-report"}
                                onClick={this.buscarDadosRelatorio} >Gerar Relatório</button>
                        </div>
                    </div>
                    {intervalos.length > 0 ?
                        <table className="table table-sm table-borderless hdv-table-analitico">
                            <thead style={{ marginBottom: '5px' }}>
                                <tr>
                                    <th>Data</th>
                                    <th>Volume</th>
                                    <th>Vazão</th>
                                    <th>Diferença do Volume</th>
                                    <th>Diferença do período</th>
                                </tr>
                            </thead>
                            <tbody>
                                {intervalos.map((intervalo, index) => (
                                    <tr key={index}>
                                        <td className="hdv-cell">
                                            <p>{this.convertToDate(intervalo.tmsp_ini)}</p>
                                            <p>{this.convertToDate(intervalo.tmsp_fim)}</p>
                                        </td>
                                        <td className="hdv-cell">
                                            <p>{intervalo.vol_ini.toFixed(2)}</p>
                                            <p>{intervalo.vol_fim.toFixed(2)}</p>
                                        </td>
                                        <td className="hdv-cell">
                                            <p>{Number(intervalo.vaz_ini).toLocaleString("pt-BR", { maximumFractionDigits: 2 })}</p>
                                            <p>{Number(intervalo.vaz_fim).toLocaleString("pt-BR", { maximumFractionDigits: 2 })}</p>
                                        </td>
                                        <td className="hdv-center">{intervalo.vol_diff}</td>
                                        <td className="hdv-center">{this.convertMinutes(intervalo.tsmp_diff)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> : null}
                </div>
            </section>
        )
    }
}