import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom'
import "react-tabs/style/react-tabs.css";
import Select from 'react-select';
import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import moment from 'moment';
import TimePicker from 'rc-time-picker'
import Aviso from '../../components/Aviso'


import 'rc-time-picker/assets/index.css';

class Form extends Component {

    state = {
        detalhes: {
            severidade: "",
            descricao: "",
            monitorado: "",
            equipamento: "",
            envio: { "email": false, "mobile": false },
            tolerancia: 0,
            usuarios: [],
        },
        formerrors: [],
        redirect: false,
        path: "",
        severidades: [{ label: "Informativo", value: "informativo" }, { label: "Não Urgente", value: "nao_urgente" }, { label: "Grave", value: "grave" }, { label: "Crítico", value: "critico" }],
        monitorados: [],
        equipamentos: [],
        condicoes: [{ campo: "", condicao: "", valor: "" }],
        condicoesSelected: { campo: { 0: "" }, condicao: { 0: "" } },
        optionSelected: {
            "severidade": {},
            "monitorado": {},
            "equipamento": {},
        },
        campos: [
            { label: "Horímetro Virtual", value: "horimetro_virtual", dataType: "text" },
        ],
        operadores: [
            { label: "Igual (=)", value: "igual" },
            { label: "Maior ou Igual (>=)", value: "maior_igual" },
            { label: "Menor ou Igual (<=)", value: "menor_igual" },
            { label: "Maior (>)", value: "maior" },
            { label: "Menor (<)", value: "menor" },
            { label: "Diferente (!=)", value: "diferente" },
        ],
        'equipamento_selecionado': { value: '', label: '' },
        usuarios: { 'cliente': [], 'empresa': [] },
        tolerancia: "00:00",
        aviso: { show: false, args: null, message: '' },
        cooldown: false,
    }

    componentWillMount = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado/', config_me))

        axios.all(requestArray)
            .then(axios.spread((monitorados) => {
                let monitoradoSelected = []
                monitorados.data.forEach(monitorado => {
                    monitoradoSelected.push({ value: monitorado.id, label: monitorado.nome })
                });
                this.setState({ "monitorados": monitoradoSelected })
            }));
    }

    componentDidMount = () => {
        if (this.props.location.state) {
            let equipamento_selecionado = ("equipamento_selecionado" in this.props.location.state) ? this.props.location.state.equipamento_selecionado : false
            let monitorado_selecionado = ("monitorado_selecionado" in this.props.location.state) ? this.props.location.state.monitorado_selecionado : false
            this.setState({ 'equipamento_selecionado': equipamento_selecionado })
            this.setState({ 'monitorado_selecionado': monitorado_selecionado })
            console.log(monitorado_selecionado)
            this.handleChangeSelectMonitorado(monitorado_selecionado, 'monitorado')
            if (!this.props.match.params.id)
                this.handleChangeSelectEquipamentoBuscaCondicoes(equipamento_selecionado, 'equipamento')
        }
        if (this.props.match.params.id) {
            this.getData()
        }
    }

    getData = () => {
        let resource = "alerta"
        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((alerta_registro) => {
                console.log(alerta_registro.data.monitorado)
                let requestArray = []
                requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/item/?page=0&pageSize=10&filter=' + encodeURI(JSON.stringify([{ "id": "monitorado_id", "value": alerta_registro.data.monitorado, "type": "int" }])), config_me))

                axios.all(requestArray)
                    .then(axios.spread((equipamentos) => {
                        console.log(equipamentos)

                        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/buscar-entradas-hidrohub/' + alerta_registro.data.equipamento__imei + '/', config_me)
                            .then((entradas_hidrohub) => {

                                //ADICIONA OS CAMPOS SELECIONADOS NAS CONDIÇÕES
                                let entradasCampos = [
                                    { label: "Horímetro Virtual", value: "horimetro_virtual", dataType: "text" },
                                ]
                                if (entradas_hidrohub.status === 200) {
                                    entradas_hidrohub.data.entrada4a20.forEach((value) => {
                                        entradasCampos.push({ label: "#" + value.canal + ' ' + value.descricao, value: 'entrada4a20_' + value.canal, dataType: "text" })
                                    })
                                    entradas_hidrohub.data.outros_campos.map(value =>
                                        entradasCampos.push({ label: value.label, value: value.value })
                                    )
                                    entradas_hidrohub.data.entradalogica.forEach((value) => {
                                        //entradasCampos.push({label: "#"+value.canal + ' ' +value.tipo_entrada_logica__descricao, value: 'entradalogica_'+value.canal, dataType: "text"})
                                        if (value.tipo_entrada_logica_id === 3) {
                                            entradasCampos.push({ label: "#" + value.canal + ' ' + value.tipo_entrada_logica__descricao, value: 'status_' + value.canal, dataType: "text" })
                                        } else {
                                            entradasCampos.push({ label: "#" + value.canal + ' ' + value.tipo_entrada_logica__descricao, value: 'entradalogica_' + value.canal, dataType: "text" })
                                            if (value.tipo_entrada_logica_id === 1)
                                                entradasCampos.push({ label: "#" + value.canal + ' ' + 'Vazão', value: 'ct_pulso_' + value.canal, dataType: "text" })
                                        }
                                    })
                                }
                                //////////////////////////////////////////

                                //PREENCHE COM OS EQUIPAMENTOS, BASEADO NA SELEÇÃO DO MONITORADO
                                let lista_equipamentos = []
                                if (entradas_hidrohub.status === 200) {
                                    equipamentos.data.results.forEach((value) => {
                                        lista_equipamentos.push({ label: value.equipamento__imei, value: value.equipamento__imei })
                                    })
                                }
                                ////////////////////////////////////////////

                                //ADICIONAR VALORES NO ESTADO DETALHES, RESPONSAVEL PELA MAIORIA DOS CAMPOS
                                let detalhes = {}
                                detalhes['severidade'] = alerta_registro.data.severidade
                                detalhes['envio'] = JSON.parse(alerta_registro.data.envio)
                                detalhes['descricao'] = alerta_registro.data.descricao
                                detalhes['monitorado'] = alerta_registro.data.monitorado
                                detalhes['usuarios'] = alerta_registro.data.usuarios
                                detalhes['tolerancia'] = alerta_registro.data.tolerancia
                                detalhes['equipamento'] = alerta_registro.data.equipamento__imei
                                ////////////////////////////////////////////

                                //ADICIONANDO EM CONDICAO E PERIODO
                                let condicoes = JSON.parse(alerta_registro.data.condicao)
                                ////////////////////////////////////////////

                                //GERAR ITENS DOS SELECTS
                                let objSelected = this.state.optionSelected
                                if (detalhes['severidade'] === 'informativo') {
                                    objSelected['severidade'] = { label: "Informativo", value: 'informativo' }
                                } else if (detalhes['severidade'] === 'nao_urgente') {
                                    objSelected['severidade'] = { label: "Não Urgente", value: 'nao_urgente' }
                                } else if (detalhes['severidade'] === 'grave') {
                                    objSelected['severidade'] = { label: "Grave", value: 'grave' }
                                } else if (detalhes['severidade'] === 'critico') {
                                    objSelected['severidade'] = { label: "Crítico", value: 'critico' }
                                }

                                let tolerancia_formatado = moment.utc(alerta_registro.data.tolerancia * 1000).format('HH:mm');

                                let monitorado_executa_selecionado = this.state.monitorados.filter((monitorado) => {
                                    if (monitorado.value === detalhes['monitorado']) {
                                        return true
                                    } else {
                                        return false
                                    }
                                })
                                objSelected['monitorado'] = { label: monitorado_executa_selecionado[0].label, value: monitorado_executa_selecionado[0].value }


                                let equip_selecionado = equipamentos.data.results.filter((equip) => {
                                    if (equip.equipamento__imei === detalhes['equipamento']) {
                                        return true
                                    } else {
                                        return false
                                    }
                                })
                                objSelected['equipamento'] = { label: equip_selecionado[0].equipamento__imei, value: equip_selecionado[0].equipamento__imei }


                                let condicoesSelected = { campo: {}, condicao: {} }
                                condicoes.forEach((condicao, index) => {
                                    let campo_selecionado = entradasCampos.filter((campo) => {
                                        if (campo.value === condicao.campo) {
                                            return true
                                        } else {
                                            return false
                                        }
                                    })
                                    let operador_selecionado = this.state.operadores.filter((operador) => {
                                        if (operador.value === condicao.condicao) {
                                            return true
                                        } else {
                                            return false
                                        }
                                    })

                                    condicoesSelected['campo'][index] = campo_selecionado[0]
                                    condicoesSelected['condicao'][index] = operador_selecionado[0]
                                })
                                ////////////////////////////////////////////

                                this.setState({ optionSelected: objSelected, tolerancia: tolerancia_formatado, condicoesSelected: condicoesSelected, detalhes: detalhes, condicoes: condicoes, campos: entradasCampos, equipamentos: lista_equipamentos });
                            })

                    }));
            })
    }

    handleChange = (e) => {
        let obj = this.state.detalhes

        if (e.target.type === "checkbox") {
            obj['envio'][e.target.id] = (e.target.checked === true) ? true : false
        } else {
            obj[e.target.id] = e.target.value
        }
        if (document.querySelector(`#validacao-${e.target.name}`))
            document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""
        this.setState({ "detalhes": obj })
    }

    saveAction = (novo_cadastro) => {
        let userInfo = auth.getUserInfo();
        let resource = "alerta"
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/';
            verb = 'post'
        }
        let bodyFormData = new FormData();

        for (let key in this.state.detalhes) {
            if (key === "envio") {
                bodyFormData.append(key, JSON.stringify(this.state.detalhes[key]));
            } else {
                bodyFormData.append(key, this.state.detalhes[key]);
            }
        }

        if (this.state.condicoes.length > 0) {
            bodyFormData.append('condicao', JSON.stringify(this.state.condicoes));
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (novo_cadastro === true) {
                    if (this.props.match.params.id) {
                        // this.setState({ redirect: true, path: "/alerta/novo" })
                        const aviso = {
                            show: true, message: `Aviso ${verb === 'patch' ? 'editado' : 'criado'} com sucesso.`,
                            args: { redirect: true, path: "/equipamento/" + this.state.equipamento_selecionado.value + "/status/" }
                        }
                        this.setState({ aviso })
                    } else {
                        window.location.reload()
                    }
                } else {
                    // this.setState({ redirect: true, path: '/equipamento/' + this.state.equipamento_selecionado.value + '/status/' })
                    const aviso = {
                        show: true, message: `Aviso ${verb === 'patch' ? 'editado' : 'criado'} com sucesso.`,
                        args: { redirect: true, path: "/equipamento/" + this.state.equipamento_selecionado.value + "/status/" }
                    }
                    this.setState({ aviso })
                }
            })
            .catch((error) => {
                console.log(error)
                let erros = error.response.data
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span)
                            span.innerHTML = erros[i][1]
                    }
                }
            })
    }

    handleChangeSelect = (selectedOption, field) => {
        let obj = this.state.detalhes
        let obj_selected = this.state.optionSelected
        obj[field] = selectedOption.value
        obj_selected[field] = selectedOption
        if (document.querySelector(`#validacao-${field}`))
            document.querySelector(`#validacao-${field}`).innerHTML = ""
        this.setState({ optionSelected: obj_selected, "detalhes": obj });
    }

    handleChangeSelectEquipamentoBuscaCondicoes = (selectedOption, field) => {
        let obj = this.state.detalhes
        let obj_selected = this.state.optionSelected
        obj[field] = selectedOption.value
        obj_selected[field] = selectedOption

        this.setState({ loading: true });
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        let userInfo = auth.getUserInfo();

        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/buscar-entradas-hidrohub/' + selectedOption.value + '/', config_me)
            .then((response) => {
                let entradasCampos = [
                    { label: "Horímetro Virtual", value: "horimetro_virtual", dataType: "text" },
                ]
                console.log(response.data)
                if (response.status === 200) {
                    response.data.entrada4a20.forEach((value) => {
                        entradasCampos.push({ label: "#" + value.canal + ' ' + value.descricao, value: 'entrada4a20_' + value.canal, dataType: "text" })
                    })
                    response.data.entradalogica.forEach((value) => {
                        //entradasCampos.push({label: "#"+value.canal + ' ' +value.tipo_entrada_logica__descricao, value: 'entradalogica_'+value.canal, dataType: "text"})
                        if (value.tipo_entrada_logica_id === 3) {
                            entradasCampos.push({ label: "#" + value.canal + ' ' + value.tipo_entrada_logica__descricao, value: 'status_' + value.canal, dataType: "text" })
                        } else {
                            entradasCampos.push({ label: "#" + value.canal + ' ' + value.tipo_entrada_logica__descricao, value: 'entradalogica_' + value.canal, dataType: "text" })
                            if (value.tipo_entrada_logica_id === 1)
                                entradasCampos.push({ label: "#" + value.canal + ' ' + 'Vazão', value: 'ct_pulso_' + value.canal, dataType: "text" })
                        }
                    })
                    response.data.outros_campos.map(value => {
                        console.log(value)
                        entradasCampos.push({ label: value.label, value: value.value })
                        return null
                    })
                }
                console.log(entradasCampos)
                this.setState({ optionSelected: obj_selected, "detalhes": obj, campos: entradasCampos });

            })
    }

    handleChangeSelectMonitorado = (selectedOption, field) => {
        let obj = this.state.detalhes
        let obj_selected = this.state.optionSelected
        obj[field] = selectedOption.value
        obj_selected[field] = selectedOption


        this.setState({ loading: true });
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        let userInfo = auth.getUserInfo();

        /*axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/item/?page=0&pageSize=10&filter='+encodeURI(JSON.stringify([{"id": "monitorado_id", "value": selectedOption.value, "type": "int" }])), config_me)
        .then((response)=>{
            let equipamentos_executa = []
            if(response.status === 200){
                response.data.results.forEach((value) => {
                    equipamentos_executa.push({label: value.equipamento__imei, value: value.equipamento__imei})
                })
            }
            this.setState({ optionSelected: obj_selected, "detalhes": obj, equipamentos:  equipamentos_executa});
        })*/

        let requestArray = []

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/item/?page=0&pageSize=10&filter=' + encodeURI(JSON.stringify([{ "id": "monitorado_id", "value": selectedOption.value, "type": "int" }])), config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/usuario-monitorado/' + selectedOption.value + '/', config_me))

        axios.all(requestArray)
            .then(axios.spread((equipamentos, usuarios) => {
                let equipamentos_executa = []
                if (equipamentos.status === 200) {
                    equipamentos.data.results.forEach((value) => {
                        equipamentos_executa.push({ label: value.equipamento__imei, value: value.equipamento__imei })
                    })
                }
                this.setState({ optionSelected: obj_selected, "detalhes": obj, equipamentos: equipamentos_executa, usuarios: usuarios.data })
            }));

    }

    handleChangeCondicao = (selectedOption, campo, index) => {
        let campo_tipo = selectedOption.value.split('_')
        let condicoes = this.state.condicoes
        let condicoesSelected = this.state.condicoesSelected
        let condicao = condicoes[index]

        if (campo_tipo[0] === 'status') {

            let force_operador = { "label": "Igual (=)", "value": "igual" }
            condicao[campo] = selectedOption.value
            condicoesSelected[campo][index] = selectedOption

            condicao['condicao'] = force_operador.value
            condicao['valor'] = '1'
            condicoes[index] = condicao
            condicoesSelected['condicao'][index] = force_operador

        } else {

            condicao[campo] = selectedOption.value
            condicoes[index] = condicao
            condicoesSelected[campo][index] = selectedOption
        }
        console.log(condicoes, condicoesSelected)
        this.setState({ condicoes: condicoes, condicoesSelected: condicoesSelected })
    }

    handleChangeCondicaoValue = (e, index) => {
        let condicoes = this.state.condicoes
        let condicao = condicoes[index]
        condicao[e.target.id] = e.target.value
        condicoes[index] = condicao
        this.setState({ condicoes: condicoes })
    }

    formatar = (e, index) => {
        let v = e.target.value.replace(/\D/g, '')
        v = (v / 100).toFixed(2) + ''
        v = v.replace(" ", ",")
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2,$3,")
        v = v.replace(/(\d)(\d{3}),/g, "$1.$2,")
        let condicoes = this.state.condicoes
        let condicao = condicoes[index]
        condicao[e.target.id] = v
        condicoes[index] = condicao
        this.setState({ condicoes: condicoes })
    }


    handleChangeTempoExecucao = (e, campo) => {

        let detalhes = this.state.detalhes

        if (e === null) return

        let tempo = e.format('HH:mm')
        let tempo_array = tempo.split(':')

        let total_segundos = (parseInt(tempo_array[0]) * 60) * 60
        total_segundos = total_segundos + (parseInt(tempo_array[1]) * 60)

        detalhes['tolerancia'] = total_segundos

        this.setState({ tolerancia: tempo, detalhes: detalhes })

    }

    handleChangeUsuario = (e) => {

        let detalhes = this.state.detalhes

        let id = parseInt(e.target.id.split('_')[1])

        let usuario_selecionado = detalhes['usuarios'].find((usuario) => {
            return usuario === id
        })

        if (usuario_selecionado) {
            detalhes['usuarios'] = detalhes['usuarios'].filter((usuario) => {
                return usuario !== id
            })
        } else {
            detalhes['usuarios'].push(id)
        }

        this.setState({ detalhes: detalhes })

    }

    createNewCondition = () => {
        let condicoes = this.state.condicoes
        condicoes.push({ campo: "", condicao: "", valor: "" })
        this.setState({ condicoes: condicoes })
    }

    removeCondition = (index) => {
        let condicoes = this.state.condicoes
        condicoes.splice(index, 1)
        this.setState({ condicoes: condicoes })
    }

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    render() {
        console.log(this.state)

        const { severidade,
            monitorado,
            equipamento } = this.state.optionSelected;

        if (this.state.redirect === true) {
            return <Redirect to={{
                pathname: this.state.path,
                state: {
                    tabIndex: 2
                }
            }} />
        }

        return (
            <div>
                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.setState(this.state.aviso.args)}
                    open={this.state.aviso.show}
                    continue={Boolean(this.props.match.params.id) ? () => {
                        let { aviso } = this.state
                        aviso = { show: false, args: null, message: '' }
                        this.setState({ aviso })
                    }
                        : null}
                />
                {this.props.match.params.id ? <h4>Editar Regra de Alerta</h4> : <h4>Nova Regra de Alerta</h4>}

                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Detalhes</Tab>
                        <Tab>Condições</Tab>
                        <Tab>Usuários</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="descricao">Descrição: </label>
                                    <input type="text" name="descricao" id="descricao" value={this.state.detalhes.descricao} onChange={this.handleChange} />
                                    <span id="validacao-descricao"></span>
                                </div>
                                <div className="col-md-8"></div>
                                <div className="col-md-4">
                                    <label htmlFor="nome">Severidade: </label>
                                    <Select
                                        value={severidade}
                                        onChange={(e) => this.handleChangeSelect(e, 'severidade')}
                                        options={this.state.severidades}
                                    />
                                    <span id="validacao-severidade"></span>
                                </div>
                                <div className="col-md-8"></div>
                                <div className="col-md-5">
                                    <label htmlFor="horainicio">Tolerância antes de Gerar o Alerta</label>
                                    <TimePicker value={moment(this.state.tolerancia, "HH:mm:ss")} showSecond={false} onChange={(e) => this.handleChangeTempoExecucao(e, 'tolerancia_para_gerar_alerta')} />
                                </div>
                                <div className="col-md-7"></div>
                                <div className="col-md-5">
                                    <label htmlFor="nome">Monitorado: </label>
                                    <Select
                                        value={monitorado}
                                        onChange={(e) => this.handleChangeSelectMonitorado(e, 'monitorado')}
                                        options={this.state.monitorados}
                                    />
                                </div>
                                <div className="col-md-5">
                                    <label htmlFor="nome">Equipamento: </label>
                                    <Select
                                        value={equipamento}
                                        onChange={(e) => this.handleChangeSelectEquipamentoBuscaCondicoes(e, 'equipamento')}
                                        options={this.state.equipamentos}
                                    />
                                </div>
                                <div className="col-md-5"></div>
                                <div className="col-md-8">
                                    <label htmlFor="nome">Enviar via: </label>
                                    <input type="checkbox" id="email" name="email" checked={this.state.detalhes.envio.email} onChange={this.handleChange} /> E-mail <br />
                                    <input type="checkbox" id="mobile" name="mobile" checked={this.state.detalhes.envio.mobile} onChange={this.handleChange} /> Mobile
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="hdv-form-default">
                            {
                                this.state.condicoes.map((condicao, index) => {
                                    return (
                                        <div key={index} className="row">
                                            <div className="col-md-4">
                                                <label htmlFor="campo">Quando o Campo: </label>
                                                <Select
                                                    value={this.state.condicoesSelected['campo'][index]}
                                                    onChange={(e) => this.handleChangeCondicao(e, 'campo', index)}
                                                    options={this.state.campos}
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <label htmlFor="condicao">For: </label>
                                                {console.log(this.state.condicoesSelected)}
                                                <Select
                                                    value={this.state.condicoesSelected['condicao'][index]}
                                                    onChange={(e) => this.handleChangeCondicao(e, 'condicao', index)}
                                                    options={this.state.operadores}
                                                    isDisabled={
                                                        (this.state.condicoesSelected['campo'][index]) ?
                                                            (this.state.condicoesSelected['campo'][index].value.split('_')[0] === 'status') ? true : false
                                                            :
                                                            false
                                                    }
                                                />
                                            </div>
                                            {
                                                (this.state.condicoesSelected['campo'][index]) ?
                                                    (this.state.condicoesSelected['campo'][index].value.split('_')[0] === 'status') ?
                                                        <div className="col-md-2">
                                                            <label htmlFor="condicao">Status: </label>
                                                            <div>
                                                                <input type="radio" id="valor" name="valor" checked={(this.state.condicoes[index].valor !== "0" ? true : false)} onChange={(e) => this.handleChangeCondicaoValue(e, index)} value="1" />
                                                                <span>Ligado</span>
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="valor" name="valor" checked={(this.state.condicoes[index].valor === "0" ? true : false)} onChange={(e) => this.handleChangeCondicaoValue(e, index)} value="0" />
                                                                <span>Desligado</span>
                                                            </div>
                                                        </div>
                                                        : <div className="col-md-2">
                                                            <label htmlFor="valor">Valor: </label>
                                                            <input type="text" name="valor" id="valor" value={this.state.condicoes[index].valor} onChange={(e) => this.formatar(e, index)} />
                                                        </div>
                                                    : <div className="col-md-2">
                                                        <label htmlFor="valor">Valor: </label>
                                                        <input
                                                            type="text"
                                                            name="valor"
                                                            id="valor"
                                                            value={this.state.condicoes[index].valor}
                                                            onChange={(e) => this.formatar(e, index)}
                                                        />
                                                    </div>
                                            }
                                            {/*<div className={(index === 0) ? "hdv-noshow-item" : "col-md-2" }>*/}
                                            <div className={"col-md-2"}>
                                                <label >&nbsp;</label>
                                                <button onClick={() => this.removeCondition(index)} className="hdv-btn-forms hdv-btn-red hdv-automacao-delete-button"><i className="fa fa-minus-circle fa-2x"></i></button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="row">
                                <div className="col-md-5">
                                    <button
                                        onClick={this.createNewCondition}
                                        className="hdv-btn-forms hdv-btn-blue hdv-automacao-add-new-button d-flex align-items-center">
                                        <i className="fa fa-plus-circle fa-2x"></i> &nbsp; Nova condição
                                    </button>
                                </div>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="">Selecione os Usuários que receberão as Notificações: </label>
                                    <h4>Usuários do Cliente</h4>
                                    {this.state.usuarios.cliente.map((usuario, idx) => {
                                        return (
                                            <p key={idx}>
                                                <input style={{ marginRight: "10px" }} type="checkbox" id={"usuario_" + usuario.id} name={"usuario_" + usuario.id} checked={this.state.detalhes.usuarios.find((usuario_slc) => (usuario_slc === usuario.id) ? true : false)} onChange={this.handleChangeUsuario} />
                                                {usuario.nome + " (" + usuario.nome_usuario + ")"}
                                            </p>
                                        )
                                    })}

                                    <h4>Usuários da Empresa</h4>
                                    {this.state.usuarios.empresa.map((usuario, idx) => {
                                        return (
                                            <p key={idx}>
                                                <input style={{ marginRight: "10px" }} type="checkbox" id={"usuario_" + usuario.id} name={"usuario_" + usuario.id} checked={this.state.detalhes.usuarios.find((usuario_slc) => (usuario_slc === usuario.id) ? true : false)} onChange={this.handleChangeUsuario} />
                                                {usuario.nome + " (" + usuario.nome_usuario + ")"}
                                            </p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-geen">Salvar</button>
                    <button onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-geenlight ">Salvar e Cadastrar um Novo</button>
                    <Link to={{
                        pathname: '/equipamento/' + this.state.equipamento_selecionado.value + '/status/',
                        state: {
                            tabIndex: 2
                        }
                    }}>
                        <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>

            </div>
        );
    }
}

export default Form;