import React, { Component } from 'react'
import HeaderReport from '../../components/HeaderReport'
import axios from 'axios'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import ReactEcharts from "echarts-for-react"
import RelatorioCustomizado from './relatorio'
import Aviso from '../../components/Aviso'
import { Link, Redirect } from 'react-router-dom'
import domToImage from 'dom-to-image'
import pdfMake from 'pdfmake'

pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    }
}
class Form extends Component {
    userInfo = auth.getUserInfo()
    config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
    baseUrl = `${cfg.base_api_url + cfg.api_version}`

    state = {
        selectedOption: { value: null, label: null },
        detalhesDefault: { "data": "", "cliente": "", "monitorado": "", "item": null, "agrupar": "", manter_equipamento: true, disponivel_cliente_final: false, descricao: "" },
        configuracaoSelecionada: null,
        validacao_formulario: { data: true, cliente: true, monitorado: true, item: true, descricao: true, agrupar: true },
        carregandoDados: false,
        relatorioGerado: false,
        charts: [],
        referencias: [],
        resumos: [],
        reporte: { data: '', calc: '', referencia: [], resumo: [] },
        redirect: false,
        aviso: { show: false, args: null, message: '' },
        resource: {
            automacaoOptions: [], alertaOptions: [], ultimoReporte: null, entradas4a20: [], entradasLogicas: [],
            tiposResumo: [
                { label: 'Mínimo, Médio e Máximo', value: 'min_med_max' },
                { label: 'Volume Total', value: 'volume_total' },
                { label: 'Custo por unidade', value: 'custo' },
            ],
            resumoIconOptions: [
                { label: 'Ruler', value: 'fa fa-ruler fa-2x' },
                { label: 'Bolt', value: 'fa fa-bolt fa-2x' },
                { label: 'Clock', value: 'fa fa-clock fa-2x' },
                { label: 'Tint', value: 'fa fa-tint fa-2x' },
                { label: 'Coins', value: 'fa fa-coins fa-2x' },
                { label: 'Exclamation', value: 'fa fa-exclamation fa-2x' },
                { label: 'Balance', value: 'fa fa-balance-scale fa-2x' },
                { label: 'Chart Bar', value: 'fa fa-chart-bar fa-2x' },
                { label: 'Chart Line', value: 'fa fa-chart-line fa-2x' },
                { label: 'Percent', value: 'fa fa-percent fa-2x' },
            ],
            referenciaOptions: [
                { label: 'Valor Fixo', value: 'fixa' },
                { label: 'Valor Dinâmico', value: 'dinamico' },
                { label: 'Alerta', value: 'alerta' },
                { label: 'Automação', value: 'automacao' },
            ],
        },
        cooldown: false,
        exportCooldown: false,
        relatorioErro: false,
        cliente: false
    }

    buscarCanais = () => {
        if (this.state.detalhesDefault.item != null) {
            let bodyFormData = new FormData()

            if (typeof this.state.detalhesDefault.item === 'object') {
                bodyFormData.append('hidrohub__imei', this.state.detalhesDefault.item.label)
            } else {
                bodyFormData.append('hidrohub__imei', this.state.detalhesDefault.item)
            }

            axios({
                method: 'POST',
                url: this.baseUrl + `/${this.userInfo.empresa}/parametrizacao/filtro/`,
                data: bodyFormData,
                ...this.config_me
            })
                .then((res) => {
                    let entradas4a20 = []
                    let entradasLogicas = []

                    res.data.entrada4a20.forEach((c) => entradas4a20.push({ value: `entradas_4a20_${c.canal}`, label: `#${c.canal} - ${c.descricao}` }))
                    res.data.entradalogica.forEach((c) => entradasLogicas.push({ value: `pulso_${c.canal}`, label: `#${c.canal} - ${c.descricao}` }))
                    let resource = this.state.resource
                    resource['entradas4a20'] = entradas4a20
                    resource['entradasLogicas'] = entradasLogicas

                    this.setState({ resource, configuracaoSelecionada: res.data, runupdate: false })
                })
        }
    }

    componentDidMount() {
        this.setState({ cliente: auth.getUserInfo().tipo === "cliente" })
        if (this.props.match.params.id)
            this.getData()
    }

    getReferencias = () => {
        axios.get(this.baseUrl + `/${this.userInfo.empresa}/relatorio/${this.props.match.params.id}/referencia`, this.config_me)
            .then(res => {
                let referencias = []
                const { resource } = this.state
                if (res.data) {
                    referencias = res.data
                    referencias.map(ref => {
                        ref['tipo_da_referencia'] = resource.referenciaOptions.find(r => r.value === ref['tipo_da_referencia'])
                        ref['alerta'] = resource.alertaOptions.find(a => a.value === ref['alerta_id'])
                        ref['automacao'] = resource.automacaoOptions.find(a => a.value === ref['automacao_id'])
                    })
                }

                this.setState({ referencias: referencias })
            })
    }

    getResumos = () => {
        axios.get(this.baseUrl + `/${this.userInfo.empresa}/relatorio/${this.props.match.params.id}/resumo`, this.config_me)
            .then(res => {
                const { resource } = this.state
                let resumos = []

                if (res.data) {
                    resumos = res.data
                    resumos.map(resumo => {
                        resumo['icone'] = resource.resumoIconOptions.find(icon => icon.value === resumo['icone'])
                        if (resumo?.['resumo_pre_definido']) {
                            resumo['tipo'] = resource.tiposResumo.find(tipo => tipo.value === resumo['resumo_pre_definido'])
                        }
                    })
                }

                this.setState({ resumos: resumos })
            })
    }

    getData = (updateDefault = true) => {
        this.setState({ relatorioGerado: false })
        axios({
            method: 'GET',
            url: this.baseUrl + `/${this.userInfo.empresa}/relatorio/${this.props.match.params.id}`,
            ...this.config_me
        })
            .then(res => {
                const data = res.data
                if (updateDefault) {
                    let { detalhesDefault } = this.state
                    if (data.equipamento_id) {
                        detalhesDefault.cliente = { label: `${data.cliente__nome_fantasia} - ${data.cliente__cpf_cnpj}`, value: data.cliente_id }
                        detalhesDefault.monitorado = { label: data.monitorado__nome, value: data.monitorado_id }
                        detalhesDefault.item = { label: data.equipamento__imei, value: data.equipamento_id }
                    }
                    detalhesDefault.descricao = data.descricao
                    detalhesDefault.disponivel_cliente_final = data.disponivel_cliente_final
                    detalhesDefault.manter_equipamento = Boolean(data.cliente_id)

                    this.setState({ detalhesDefault, relatorioGerado: false })
                }

                this.getReferencias()
                this.getResumos()
                this.buscarCanais()
            })
            .catch(error => {
                console.log(error)
                this.setState({ relatorioErro: true })
            })

        axios.get(this.baseUrl + `/${this.userInfo.empresa}/relatorio/${this.props.match.params.id}/grafico`, this.config_me)
            .then(res => {
                let charts = res.data || []
                const tipos = {
                    linha: { label: 'Linha', value: 'linha' },
                    barra: { label: 'Barra', value: 'barra' }
                }
                const visualizacoes = [
                    { label: 'Média do período', value: 'med' },
                    { label: 'Todo o período', value: 'tudo' },
                    { label: 'Mínima e Máxima', value: 'min_max' },
                ]
                const calcular_como = [
                    { label: 'Padrão', value: 'padrao' },
                    { label: 'Volume', value: 'volume' },
                    { label: 'Horímetro', value: 'horimetro' },
                ]

                charts?.map(chart => {
                    chart['tipo_grafico'] = chart['tipo_grafico'] === 'barra' ? tipos.barra : tipos.linha
                    chart['visualizacao'] = visualizacoes.find(visu => visu.value === chart['visualizacao'])
                    chart['calcular_como'] = calcular_como.find(calc => calc.value === chart['calcular_como'])
                })

                // charts = charts.filter(chart => chart.id === 809)
                this.setState({ charts })
            })
    }

    saveAction = () => {
        let { detalhesDefault } = this.state
        let not_validate = false
        let validate_form = this.state.validacao_formulario

        if (!detalhesDefault.manter_equipamento) {
            validate_form['cliente'] = null
            validate_form['monitorado'] = null
            validate_form['item'] = null
            validate_form['data'] = null
            validate_form['agrupar'] = null
        }

        let except
        if (detalhesDefault.manter_equipamento) {
            except = ['data', 'agrupar', 'disponivel_cliente_final']
        } else {
            except = ['data', 'agrupar', 'cliente', 'monitorado', 'item', 'disponivel_cliente_final']
        }

        for (let key in validate_form) {
            if (!detalhesDefault[key] && !except.includes(key)) {
                validate_form[key] = false
                not_validate = true
            } else
                validate_form[key] = true
        }

        if (not_validate) {
            this.setState({ validacao_formulario: validate_form, carregandoDados: false })
            window.scrollTo(0, 50)
            return
        }

        this.setState({ cooldown: true })

        let verb = 'POST'
        let url = this.baseUrl + `/${this.userInfo.empresa}/relatorio/`
        if (this.props.match.params.id) {
            verb = 'PATCH'
            url = this.baseUrl + `/${this.userInfo.empresa}/relatorio/${this.props.match.params.id}/`
        }

        const bodyFormData = new FormData()

        bodyFormData.append("descricao", detalhesDefault.descricao)
        bodyFormData.append("disponivel_cliente_final", detalhesDefault.disponivel_cliente_final ? '1' : '0')
        if (detalhesDefault.manter_equipamento) {
            bodyFormData.append('cliente_id', typeof detalhesDefault.cliente === 'object' ? detalhesDefault.cliente.value : detalhesDefault.cliente)
            bodyFormData.append('monitorado_id', typeof detalhesDefault.monitorado === 'object' ? detalhesDefault.monitorado.value : detalhesDefault.monitorado)
            bodyFormData.append('equipamento_id', typeof detalhesDefault.item === 'object' ? detalhesDefault.item.value : detalhesDefault.item)
        } else {
            bodyFormData.append('cliente_id', "")
            bodyFormData.append('monitorado_id', "")
            bodyFormData.append('equipamento_id', "")
        }

        axios({ method: verb, url: url, data: bodyFormData, ...this.config_me })
            .then(res => {
                const aviso = { show: true, message: `Relatorio salvo com sucesso`, args: { redirect: true, path: "/relatorio-customizado" } }
                this.setState({ aviso })
            })
        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    getResource = () => {
        let { item } = this.state.detalhesDefault
        if (item) {
            let userInfo = auth.getUserInfo()
            let requestArray = []

            const bodyFormData = new FormData()
            bodyFormData.append('id_dispositivo', item.hasOwnProperty('label') ? item.label : item)

            const query = JSON.stringify({ id: "equipamento_executa__imei", value: item.label })
            requestArray.push(axios.post(this.baseUrl + `/${this.userInfo.empresa}/reporte-ultimo-evento-calculado/`, bodyFormData, this.config_me))
            requestArray.push(axios.get(this.baseUrl + `/${userInfo.empresa}/automacao/?${query}`, this.config_me))
            requestArray.push(axios.get(this.baseUrl + `/${userInfo.empresa}/alerta/?${query}`, this.config_me))

            axios.all(requestArray)
                .then(axios.spread((ultimoReporte, automacao, alerta) => {

                    let automacaoOptions = []
                    let alertaOptions = []
                    let resource = this.state.resource

                    if (automacao.data) {
                        automacao?.data?.map(resource => {
                            if (resource.equipamento_recebe_id === item.value || resource.equipamento_executa_id === item.value) {
                                automacaoOptions.push({
                                    label: resource.descricao,
                                    value: resource.id,
                                    condicao: JSON.parse(resource.condicao)
                                })
                            }
                        })
                    }

                    if (alerta.data) {
                        alerta?.data?.map(resource => {
                            if (resource.equipamento === item.value) {
                                alertaOptions.push({
                                    label: resource.descricao,
                                    value: resource.id,
                                    condicao: JSON.parse(resource.condicao)
                                })
                            }
                        })
                    }

                    resource['ultimoReporte'] = ultimoReporte.data
                    resource['automacaoOptions'] = automacaoOptions
                    resource['alertaOptions'] = alertaOptions
                    this.setState({ resource: resource })
                }))
        }
    }

    formatField = (value) => {
        let identificador = String(value).toLowerCase().replace(/\s/g, '_')
        identificador = identificador.replace(/[^\w\s]/gi, '')
        if (/^[0-9].+$/.test(identificador))
            identificador = identificador.substring(1)

        const split = identificador.split('_')
        let posicao = null
        if (!isNaN(split[split.length - 1])) {
            posicao = split[split.length - 1]
            identificador = identificador.slice(0, identificador.length - 2)
        }

        return { identificador, posicao }
    }

    getDates = (startDate, endDate, group) => {
        let [dates, currentDate] = [[], startDate]

        if (group === 'day') {
            const addDays = function (days) {
                let date = new Date(this.valueOf())
                date.setDate(date.getDate() + days)
                return date
            }
            while (currentDate <= endDate) {
                dates.push(`${currentDate.getDate()}/${currentDate.getMonth() + 1}`)
                currentDate = addDays.call(currentDate, 1)
            }
        } else if (group === 'month') {
            const addMonths = function (months) {
                let date = new Date(this.valueOf())
                date.setMonth(date.getMonth() + months)
                return date
            }

            while (currentDate <= endDate) {
                dates.push(`${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`)
                currentDate = addMonths.call(currentDate, 1)
            }
        }
        return dates
    }

    generateChartSeries = (chart, res, field, xAxis, group, sinal) => {
        let data = []
        let keys = xAxis
        const campoReferencia = chart.campo_referencia.replace('[', '').replace(']', '')
        const split = campoReferencia.split('_')
        const canal = Number(split[split.length - 1])

        function useData() {
            xAxis = []

            keys.map((key, index) => {
                if (res.data[key]?.length > 0) {
                    res.data[key]?.map(reporte => {

                        if (Array.isArray(reporte[field]) && reporte[field][canal - 1]) {
                            const result = Number(reporte[field][canal - 1])
                            data.push(isNaN(result) ? 0 : result)
                        }
                        else {
                            const result = Number(reporte[field])
                            data.push(isNaN(result) ? 0 : result)
                        }

                        if (group === 'hour')
                            xAxis.push(new Date(reporte.timestamp_dispositivo * 1000).toLocaleTimeString('pt-BR').slice(0, 5))
                        else
                            xAxis.push(new Date(reporte.timestamp_dispositivo * 1000).toLocaleDateString('pt-BR', { hour: 'numeric', minute: 'numeric' }))
                    })
                } else {
                    data.push(0)
                }
            })
        }

        function mediaPadrao() {
            if (chart.calcular_como?.value === 'volume') {
                keys.map((key, index) => {
                    const item = Object.values(res.calc[index])?.[0]?.volume?.[field]
                    const result = Number(item)

                    data.push(isNaN(result) ? 0 : result)
                })
            } else {
                keys.map(key => {

                    let total = 0
                    res.data[key]?.map(reporte => {
                        if (Array.isArray(reporte[field]) && reporte[field][canal - 1])
                            total += Number(reporte[field][canal - 1] ?? 0)
                        else
                            total += Number(reporte[field] ?? 0)
                    })
                    const result = Number(total / res.data[key]?.length)?.toFixed(2)
                    data.push(isNaN(result) ? 0 : result)
                })
            }
        }

        if (chart.calcular_como.value === 'horimetro') {
            keys.map(key => {
                try {
                    const obKey = Object.keys(res.calc[key])[0]
                    const item = res.calc[key][obKey]?.volume?.[field]

                    if (Array.isArray(item) && item?.length > 1) {
                        data.push(Number(item[canal - 1])?.toFixed(2))
                    } else {
                        data.push(Number(item)?.toFixed(2))
                    }
                } catch (err) {
                    const first = res.data[key]?.[0]
                    const last = res.data[key]?.[res.data[key]?.length - 1]

                    if (first?.[field]?.length >= 1) {
                        const result = last?.[field][canal - 1] - first?.[field][canal - 1]
                        data.push(isNaN(result) ? 0 : result?.toFixed(2))
                    } else {
                        const result = last?.[field] - first?.[field]
                        data.push(isNaN(result) ? 0 : result?.toFixed(2))
                    }
                }
            })
        }
        else if (chart?.visualizacao.value === 'tudo') useData()
        else if (chart?.visualizacao.value === 'med') {
            if (field === 'ct_pulso') {
                res.calc.map((c, index) => {
                    const item = Object.values(res.calc[index])?.[0]
                    if (item?.vazao?.length === 1)
                        data.push(Number(item.vazao[0]) === 999 ? 0 : item.vazao[0]?.toFixed(2))
                    else
                        data.push(Number(item?.vazao?.[canal - 1]) === 999 ? 0 : item?.vazao?.[canal - 1]?.toFixed(2))
                })
            } else if (field === 'pulso') {
                if (chart.calcular_como?.value === 'volume') {
                    res.calc.map((c, index) => {
                        const item = Object.values(res.calc[index])?.[0]
                        if (item?.volume?.pulso?.length === 1)
                            data.push(Number(item?.volume?.pulso?.[0])?.toFixed(2))
                        else
                            data.push(Number(item?.volume?.pulso?.[canal - 1])?.toFixed(2))
                    })
                } else mediaPadrao()
            } else mediaPadrao()
        }
        else if (chart?.visualizacao.value === 'min_max') {
            keys.map(key => {
                let periodo = []
                res.data[key]?.map(reporte => {
                    if (Array.isArray(reporte[field])) {
                        if (reporte[field][canal - 1] !== 0)
                            periodo.push(reporte[field][canal - 1])
                    } else {
                        if (reporte[field] !== 0)
                            periodo.push(reporte[field])
                    }
                })
                let maior, menor

                if (String(sinal) === "9") {
                    maior = periodo.length > 0 ? Math.min(...periodo) : undefined
                    menor = periodo.length > 0 ? Math.max(...periodo) : undefined
                } else {
                    maior = periodo.length > 0 ? Math.max(...periodo) : undefined
                    menor = periodo.length > 0 ? Math.min(...periodo) : undefined
                }

                data.push({ menor, maior })
            })
        }

        return [data, xAxis]
    }

    gerarDadosDinamicos = (chart, ref, xAxis, dados) => {
        const field = this.formatField(`[${ref.campo_referencia}]`)
        let data = []
        let keys = Object.keys(dados)

        if (chart?.visualizacao.value === 'tudo') {
            keys.map(key => {
                dados[key]?.map(reporte => {
                    if (Array.isArray(reporte[field.identificador]) && reporte[field.identificador].length > 1)
                        data.push(Number(reporte[field.identificador][field.posicao - 1] ?? 0))
                    else
                        data.push(Number(reporte[field.identificador] ?? 0))
                })
            })
        } else {
            keys.map(key => {
                let total = 0

                dados[key]?.map(reporte => {
                    if (Array.isArray(reporte[field.identificador]) && reporte[field.identificador].length > 1)
                        total += Number(reporte[field.identificador][field.posicao - 1] ?? 0)
                    else
                        total += Number(reporte[field.identificador] ?? 0)
                })
                const result = Number(total / dados[key]?.length)?.toFixed(2)
                data.push(isNaN(result) ? 0 : result)
            })
        }
        return data
    }

    buscarDadosRelatorio = () => {
        this.setState({ carregandoDados: true, relatorioGerado: false })
        let { charts, detalhesDefault } = this.state
        let not_validate = false
        let valiate_form = this.state.validacao_formulario
        const except = ['disponivel_cliente_final', 'descricao', 'manter_equipamento']
        for (let key in detalhesDefault) {
            if (!detalhesDefault[key] && !except.includes(key)) {
                valiate_form[key] = false
                not_validate = true
            } else {
                valiate_form[key] = true
            }
        }

        if (not_validate) {
            this.setState({ validacao_formulario: valiate_form, carregandoDados: false })
            window.scrollTo(0, 50)
            return
        }

        let data_ini = detalhesDefault.data.split('-')[0].split('/').reverse().join('-') + ' 00:00:00'
        let data_fim = detalhesDefault.data.split('-')[1].split('/').reverse().join('-') + ' 23:59:59'

        const bodyFormData = new FormData()
        bodyFormData.append('id_dispositivo', detalhesDefault.item.label ?? detalhesDefault.item)
        bodyFormData.append('relatorio_id', this.props.match.params.id)
        bodyFormData.append('timestamp_dispositivo_ini', data_ini)
        bodyFormData.append('timestamp_dispositivo_fim', data_fim)
        bodyFormData.append('group', detalhesDefault.agrupar)

        axios.post(this.baseUrl + `/${this.userInfo.empresa}/reporte/relatorio/`, bodyFormData, this.config_me)
            .then(res => {
                console.log(res.data)
                const reportData = res.data.data ?? []
                const tiposGrafico = { 'linha': 'line', 'barra': 'bar' }
                const group = detalhesDefault.agrupar
                let xAxis = []

                if (group === 'day' || group === 'month') {
                    xAxis = this.getDates(new Date(data_ini), new Date(data_fim), group)
                } else {
                    Object.keys(reportData).map((key, index) => xAxis.push(key))
                }

                charts.forEach(chart => {
                    const chartRes = res.data?.graficos?.filter(grafico => grafico.id === chart.id)?.[0]
                    const field = this.formatField(chart.campo_referencia).identificador
                    const [chartData, chartXAxis] = this.generateChartSeries(chart, res.data, field, xAxis, group, chartRes?.sinal)
                    chart['xAxis'] = chartXAxis
                    chart['unidade'] = chartRes?.[0]?.unidade
                    chart['sinal'] = chartRes?.sinal
                    chart['series'] = []
                    chart['resumos'] = res.data.resumos?.filter(res => String(res.grafico_id) === String(chart.id)) ?? []
                    chart['referencias'] = res.data.referencias?.filter(ref => String(ref.grafico_id) === String(chart.id)) ?? []

                    if (typeof chartData?.[0] === "object") {
                        let maior = [], menor = []
                        chartData?.map(data => {
                            maior.push(Number(data['maior']))
                            menor.push(Number(data['menor']))
                        })

                        chart['series'].push({
                            descricao: chart.descricao + ' (Mínimo)',
                            cor: "#03a5fc",
                            tipo_grafico: tiposGrafico[chart.tipo_grafico.value],
                            referencia: false,
                            data: menor
                        }, {
                            descricao: chart.descricao + ' (Máximo)',
                            cor: "#34bf57",
                            tipo_grafico: tiposGrafico[chart.tipo_grafico.value],
                            referencia: false,
                            data: maior
                        })
                    } else {
                        chart['series'].push({
                            descricao: chart.descricao,
                            cor: chart.cor_linha,
                            tipo_grafico: tiposGrafico[chart.tipo_grafico.value],
                            referencia: false,
                            unidade: chartRes?.unidade,
                            data: chartData
                        })
                    }

                    const gerarDadosDinamicos = this.gerarDadosDinamicos
                    chart['referencias'].map(ref => {
                        chart.series.push({
                            descricao: ref.descricao,
                            cor: ref.cor_linha,
                            tipo_grafico: 'line',
                            unidade: ref.unidade,
                            referencia: true,
                            data: function () {
                                let data = []
                                if (ref.tipo_da_referencia === 'fixa') {
                                    data = new Array(chart.xAxis.length)
                                    data.fill(ref?.data?.[0]?.valor, 0, chart.xAxis.length)
                                } else if (ref.tipo_da_referencia === 'alerta' || ref.tipo_da_referencia === 'automacao') {
                                    let valor
                                    try {
                                        valor = JSON.parse(ref.data?.[0].condicao)[0]?.valor
                                    } catch (err) {
                                        valor = undefined
                                    }

                                    data = new Array(chart.xAxis.length)
                                    data.fill(valor, 0, chart.xAxis.length)
                                } else if (ref.tipo_da_referencia === "dinamico") {
                                    data = gerarDadosDinamicos(chart, ref, chart.xAxis, res.data.data)
                                }
                                return data
                            }(),
                        })
                    })

                    if (chart?.calcular_como.value === 'horimetro') {
                        if (chart?.series.length)
                            chart.series[0] = { ...chart.series[0], horimetro: true }
                    }
                })

                this.setState({ charts, reporte: res.data, carregandoDados: false, relatorioGerado: true })
            })
            .catch(err => {
                console.log(err)
                this.setState({ carregandoDados: false, relatorioGerado: false })
            })
    }

    gerarResumos = (resumos) => {
        let elements = []

        resumos.map((resumo, index) => {
            const posicao = this.formatField(resumo.campo_referencia).posicao
            const canal = posicao ? posicao - 1 : 0

            let minMedMax
            if (resumo.resumo_pre_definido === 'min_med_max' && Object.keys(canal)) {
                minMedMax = (
                    <div>
                        <small><b>Max:</b> {Number(resumo?.data?.[canal]?.max).toFixed(2)}</small>
                        <small><b>Med:</b> {Number(resumo?.data?.[canal]?.med).toFixed(2)}</small>
                        <small><b>Min:</b> {Number(resumo?.data?.[canal]?.min).toFixed(2)}</small>
                    </div>
                )
            } else {
                minMedMax = (
                    <div>
                        <small><b>Max:</b> {Number(resumo?.data?.max).toFixed(2)}</small>
                        <small><b>Med:</b> {Number(resumo?.data?.med).toFixed(2)}</small>
                        <small><b>Min:</b> {Number(resumo?.data?.min).toFixed(2)}</small>
                    </div>
                )
            }

            elements.push(
                <div className="hdv-resumo" key={index}>
                    <i className={resumo?.icone} />
                    <div id="content">
                        {resumo.descricao}
                        {resumo.resumo_pre_definido === 'min_med_max'
                            ?
                            minMedMax
                            : resumo.resumo_pre_definido === 'volume_total'
                                ?
                                <strong>{resumo.data?.[canal]}</strong>
                                : resumo.resumo_pre_definido === 'custo'
                                    ?
                                    <>
                                        <strong>{resumo.data?.[canal]}</strong> R$
                                    </>
                                    :
                                    null
                        }
                        <small>{resumo.campo_referencia}</small>
                    </div>
                </div>
            )
        })

        return elements
    }

    formatTooltip = (params) => {
        if (Array.isArray(params)) {
            let formatted = ""

            for (let i = 0; i < params.length; i++) {
                formatted += `<div style="display: flex; align-items: center;"><div style="background-color: ${params?.[i]?.color}; height: 14px; width: 14px; border-radius: 7px; margin-right: 5px"></div>`
                formatted += `${params[i]?.seriesName}:&nbsp; <b>${Number(Number(params[i].data).toFixed(2)).toLocaleString("pt-BR")}</b>`
                formatted += (i !== (params.length - 1)) ? "</div>\n" : "</div>"
            }

            return formatted
        }
        return ""
    }

    decimalToTime = (data, group) => {
        if (group === "hour" && data > 1) {
            data = 1
        }

        let decimal = Number("0." + String(data).split('.')[1])
        let hours = data.toString().split('.')[0]
        let minutes = decimal * 60
        let seconds = Number("0." + String(minutes).split('.')[1]) * 60
        minutes = String(minutes).split('.')[0]

        if (isNaN(hours)) hours = "00"
        else if (String(hours).length === 1) hours = "0" + hours

        if (isNaN(minutes)) minutes = "00"
        else if (String(minutes).length === 1) minutes = "0" + minutes

        if (isNaN(seconds)) seconds = "00"
        else if (String(seconds).length === 1) seconds = "0" + seconds

        return `${hours}:${minutes}:${String(seconds).includes('.') ? Number(seconds).toFixed(0) : seconds}`
    }

    gerarGrafico = () => {
        const { charts, detalhesDefault } = this.state
        let generatedCharts = []
        let legend = []
        const axisType = { day: 'Dias', month: 'Meses', hour: 'Horas' }

        const condicoes = {
            'menor_igual': '<=',
            'maior_igual': '>=',
            'menor': '<',
            'maior': '>',
            'igual': '=',
            'diferente': '!=',
        }

        console.log(charts)

        charts.forEach((chart, index) => {
            let series = []
            let referencias = []
            series = chart?.series?.map((serie, index) => {
                if (serie.data.length > 0) {
                    legend.push(serie.descricao + (serie.unidade ? ` (${serie.unidade})` : ''))

                    let label = {
                        show: true,
                        position: 'top',
                        color: "rgba(0, 0, 0, 0.85)",
                        fontWeight: '600',
                        textBorderColor: '#fff',
                        textBorderWidth: 2,
                        fontSize: 12,
                        distance: 5,
                        rotate: chart.xAxis.length > 20 ? 55 : 0,
                        formatter: (params) => !isNaN(params.value) ? Number(params.data).toLocaleString("pt-BR") : params.value
                    }

                    if (serie.horimetro) {
                        label['formatter'] = (params) => {
                            return this.decimalToTime(params.data, detalhesDefault.agrupar)
                        }
                    }

                    return {
                        name: serie.descricao + (serie.unidade ? ` (${serie.unidade})` : ''),
                        data: serie.data,
                        type: serie.tipo_grafico,
                        itemStyle: { color: serie.cor, opacity: serie.referencia ? 0.85 : 1 },
                        lineStyle: { width: serie.referencia ? 2 : 3, opacity: serie?.referencia ? 0.85 : 1 },
                        labelLayout: { hideOverlap: true },
                        label: label,
                    }
                }
                return null
            }).filter(s => s !== null)

            referencias = chart?.referencias?.map((ref, index) => {
                if (ref.tipo_da_referencia === 'automacao' || ref.tipo_da_referencia === 'alerta') {
                    let condicao
                    try {
                        condicao = JSON.parse(ref?.data?.[0]?.condicao)[0]
                    } catch (err) {
                        condicao = undefined
                    }

                    return (
                        <div className="hdv-ref-item" key={index}>
                            <strong>{ref.descricao}</strong><br />
                            <span>
                                {condicao
                                    ?
                                    <>
                                        Condição: {`${condicao.campo} ${condicoes[condicao.condicao]} ${condicao.valor}`}
                                    </>
                                    :
                                    null
                                }
                            </span>
                            <br />
                            <span className="d-flex align-items-center">
                                Cor: <div id="ref-line-color" style={{ backgroundColor: `${ref.cor_linha}` }}></div>
                            </span>
                            <span>Tipo: {ref.tipo_da_referencia}</span>
                        </div>
                    )
                }
                else if (ref.tipo_da_referencia === 'dinamico') {

                    return (
                        <div className="hdv-ref-item" key={index}>
                            <strong>{ref.descricao}</strong><br />
                            <span className="d-flex align-items-center">
                                Cor: <div id="ref-line-color" style={{ backgroundColor: `${ref.cor_linha}` }}></div>
                            </span>
                            <span>Campo: {ref.campo_referencia}</span><br />
                            <span>Tipo: {ref.tipo_da_referencia}</span>
                        </div>
                    )
                } else {
                    const valor = ref.data?.[0]?.valor
                    return (
                        <div className="hdv-ref-item" key={index}>
                            <strong>{ref?.descricao}</strong><br />
                            <span>Valor {valor}</span><br />
                            <span className="d-flex align-items-center">
                                Cor: <div id="ref-line-color" style={{ backgroundColor: `${ref?.cor_linha}` }}></div>
                            </span>
                            <span>Tipo: {ref?.tipo_da_referencia}</span>
                        </div>
                    )
                }
            })

            generatedCharts.push(
                <div key={index} className="chart-item" style={{ padding: '3px' }} id={`chart-id-${chart.id}`}>
                    <h5>{chart.descricao} {chart.unidade ? `(${chart.unidade})` : ''}</h5>
                    {chart?.resumos?.length > 0
                        ?
                        <div className="hdv-resumo-list">
                            {this.gerarResumos(chart.resumos)}
                        </div>
                        :
                        null
                    }
                    {chart?.referencias?.length > 0
                        ?
                        <details className="hdv-chart-refs">
                            <summary>Referências</summary>
                            <section style={{ padding: '8px 0' }}>{referencias}</section>
                        </details>
                        :
                        null
                    }
                    <div className="hdv-chart" >
                        {series?.length > 0 ?
                            <ReactEcharts
                                style={{ height: '400px', width: '100%' }}
                                option={{
                                    series: series,
                                    xAxis: {
                                        data: chart.xAxis,
                                        name: axisType[detalhesDefault.agrupar],
                                        nameTextStyle: { fontWeight: '600', fontSize: '12' },
                                        nameLocation: 'center',
                                        nameGap: 22
                                    },
                                    yAxis: { type: 'value' },
                                    tooltip: {
                                        show: true,
                                        trigger: "axis",
                                        axisPointer: { type: "cross", label: { show: true } },
                                        formatter: (params) => this.formatTooltip(params)
                                    },
                                    legend: { data: legend },
                                    grid: { left: 65, right: 50, bottom: 80 },
                                    dataZoom: [
                                        { type: 'inside', start: 0, end: 100 },
                                        {
                                            bottom: 0,
                                            brushSelect: false, start: 0, end: 10, handleSize: '100%',
                                            handleStyle: { borderWidth: '2', borderColor: 'rgba(0, 0, 0, 0.42)' }
                                        }
                                    ],
                                }} />
                            : <p style={{ margin: '12px 0', textAlign: 'center', color: '#a33131', fontWeight: 'bold' }}>Erro ao carregar gráfico</p>}
                    </div>
                    <hr />
                </div>)
        })

        return this.state.carregandoDados ? null : generatedCharts
    }

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    handleChangeRelatorio = (e, field) => {
        const { detalhesDefault } = this.state
        detalhesDefault[field] = e
        this.setState({ detalhesDefault })
    }

    hideRef = () => {
        let referencias = document.querySelectorAll('.hdv-chart-refs')
        let icones = document.querySelectorAll('.hdv-resumo i')
        referencias.forEach(ref => ref.hidden = true)
        icones.forEach(icone => icone.classList.add('hide-icon'))

        setTimeout(() => {
            referencias.forEach(ref => ref.hidden = false)
            icones.forEach(icone => icone.classList.remove('hide-icon'))
        }, 1000)
    }

    toDataURL = (url, callback) => {
        let xhr = new XMLHttpRequest()
        xhr.onload = function () {
            let reader = new FileReader()
            reader.onloadend = function () {
                callback(reader.result)
            }
            reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
    }

    exportarPDF = async () => {
        const { charts, detalhesDefault } = this.state
        let canvas = document.querySelectorAll('.chart-item') ?? []
        let infoEmpresa
        let graficos = []
        this.setState({ exportCooldown: true })
        this.hideRef()

        canvas.forEach(async c => {
            let data
            const item = charts.find(chart => `chart-id-${chart.id}` === c.id)
            data = await domToImage.toPng(c)

            graficos.push({ ...item, dom: c, img: data })
        })

        await axios.get(this.baseUrl + `/${this.userInfo.empresa}/configuracao-sistema`, this.config_me)
            .then(res => {
                infoEmpresa = res.data?.data
                if (detalhesDefault.cliente?.label) {
                    infoEmpresa.cliente = detalhesDefault.cliente.label.split(" - ")[0]
                    infoEmpresa.data = detalhesDefault.data.replace("-", " - ")
                }
            })

        const dadosEmpresa = []
        if (infoEmpresa?.email_contato) {
            dadosEmpresa.push(infoEmpresa.email_contato)
            dadosEmpresa.push('\n')
        }
        if (infoEmpresa?.endereco) {
            dadosEmpresa.push(infoEmpresa.endereco)
            dadosEmpresa.push('\n')
        }
        if (infoEmpresa?.telefone) {
            dadosEmpresa.push(infoEmpresa.telefone)
            dadosEmpresa.push('\n')
        }

        const pdfOptions = {
            content: [
                {
                    table: {
                        widths: ['*', 120],
                        body: [[
                            {
                                text: dadosEmpresa,
                                border: [false, false, false, true]
                            },
                            { image: 'logo', style: 'logo', width: 80, border: [false, false, false, true] }
                        ]]
                    },
                },
                { text: [`Cliente: ${infoEmpresa?.cliente}`, `\nRelatório: ${detalhesDefault?.descricao}`, `\nPeríodo: ${infoEmpresa?.data}`], style: 'info' },
            ],
            images: { 'logo': document.querySelector('div.hdv-logomarca img')?.src, },
            styles: {
                logo: { margin: [40, -18, 70, 0] },
                info: { margin: [5, 8], fontSize: '11', color: '#444444' }
            }
        }

        graficos?.forEach(async (grafico, index) => {
            pdfOptions.images['chart_' + index] = grafico.img
            pdfOptions.content.push({
                image: 'chart_' + index,
                margin: [0, 20],
                width: 516,
            })
        })

        const win = window.open('', '_blank')
        pdfMake.createPdf(pdfOptions).open({}, win)
        this.setState({ exportCooldown: false })
    }

    render() {
        const { relatorioGerado, detalhesDefault } = this.state

        if (this.state.redirect)
            return <Redirect to="/relatorio-customizado" />

        const altForm = Boolean(this.props.match.params.id)

        return (
            <>
                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.setState(this.state.aviso.args)}
                    continue={altForm ? () => {
                        let { aviso } = this.state
                        aviso = { show: false, args: null, message: '' }
                        this.setState({ aviso })
                    }
                        :
                        null}
                    open={this.state.aviso.show}
                />

                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                    <h3 className="mx-3">Relatório Customizado</h3>
                    <br />
                    <form className="hdv-form-default row px-3 mb-2">
                        <div className={altForm ? "col-md-5" : "col-md-4"}>
                            <label className="required mt-0">Descrição</label>
                            <input type="text" onChange={(e) => this.handleChangeRelatorio(e.target.value, 'descricao')} value={detalhesDefault.descricao} disabled={this.state.cliente} />
                            <span className={(this.state.validacao_formulario.descricao === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                        </div>

                        {altForm ? <div className="col-md-1"></div> : null}

                        <div className={altForm ? "col-md-3 mt-2" : "col-md-4 mt-2"}>
                            <label>Disponível para cliente final<br />
                                <input
                                    type="checkbox"
                                    onChange={(e) => this.handleChangeRelatorio(e.target.checked, 'disponivel_cliente_final')}
                                    checked={detalhesDefault.disponivel_cliente_final}
                                    disabled={this.state.cliente}
                                />
                            </label>
                        </div>

                        <div className={altForm ? "col-md-3 mt-2" : "col-md-4 mt-2"}>
                            <label>Manter dados do equipamento<br />
                                <input
                                    type="checkbox"
                                    onChange={(e) => this.handleChangeRelatorio(e.target.checked, 'manter_equipamento')}
                                    checked={detalhesDefault.manter_equipamento}
                                    disabled={this.state.cliente}
                                />
                            </label>
                        </div>
                    </form>
                    <HeaderReport altForm={altForm} cliente={this.state.cliente} DataChange={this.state.detalhesDefault} fn={this.buscarCanais} validacao={this.state.validacao_formulario} />

                    <hr style={{ marginTop: '25px' }} />

                    {this.props.match.params.id ?
                        <RelatorioCustomizado
                            id={this.props.match.params.id}
                            relatorioGerado={relatorioGerado}
                            exportCooldown={this.state.exportCooldown}
                            charts={this.state.charts}
                            resumos={this.state.resumos}
                            referencias={this.state.referencias}
                            setState={(data) => this.setState(data)}
                            getData={this.getData}
                            getResource={this.getResource}
                            resource={this.state.resource}
                            carregandoDados={this.state.carregandoDados}
                            relatorioErro={this.state.relatorioErro}
                            buscarDadosRelatorio={this.buscarDadosRelatorio}
                            cliente={this.state.cliente}
                            exportarPdf={this.exportarPDF}
                        />
                        : null
                    }

                    {relatorioGerado ?
                        <>
                            <hr style={{ marginTop: '20px' }} />
                            <div className="col-md-12" style={{ padding: '8px 10px', marginTop: '15px' }}>
                                {this.gerarGrafico()}
                            </div>
                        </>
                        : null
                    }

                    <img id="captured" />

                    <div className={!this.props.match.params.id ? "hdv-btn-group mt-4 mx-3" : "hdv-noshow-item"} >
                        <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-geen " disabled={this.state.cooldown || this.state.relatorioErro}>
                            Salvar
                        </button>
                        <Link to="/relatorio-customizado">
                            <button className="hdv-btn-forms hdv-btn-yellow ">Cancelar</button>
                        </Link>
                    </div>

                    <div className={this.props.match.params.id ? "col-md-12 px-0 pt-2" : "hdv-noshow-item"} >
                        <div className={!this.props.match.params.id ? "hdv-btn-group" : "hdv-noshow-item"} style={{ margin: '10px 16px' }}>
                            <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-geen " disabled={this.state.cooldown || this.state.relatorioErro}>
                                Salvar
                            </button>
                            <Link to="/relatorio-customizado">
                                <button className="hdv-btn-forms hdv-btn-yellow ">Cancelar</button>
                            </Link>
                        </div>

                        <div className="hdv-btn-group mx-3">
                            <Link to="/relatorio-customizado">
                                <button className="hdv-btn-forms hdv-btn-yellow ">Cancelar</button>
                            </Link>

                            <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown || this.state.relatorioErro}>
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Form


