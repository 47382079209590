import { Component } from 'react'
import * as rdrLocales from 'react-date-range/dist/locale'
import { DateRangePicker } from 'react-date-range'
import ReactLoading from 'react-loading'
import axios from 'axios'
import Select from 'react-select'
import auth from "../utils/auth"
import config from "../utils/config"
import { Modal, ModalBody, Button, ModalFooter, ModalHeader, InputGroup, InputGroupAddon, Input } from 'reactstrap'


class RelatorioStatus extends Component {
   state = {
      formatoAmbiental: {
         checked: false, horaInicio: '08:00', horaFim: '', valido: true, formato: '', horimetro: '', nivel: '', volume: '',
         hidrometro: '', tipo: { label: 'Vazão', value: 'vazao' }, entradasDigitais: [], entradasAnalogicas: [],
         m900: false
      },
      detalhes: { modal: false, link: "", carregando: false },
      dateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
   }

   handleSelectDate = (ranges) => {
      let data_inicio = ranges.selection.startDate
      let data_fim = ranges.selection.endDate
      if (ranges.selection.startDate === ranges.selection.endDate) {
         let data = new Date(ranges.selection.startDate.getTime())
         let dateArray = data.toLocaleDateString('pt-BR',
            {
               year: 'numeric',
               month: 'numeric',
               day: 'numeric'
            }
         ).split('/')

         data_inicio = new Date(dateArray[2], dateArray[1] - 1, dateArray[0], '00', '00', '00')
         data_fim = new Date(dateArray[2], dateArray[1] - 1, dateArray[0], '23', '59', '59')
      }

      this.setState({
         dateRange: { startDate: data_inicio, endDate: data_fim, key: 'selection' },
         dataFormatada: ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString(),
      })
   }

   toggleModal = () => this.setState({ detalhes: { ...this.state.detalhes, modal: !this.state.detalhes.modal } })

   showDatePicker = () => this.setState({ "datePickerShow": !this.state.datePickerShow })

   componentWillReceiveProps(props) {
      const { formatoAmbiental } = this.state

      let campos = []
      
      for ( let key in props.ultimo_reporte){
         console.log(props.ultimo_reporte[key])
         console.log(typeof(props.ultimo_reporte[key]))
         if (typeof(props.ultimo_reporte[key]) == 'object'){
            let posicoes = props.ultimo_reporte[key].length + 1
            for (let index = 1; index < posicoes; index++) {
               campos.push({'value': String(key) + '_' + index, 'label': String(key) + '_' + index })
            }
         }else{
            campos.push({'value': key, 'label': key})
         }
         
      }

      formatoAmbiental['entradasDigitais'] = props.entradas.entradasDigitais
      formatoAmbiental['entradasAnalogicas'] = props.entradas.entradasAnalogicas
      formatoAmbiental['todos_campos'] = campos
      this.setState({ formatoAmbiental })
   }

   handleChangeFormato = (value, field) => {
      let { formatoAmbiental } = this.state
      formatoAmbiental[field] = value
      this.setState({ formatoAmbiental })

      if (field === 'checked' && value === false) {
         formatoAmbiental = {
            checked: false, horaInicio: '08:00', horaFim: '', valido: true, formato: '',
            horimetro: '', nivel: '', hidrometro: '', tipo: { label: 'Vazão', value: 'vazao' },
            m900: false
         }
         this.setState({ formatoAmbiental })
      }
   }

   exportar = () => {
      let { dateRange, formatoAmbiental, detalhes } = this.state
      this.setState({ detalhes: { ...detalhes, carregando: true } })
      let userInfo = auth.getUserInfo()
      let data = new FormData()

      if (formatoAmbiental.checked && !formatoAmbiental.horaInicio) {
         formatoAmbiental['valido'] = false
         this.setState({ formatoAmbiental, detalhes: { ...detalhes, carregando: false } })
         return
      } else {
         formatoAmbiental['valido'] = true
         this.setState({ formatoAmbiental })
         if (formatoAmbiental.checked) {
            data.append('hora_inicio', this.state.formatoAmbiental.horaInicio)
         }
      }

      if (formatoAmbiental.formato?.value !== 'padrao') {
         if (formatoAmbiental.hidrometro?.value) {
            data.append('hidrometro', formatoAmbiental.hidrometro?.value)
            data.append('hora_fim', this.state.formatoAmbiental.horaFim)
         }

         if (formatoAmbiental.nivel?.value)
            data.append('nivel', formatoAmbiental.nivel?.value)

         data.append('horimetro', formatoAmbiental.horimetro?.value)
         data.append('volume', formatoAmbiental.volume?.value)
         data.append('tipo', formatoAmbiental.tipo?.value)
      }

      let data_ini = dateRange.startDate.toISOString().slice(0, 10) + " 00:00:00"
      let data_fim = dateRange.endDate.toISOString().slice(0, 10) + " 23:59:59"

      data.append('formato', formatoAmbiental.formato?.value ?? 'padrao')
      data.append('timestamp_dispositivo_ini', data_ini)
      data.append('timestamp_dispositivo_fim', data_fim)
      data.append('id_dispositivo', this.props.imei)
      data.append('m900', formatoAmbiental.m900)

      axios({
         method: 'POST',
         url: config.base_api_url + config.api_version + '/' + userInfo.empresa + '/reporte/exportar-csv/',
         data: data,
         headers: { 'Authorization': 'Bearer ' + auth.getToken() }
      }).then(res => {
         this.setState({ detalhes: { ...detalhes, carregando: false, link: res.data?.link } })
      }).catch(err => {
         console.log(err)
         this.setState({ detalhes: { ...detalhes, carregando: false, link: '' } })
      })
   }

   render() {
      const { formatoAmbiental, detalhes } = this.state

      return (
         <>
            <button className="hdv-btn-forms hdv-btn-geen float-right" onClick={this.toggleModal}>Exportar</button>
            <Modal isOpen={detalhes.modal} toggle={this.toggleModal} className="modal-md">
               <ModalHeader toggle={this.toggleModal}>Exportar</ModalHeader>
               <ModalBody style={{ padding: '20px 20px' }}>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="col-md-12 px-0 pr-2">
                           <label className="required">Período</label>
                           <InputGroup className="hdv-force-hight">
                              <InputGroupAddon addonType="prepend">
                                 <Button onClick={() => this.manipular_data("voltar")} color="info">
                                    <i className="fa fa-angle-double-left"></i>
                                 </Button>
                              </InputGroupAddon>
                              <Input className="hdv-force-hight" placeholder="Selecione o periodo no botão 'Exibir Calendário' " onChange={() => false} disabled value={this.state.dataFormatada} />
                              <InputGroupAddon addonType="append">
                                 <Button onClick={this.showDatePicker} color={(this.state.datePickerShow) ? "danger" : "primary"}>
                                    <i className={(this.state.datePickerShow) ? "fa fa-calendar-times-o" : "fa fa-calendar-check-o"}></i>
                                    {(this.state.datePickerShow) ? "Fechar Calendário" : "Exibir Calendário"}
                                 </Button>
                              </InputGroupAddon>
                              <InputGroupAddon addonType="append">
                                 <Button onClick={() => this.manipular_data("avancar")} color="info">
                                    <i className="fa fa-angle-double-right"></i>
                                 </Button>
                              </InputGroupAddon>
                           </InputGroup>
                           <div className={(this.state.datePickerShow) ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                              <DateRangePicker
                                 locale={rdrLocales.pt}
                                 ranges={[this.state.dateRange]}
                                 onChange={this.handleSelectDate}
                              />
                           </div>
                        </div>

                        <br />
                        <label>
                           Formato de órgão Ambiental &nbsp;
                           <input
                              type="checkbox"
                              value={formatoAmbiental.checked}
                              onChange={e => this.handleChangeFormato(e.target.checked, 'checked')}
                           />
                        </label>

                        <div className="row">
                           <div className={formatoAmbiental.checked ? "col-md-6" : "hdv-noshow-item"}>
                              <label className="mb-0">Formato</label>
                              <Select
                                 value={formatoAmbiental.formato}
                                 onChange={(e) => this.handleChangeFormato(e, 'formato')}
                                 options={[
                                    { label: 'Padrão', value: 'padrao' },
                                    { label: 'INEMA', value: 'INEMA' },
                                    { label: 'SERHMA', value: 'SERHMA' },
                                 ]}
                              />
                           </div>
                           <div className={formatoAmbiental.checked && formatoAmbiental?.formato?.value !== 'padrao' ? "col-md-6" : "hdv-noshow-item"}>
                              <label className="mb-0">Tipo</label>
                              <Select
                                 isDisabled={!(formatoAmbiental?.formato?.value === 'INEMA')}
                                 value={formatoAmbiental.tipo}
                                 onChange={(e) => this.handleChangeFormato(e, 'tipo')}
                                 options={[
                                    { label: 'Vazão', value: 'vazao' },
                                    { label: 'Nível', value: 'nivel' },
                                 ]}
                              />

                           </div>
                           <div className={formatoAmbiental.checked && formatoAmbiental?.formato?.value == 'INEMA' ? "col-md-6 hdv-top15" : "hdv-noshow-item"}>
                              <label className="mb-0">Volume</label>
                              <Select
                                 value={formatoAmbiental?.volume}
                                 onChange={(e) => this.handleChangeFormato(e, 'volume')}
                                 options={formatoAmbiental?.todos_campos}
                              />
                           </div>
                           {formatoAmbiental.tipo.value === 'vazao'
                              ?
                              <div className={formatoAmbiental.checked && formatoAmbiental?.formato?.value !== 'padrao' ? "col-md-6 hdv-top15" : "hdv-noshow-item"}>
                                 <label className="mb-0">Vazão</label>
                                 <Select
                                    value={formatoAmbiental?.hidrometro}
                                    onChange={(e) => this.handleChangeFormato(e, 'hidrometro')}
                                    options={formatoAmbiental?.todos_campos}
                                 />
                              </div>
                              :
                              <div className={formatoAmbiental.checked && formatoAmbiental?.formato?.value !== 'padrao' ? "col-md-6 hdv-top15" : "hdv-noshow-item"}>
                                 <label className="mb-0">Nível</label>
                                 <Select
                                    value={formatoAmbiental?.nivel}
                                    onChange={(e) => this.handleChangeFormato(e, 'nivel')}
                                    options={formatoAmbiental?.todos_campos}
                                 />
                              </div>
                           }
                           <div className={formatoAmbiental.checked && formatoAmbiental?.formato?.value !== 'padrao' ? "col-md-6 hdv-top15" : "hdv-noshow-item"}>
                              <label className="mb-0">Horímetro</label>
                              <Select
                                 value={formatoAmbiental?.horimetro}
                                 onChange={(e) => this.handleChangeFormato(e, 'horimetro')}
                                 options={formatoAmbiental?.todos_campos}
                              />
                           </div>
                           <div className="row col-md-12">
                              <div className="col-md-6 d-flex">
                                 <div className={formatoAmbiental.checked ? "hdv-top15" : "hdv-noshow-item"}>
                                    <label>Inicio<br />
                                       <input
                                          type="time"
                                          value={formatoAmbiental.horaInicio}
                                          onChange={(e) => this.handleChangeFormato(e.target.value, 'horaInicio')}
                                       />
                                    </label>
                                    <br />
                                    <span className={!formatoAmbiental.valido ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                                 </div>
                                 <div className={formatoAmbiental.checked && formatoAmbiental.formato?.value !== "padrao" ? "hdv-top15" : "hdv-noshow-item"}
                                    style={{ paddingLeft: '30px' }}>
                                    <label style={{ width: '100%' }}>Fim<br />
                                       <input
                                          type="time"
                                          value={formatoAmbiental.horaFim}
                                          onChange={(e) => this.handleChangeFormato(e.target.value, 'horaFim')}
                                       />
                                    </label>
                                    <br />
                                    <span className={!formatoAmbiental.valido ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className={formatoAmbiental.checked && formatoAmbiental.formato?.value === 'INEMA' ? "ml-3 hdv-top15" : "hdv-noshow-item"}>
                                    <label>Maior que 900m³/dia<br />
                                       <input
                                          type="checkbox"
                                          value={formatoAmbiental.m900}
                                          onChange={(e) => this.handleChangeFormato(e.target.checked, 'm900')}
                                       />
                                    </label>
                                    <br />
                                    <span className={!formatoAmbiental.valido ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </ModalBody>
               <ModalFooter>

                  {detalhes.carregando ?
                     <ReactLoading
                        style={{ padding: '8px 0' }}
                        type="spin"
                        color="#589bd4"
                        height={20}
                        width={30}
                     />
                     :
                     detalhes.link ?
                        <a href={detalhes.link}
                           rel="noopener noreferrer"
                           target="_blank">
                           <button className="hdv-btn-forms hdv-btn-blue">Baixar</button>
                        </a>
                        : null
                  }
                  <button className="hdv-btn-forms hdv-btn-geen" onClick={this.exportar} disabled={detalhes.carregando}>Iniciar Exportação</button>
                  <button onClick={this.toggleModal} className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
               </ModalFooter>
            </Modal>
         </>
      )
   }
}


export default RelatorioStatus