import React, { Component } from 'react';
import { Switch } from 'react-router-dom'

import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';

import MenuBar from '../../components/MenuBar'
import MenuSystem from '../../components/MenuSystem'

import PrivateRoute from '../../containers/PrivateRoute'
import Dashboard from '../../containers/Dashboard'

import Acesso from '../../containers/Acesso'
import Cliente from '../../containers/Cliente'
import Empresa from '../../containers/Empresa'
import Equipamento from '../../containers/Equipamento'
import Operadora from '../../containers/Operadora'
import Fabricante from '../../containers/Fabricante'
import ModeloEquipamento from '../../containers/ModeloEquipamento'
import Aplicacao from '../../containers/Aplicacao'
import Setor from '../../containers/Setor'
import Monitorado from '../../containers/Monitorado'
import Item from '../../containers/Item'
import RelatorioHidrometro from '../../containers/RelatorioHidrometro'
import Logs from '../../containers/Logs'
import Parametrizacao from '../../containers/Parametrizacao'
import Relatorio4a20 from '../../containers/Relatorio4a20'
import RelatorioEnergia from '../../containers/RelatorioEnergia'
import PerfilUsuario from '../../containers/PerfilUsuario'
import PerfilCliente from '../../containers/PerfilCliente'
import ConfiguracaoSistema from '../../containers/ConfiguracaoSistema'
import Automacao from '../../containers/Automacao'
import MinhaConta from '../../containers/MinhaConta'
import Usuario from '../../containers/Usuario'
import Alerta from '../../containers/Alerta'
import ListaEquipamentos from '../../containers/ListaEquipamentos/nova.js'
import ListaAlertas from '../../containers/ListaAlertas'
import Status from '../../containers/Status'
import EquipamentoVirtual from '../../containers/EquipamentoVirtual'
import RelatorioAnalitico from '../../containers/RelatorioAnalitico'
import CampoCustomizado from '../../containers/CampoCustomizado'
import Sideccr from '../../containers/Sideccr'
import RelatorioCustomizado from '../../containers/RelatorioCustomizado'
import RelatorioConsumo from '../../containers/RelatorioConsumo'

class Layout extends Component {

	state = {
		cores_cfg: { "degrade_top_primeira_cor": "#F44E3B", "degrade_top_segunda_cor": "#2f577b", "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7", "fonte_grupo_menu_lateral": "#c7c7c7" },
		opacity: 0,
		logomarca: ''
	}

	componentWillMount = () => {
		let requestArray = []
		let userInfo = auth.getUserInfo();
		let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

		requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/configuracao-cores/', config_me))
		requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/configuracao-sistema/', config_me))

		axios.all(requestArray)
			.then(axios.spread((cores_cfg, config) => {

				let cores = { "degrade_top_primeira_cor": "#F44E3B", "degrade_top_segunda_cor": "#2f577b", "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7", "fonte_grupo_menu_lateral": "#c7c7c7" }
				let logomarca = ''
				if (cores_cfg.status === 200) {
					cores = cores_cfg.data
				}

				if (config.status === 200) {
					logomarca = config.data.data.logomarca
				}

				this.setState({ cores_cfg: cores, opacity: 1, logomarca: logomarca })
			}));
	}

	render() {
		return (
			<div style={{ opacity: this.state.opacity }}>
				<div style={{ background: '-webkit-linear-gradient(top, ' + this.state.cores_cfg.degrade_top_primeira_cor + ' 0%, ' + this.state.cores_cfg.degrade_top_segunda_cor + ' 30%, ' + this.state.cores_cfg.degrade_top_segunda_cor + ' 30%,#ffffff 100%)' }} className="hdv-background-header"></div>

				<div className="hdv-app-container">
					<div style={{ backgroundColor: this.state.cores_cfg.back_menu_lateral }} className="fixed">
						<div style={{ backgroundColor: this.state.cores_cfg.back_logomarca }} className="hdv-logomarca">
							<img crossOrigin="anonymous" src={(this.state.logomarca) ? this.state.logomarca : ''} alt="Logomarca" id="logomarca" />
						</div>
						<div className="hdv-menu-bloco scroll-parent">
							<MenuSystem pathCurrent={this.props.location.pathname} coresCfg={this.state.cores_cfg} />
						</div>
					</div>
					<div className="fluid">
						<MenuBar myMenu={document.getElementsByClassName("fixed")} coresCfg={this.state.cores_cfg} myBody={document.getElementsByClassName("fluid")} />
						<div className="hdv-body-app">
							<div className="hdv-conteudo">
								<div className="row">
									<Switch>
										<PrivateRoute exact path='/' component={Dashboard} />
										<PrivateRoute path='/acessos' component={Acesso} />
										<PrivateRoute path='/cliente' component={Cliente} />
										<PrivateRoute path='/empresa' component={Empresa} />
										<PrivateRoute path='/equipamento/:imei/status' component={Status} />
										<PrivateRoute path='/equipamento' component={Equipamento} />
										<PrivateRoute path='/aplicacao' component={Aplicacao} />
										<PrivateRoute path='/setor' component={Setor} />
										<PrivateRoute path='/monitorado' component={Monitorado} />
										<PrivateRoute path='/item' component={Item} />
										<PrivateRoute path='/base/operadora' component={Operadora} />
										<PrivateRoute path='/base/fabricante' component={Fabricante} />
										<PrivateRoute path='/base/modelo-equipamento' component={ModeloEquipamento} />
										<PrivateRoute path='/relatorio/consumo-agua' component={RelatorioHidrometro} />
										<PrivateRoute path='/relatorio/analitico' component={RelatorioAnalitico} />
										<PrivateRoute path='/relatorio-customizado' component={RelatorioCustomizado} />
										<PrivateRoute path='/relatorio-consumo' component={RelatorioConsumo} />
										<PrivateRoute path='/relatorio/logs' component={Logs} />
										<PrivateRoute path='/sideccr/' component={Sideccr} />
										<PrivateRoute path='/campo-customizado' component={CampoCustomizado} />
										<PrivateRoute path='/relatorio/4a20' component={Relatorio4a20} />
										<PrivateRoute path='/relatorio/energia' component={RelatorioEnergia} />
										<PrivateRoute path='/parametrizacao' component={Parametrizacao} />
										<PrivateRoute path='/perfil/usuario' component={PerfilUsuario} />
										<PrivateRoute path='/perfil/cliente' component={PerfilCliente} />
										<PrivateRoute path='/configuracao' component={ConfiguracaoSistema} />
										<PrivateRoute path='/minha-conta' component={MinhaConta} />
										<PrivateRoute path='/automacao' component={Automacao} />
										<PrivateRoute path='/usuario' component={Usuario} />
										<PrivateRoute path='/alerta' component={Alerta} />
										<PrivateRoute path='/ultima-comunicacao' component={ListaEquipamentos} />
										<PrivateRoute path='/virtual' component={EquipamentoVirtual} />
										<PrivateRoute path='/lista-alertas' component={ListaAlertas} />
									</Switch>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Layout;