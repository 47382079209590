import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom'
import "react-tabs/style/react-tabs.css";
import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import Aviso from '../../components/Aviso'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Select from 'react-select'

class Form extends Component {

    state = {
        detalhes: { "nome": "" },
        formerrors: [],
        redirect: false,
        path: "",
        permissoes: [],
        open_group_block: {},
        selected_permission: [],
        dispositivos: [],
        clientes: [],
        aviso: { show: false, args: null, message: '' },
        cooldown: false,
        validacao: { data: true, agrupar: true, descricao: true, dispositivos: true },
        addDispositivo: {
            modal: false,
            filtrados: [],
            selecionados: [],
            filtros: { cliente: null, monitorado: null },
        },
    }

    componentWillMount = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/permissoes/', config_me))

        axios.all(requestArray)
            .then(axios.spread((permissoes) => {
                let open_close_group_block = {}
                permissoes.data.forEach(grupo => {
                    open_close_group_block[grupo.grupo.toLowerCase()] = true
                });
                this.setState({ "permissoes": permissoes.data, open_group_block: open_close_group_block })
            }))
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }
        this.getClientes()
        if (auth.getUserInfo()?.tipo === "cliente") {
            this.handleChangeCliente({ value: auth.getUserInfo()?.cliente })
        }
    }

    getClientes = () => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/', config_me)
            .then(res => {
                let clientes = []
                clientes.push({ label: 'Nenhum', value: null })

                if (res.data.length > 0) {
                    res.data.forEach((c) => {
                        clientes.push({ "value": c.id, "label": c.nome_fantasia + ' - ' + c.cpf_cnpj })
                    })
                }

                this.setState({ "clientes": clientes })
            })
    }

    getData = () => {
        let resource = "usuario/perfil"
        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((response) => {
                let permissoes = response.data.permissoes
                let selected_permission = []
                let dispositivos = []

                delete response.data.id
                delete response.data.permissoes
                permissoes.forEach((v) => {
                    selected_permission.push(v.id)
                })

                response.data?.equipamentos?.map(equip => {
                    dispositivos.push({ id: equip.imei })
                })

                this.setState({ detalhes: response.data, selected_permission: selected_permission, dispositivos: dispositivos });
            })
    }


    handleChangeMonitorado = (selectedOption) => {
        let { addDispositivo } = this.state
        addDispositivo['filtros']['monitorado'] = selectedOption

        if (selectedOption.value !== null) {
            let userInfo = auth.getUserInfo()
            const filterData = new FormData()

            if (addDispositivo.filtros.cliente?.value) {
                filterData.append('item__monitorado__cliente_id', addDispositivo.filtros.cliente.value)
            }
            filterData.append('item__monitorado__id', selectedOption.value)

            axios({
                method: 'GET',
                url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado-equipamento/' + selectedOption.value + '/',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then((response) => {
                    let optionsItem = []
                    response.data.forEach((c) => optionsItem.push({ "value": c, "label": c }))
                    this.setState({ "itens": optionsItem })
                })
                .catch((error) => console.log(error))

            axios({
                method: 'POST',
                url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reporte-lista-equipamento-revenda/?page=0&pageSize=100&filter=%5B%5D',
                data: filterData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            }).then(res => {
                let { addDispositivo } = this.state
                let filtrados = []

                res.data?.results?.map(item => {
                    filtrados.push({ label: item.imei, value: item.imei })
                })

                addDispositivo['filtrados'] = filtrados
                this.setState({ addDispositivo })
            }).catch((error) => console.log(error))
        } else {
            let { addDispositivo } = this.state
            addDispositivo['filtros']['monitorado'] = null
            this.setState({ addDispositivo })
        }
    }

    handleChangeCliente = (selectedOption) => {
        let { addDispositivo } = this.state
        addDispositivo['filtros']['cliente'] = selectedOption
        addDispositivo['filtros']['monitorado'] = null

        if (selectedOption.value !== null) {
            let userInfo = auth.getUserInfo()
            const bodyFormData = new FormData()
            const filterData = new FormData()

            bodyFormData.append('cliente_id', selectedOption.value)
            filterData.append('item__monitorado__cliente_id', selectedOption.value)

            axios({
                method: 'POST',
                url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado/filtro/',
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then((response) => {
                    let optionsMonitorado = []
                    optionsMonitorado.push({ label: 'Nenhum', value: null })

                    response.data.forEach((c) => {
                        optionsMonitorado.push({ "value": c.id, "label": c.nome })
                    })
                    this.setState({ "monitorados": optionsMonitorado })
                })
                .catch((error) => console.log(error))

            axios({
                method: 'POST',
                url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reporte-lista-equipamento-revenda/?page=0&pageSize=100&filter=%5B%5D',
                data: filterData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            }).then(res => {
                let { addDispositivo } = this.state
                let filtrados = []

                res.data?.results?.map(item => {
                    filtrados.push({ label: item.imei, value: item.imei })
                })

                addDispositivo['filtrados'] = filtrados
                this.setState({ addDispositivo })
            }).catch((error) => console.log(error))

            this.handleChangeMonitorado({ label: 'Nenhum', value: null })
        } else {
            let { addDispositivo } = this.state
            addDispositivo['filtrados'] = []
            this.setState({ addDispositivo })
        }
    }

    handleChange = (e) => {
        let obj = this.state.detalhes

        if (e.target.type === "checkbox") {
            obj[e.target.id] = (e.target.checked === true) ? true : false
        } else {
            obj[e.target.id] = e.target.value
        }

        document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""
        this.setState({ "detalhes": obj })
    }

    saveAction = (novo_cadastro) => {
        this.setState({ cooldown: true })
        let userInfo = auth.getUserInfo();
        let resource = "usuario/perfil"
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/';
            verb = 'post'
        }
        let bodyFormData = new FormData();

        for (let key in this.state.detalhes) {
            bodyFormData.append(key, this.state.detalhes[key]);
        }

        bodyFormData.append('permissoes', this.state.selected_permission);
        if (this.state.dispositivos.length > 0) {
            const equipamentos = this.state.dispositivos?.map(equip => equip.id)
            bodyFormData.append('equipamentos', equipamentos.join(","));
        } else {
            bodyFormData.delete('equipamentos')
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (novo_cadastro === true) {
                    if (this.props.match.params.id) {
                        const aviso = { show: true, message: `Perfil ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`, args: { redirect: true, path: "/perfil/usuario" } }
                        this.setState({ aviso })
                    } else {
                        window.location.reload()
                    }
                } else {
                    const aviso = { show: true, message: `Perfil ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`, args: { redirect: true, path: "/perfil/usuario" } }
                    this.setState({ aviso })
                }
            })
            .catch((error) => {
                console.log(error)
                let erros = error.response.data
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span) {
                            span.innerHTML = erros[i][1]
                            window.scrollTo({ top: 50, behavior: 'smooth' })
                        }
                    }
                }
            })

        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    change_block_view = (group) => {
        let open_block_group = this.state.open_group_block
        open_block_group[group] = !open_block_group[group]
        this.setState({ open_group_block: open_block_group })
    }

    change_permission_option = (id) => {
        let selected_permission = this.state.selected_permission
        if (selected_permission.includes(id)) {
            selected_permission = selected_permission.filter(item => item !== id)
        } else {
            selected_permission.push(id)
        }
        this.setState({ selected_permission: selected_permission })
    }

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    change_permission_group = (event, group) => {
        let selected_permission = this.state.selected_permission
        if (event.target.checked) {
            this.state.permissoes[group].permissoes.forEach((permissao) => {
                selected_permission.push(permissao.id)
            })
        } else {
            this.state.permissoes[group].permissoes.forEach((permissao) => {
                selected_permission = selected_permission.filter(item => item !== permissao.id)
            })
        }
        this.setState({ selected_permission: selected_permission })
    }

    create_group_permission = () => {
        return this.state.permissoes.map((v, i) => {
            return (
                <div key={i} className="hdv-block-group">
                    <div className="hdv-title-create-profile">
                        <input onChange={(e) => this.change_permission_group(e, i)} type="checkbox" /> {v.grupo}
                        <span className="hdv-open-close-block-group"><i onClick={() => this.change_block_view(v.grupo.toLowerCase())} className={(this.state.open_group_block[v.grupo.toLowerCase()]) ? "fa fa-arrow-circle-up" : "fa fa-arrow-circle-down"} ></i></span>
                    </div>
                    {
                        v.permissoes.map((p, i) => {
                            return (
                                <div key={i} className={(this.state.open_group_block[v.grupo.toLowerCase()]) ? "hdv-permission-block" : "hdv-noshow-item"} >
                                    <input checked={(this.state.selected_permission.includes(p.id)) ? true : false} onChange={() => this.change_permission_option(p.id)} type="checkbox" />
                                    <span className="hdv-permission-description">{p.descricao}</span>
                                </div>
                            )
                        })
                    }
                </div>
            )
        })
    }

    handleDispositivos = (e) => {
        e.stopPropagation()
        let { addDispositivo } = this.state
        let selected = addDispositivo.selecionados
        const item = e.target?.parentElement?.outerText

        if (e.target.checked && !selected.includes(item)) {
            selected.push({ id: item })
        } else {
            selected = addDispositivo['selecionados'].filter(i => i.id !== item)
        }

        addDispositivo['selecionados'] = selected
        this.setState({ addDispositivo })
    }

    toggleModal = () => {
        let { addDispositivo } = this.state
        addDispositivo['modal'] = !addDispositivo['modal']
        this.setState({ addDispositivo })
    }

    deletar = (index) => {
        let { addDispositivo } = this.state

        addDispositivo['selecionados'].splice(index, 1)
        this.setState({ addDispositivo })
    }

    cancelar = () => {
        let { addDispositivo } = this.state
        addDispositivo['selecionados'] = []
        addDispositivo['modal'] = false
        addDispositivo['filtros'] = { cliente: null, monitorado: null }
        this.setState({ addDispositivo })
    }

    adicionar = () => {
        let { addDispositivo, dispositivos } = this.state

        dispositivos = addDispositivo['selecionados']
        addDispositivo['selecionados'] = []
        addDispositivo['modal'] = false
        addDispositivo['filtros'] = { cliente: null, monitorado: null }

        document.querySelector('.hdv-multi-report-dispositivos details').open = true
        this.setState({ addDispositivo, dispositivos })
    }

    gerenciar = (e) => {
        e.preventDefault()
        let { addDispositivo, dispositivos } = this.state
        addDispositivo['selecionados'] = dispositivos

        this.setState({ dispositivos, addDispositivo })
        this.toggleModal()
    }

    selecionarTodos = () => {
        let { addDispositivo } = this.state
        let filtrados = addDispositivo.filtrados
        let selecionados = addDispositivo.selecionados

        filtrados.map(item => {
            if (selecionados.filter(s => s.id === item.label).length === 0)
                selecionados.push({ id: item.label })
        })

        addDispositivo['selecionados'] = selecionados
        this.setState({ addDispositivo })
    }

    render() {
        const { addDispositivo } = this.state

        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        return (
            <div>
                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.setState(this.state.aviso.args)}
                    open={this.state.aviso.show}
                />

                <Modal isOpen={addDispositivo.modal} toggle={this.toggleModal} className="modal-large">
                    <ModalHeader toggle={this.toggleModal}>
                        Gerenciar Dispositivos
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    {auth.getUserInfo()?.tipo !== "cliente"
                                        ?
                                        <>
                                            <div className="col-md-6">
                                                <label>Cliente</label>
                                                <Select
                                                    value={addDispositivo.filtros.cliente}
                                                    onChange={(e) => this.handleChangeCliente(e)}
                                                    options={this.state.clientes}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Monitorado</label>
                                                <Select
                                                    value={addDispositivo.filtros.monitorado}
                                                    onChange={(e) => this.handleChangeMonitorado(e)}
                                                    options={this.state.monitorados}
                                                />
                                            </div>
                                        </>
                                        :
                                        null
                                    }

                                    <div className="col-md-12">
                                        <label className="mt-2">Equipamentos</label>
                                        <ul id="multi-item-list" style={{ height: '300px' }}>
                                            {addDispositivo.filtrados.length > 0 ?
                                                addDispositivo.filtrados.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        {
                                                            index === 0
                                                                ?
                                                                <button key="button" onClick={this.selecionarTodos}>Selecionar todos</button>
                                                                :
                                                                null
                                                        }
                                                        <label>
                                                            <span>{item.label}</span>
                                                            <input
                                                                type="checkbox"
                                                                checked={addDispositivo.selecionados?.filter(i => i.id === item.value).length > 0}
                                                                onChange={(e) => this.handleDispositivos(e)}
                                                            />
                                                        </label>
                                                    </React.Fragment>
                                                )) :
                                                <span className="py-2 px-2">Sem equipamentos</span>}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 row">
                                <div className="col-md-12" id="multi-item-selected">
                                    <label>Selecionados</label>
                                    <span className={!this.state.validacao.dispositivos ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Dispositivo(s) inválido(s)</span>
                                    {
                                        this.state.addDispositivo.selecionados.map((dispositivo, index) => (
                                            <div className="hdv-multi-report-item" key={index}>
                                                <p>{dispositivo.id}</p>
                                                <div style={{ display: 'flex' }}>
                                                    <button
                                                        onClick={() => this.deletar(index)}
                                                        className="hdv-automacao-delete-button">
                                                        <i className="fa fa-times fa-2x"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <hr />

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.cancelar}>Cancelar</Button>
                        <Button color="success" onClick={this.adicionar}>Concluir</Button>
                    </ModalFooter>
                </Modal>


                {this.props.match.params.id ? <h4>Editar Perfil</h4> : <h4>Novo Perfil</h4>}
                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Detalhes do Perfil</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <label htmlFor="nome">Nome do Perfil</label>
                            <input type="text" name="nome" id="nome" value={this.state.detalhes.nome} onChange={this.handleChange} />
                            <span id="validacao-nome"></span>
                            {/*<label htmlFor="tipo">Administrador</label>
                            <input type="checkbox" name="tipo" id="tipo" checked={this.state.detalhes.tipo} onChange={this.handleChange} />*/}
                            <label htmlFor="">Selecionar Recursos</label>
                            <div className="row">
                                <div className="col-md-8">
                                    {this.create_group_permission()}
                                </div>
                                <div className="col-md-4">
                                    <div className="hdv-multi-report-dispositivos" id="perfil-usuario">
                                        <details className="mb-5 create-profile-heading" open>
                                            <summary className="d-flex align-items-center justify-content-between align-center">
                                                <span>Dispositivos</span>
                                                <button onClick={this.gerenciar} className="hdv-btn-forms hdv-btn-blue primary" style={{ background: '#0062cc' }}>Gerenciar</button>
                                            </summary>
                                            <section>
                                                {
                                                    this.state.dispositivos.length > 0
                                                        ?
                                                        this.state.dispositivos?.map((dispositivo, index) => (
                                                            <div className="hdv-multi-report-item py-2" key={index}>
                                                                <p>{dispositivo.id}</p>
                                                            </div>
                                                        ))
                                                        :
                                                        <span>Sem dispositivos selecionados</span>
                                                }
                                            </section>
                                        </details>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <br />


                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>Salvar</button>
                    <button onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-geenlight" disabled={this.state.cooldown}>Salvar e Cadastrar um Novo</button>
                    <Link to="/perfil/usuario">
                        <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>

            </div>
        );
    }
}

export default Form;