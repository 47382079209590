const helper_fn = {

    converte_volume_min: value => {
        return (value / 60).toFixed(2)
    },

    converte_fluxo_min: value => {
        if(value > 0){
            return (600 / value).toFixed(2)
        }else{
            return (0).toFixed(2)
        }
    },

    converte_decimal_to_hhmmss: value => {
        var decimalTimeString = value;
        var decimalTime = parseFloat(decimalTimeString);
        decimalTime = decimalTime * 60 * 60;
        var hours = Math.floor((decimalTime / (60 * 60)));
        decimalTime = decimalTime - (hours * 60 * 60);
        var minutes = Math.floor((decimalTime / 60));
        decimalTime = decimalTime - (minutes * 60);
        var seconds = Math.round(decimalTime);
        if(hours < 10)
        {
            hours = "0" + hours;
        }
        if(minutes < 10)
        {
            minutes = "0" + minutes;
        }
        if(seconds < 10)
        {
            seconds = "0" + seconds;
        }
        return ("" + hours + ":" + minutes + ":" + seconds);
    },

    validacao: {
        checa_validacao: obj => {
            let check = []
            obj.validar.forEach(item => {
                let resp = false
                switch (item.tipo) {
                    case 'min':
                        resp = this.min(item.validar)
                        if (resp === false){
                            let test = {}
                            test[item.campo] = "O valor mínino para o campo " + item.descricao + " é " + item.validar.value
                            check.push(test)
                        }
                        break;
                    case 'max':
                        resp = this.min(item.validar)
                        if (resp === false){
                            let test = {}
                            test[item.campo] = "O valor máximp para o campo " + item.descricao + " é " + item.validar.value
                            check.push(test)
                        }
                        break;
                    case 'required':
                        resp = this.min(item.validar)
                        if (resp === false){
                            let test = {}
                            test[item.campo] = "O campo " + item.descricao + " é obrigatório"
                            check.push(test)
                        }
                        break;
                    case 'max_length':
                        resp = this.min(item.validar)
                        if (resp === false){
                            let test = {}
                            test[item.campo] = "O campo " + item.descricao + " aceita apenas " + item.validar.value + " caracteres"
                            check.push(test)
                        }
                        break;
                    case 'email':
                        resp = this.min(item.validar)
                        if (resp === false){
                            let test = {}
                            test[item.campo] = "O campo " + item.descricao + " não é um formato de e-mail válido"
                            check.push(test)
                        }
                        break;
                    default:
                        break;
                }
            })
            return check
        },
        min: obj => {
            if (obj.value <= obj.min)
                return true
            return false
        },
        max: obj => {
            if (obj.value >= obj.max)
                return true
            return false
        },
        required: obj => {
            if (obj.value.trim() !== "")
                return true
            return false
        },
        max_length: obj => {
            if (obj.value.trim().length >= obj.max_length)
                return true
            return false
        },
        email: obj => {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(String(obj.value)))
                return true
            return false
        }
    },

    pegar_path_elemento_dom: el => {
        var stack = [];
        while ( el.parentNode != null ) {
            var sibCount = 0;
            var sibIndex = 0;
            for ( var i = 0; i < el.parentNode.childNodes.length; i++ ) {
                var sib = el.parentNode.childNodes[i];
                if ( sib.nodeName == el.nodeName ) {
                    if ( sib === el ) {
                    sibIndex = sibCount;
                    }
                    sibCount++;
                }
            }
            if ( el.hasAttribute('class') && el.className != '' ) {
                stack.unshift(el.nodeName.toLowerCase() + '.' + el.className);
            } else if ( el.hasAttribute('id') && el.id != '' ) {
                stack.unshift(el.nodeName.toLowerCase() + '#' + el.id);
            } else if ( sibCount > 1 ) {
                stack.unshift(el.nodeName.toLowerCase() + ':eq(' + sibIndex + ')');
            } else {
                stack.unshift(el.nodeName.toLowerCase());
            }
            el = el.parentNode;
        }

        return stack.slice(1);
    }

}

export default helper_fn;