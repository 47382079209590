import React, { Component } from 'react'
import Select from 'react-select'
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangePicker } from 'react-date-range'
import * as rdrLocales from 'react-date-range/dist/locale'

import axios from 'axios'
import auth from '../utils/auth'
import cfg from '../utils/config'
import helper from '../utils/helper'

class HeaderReport extends Component {

    state = {
        optionSelected: { "cliente": {}, "monitorado": {}, "item": {}, "agrupar": "" },
        datePickerShow: false,
        checkClose: false,
        tipos: [{ value: "entrada_analogica", label: "Entradas Analógicas" }, { value: "entrada_digital", label: "Entradas Digitais" }],
        dateRange: {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
        dataFormatada: "",
        detalhes: { data: "" },
        clientes: [],
        monitorados: [],
        itens: [],
    }

    checarCalendarioAberto = (e) => {
        let domPath = helper.pegar_path_elemento_dom(e.target)
        if (this.state.checkClose === true) {

            let retArray = domPath.filter((path) => {
                if (path.includes("hdv-datepicker")) {
                    return true
                }
                return false
            })

            if (retArray.length === 0) {
                this.setState({ "datePickerShow": false, checkClose: false })
            }

        } else this.setState({ checkClose: true })

        return true
    }

    componentDidMount = () => {
        window.addEventListener('click', this.checarCalendarioAberto, false)

        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/', config_me))

        axios.all(requestArray)
            .then(axios.spread(cliente => {
                let optionsCliente = []
                if (cliente.data.length > 0) {
                    cliente.data.forEach((c) => {
                        optionsCliente.push({ "value": c.id, "label": c.nome_fantasia })
                    })
                }
                this.setState({ "detalhes": this.props.DataChange, "clientes": optionsCliente, runitem_child: this.props.itemFn })
            }))
    }

    handleSelect = (ranges) => {
        let detalhes = this.state.detalhes
        detalhes.data = ranges.selection.startDate.toLocaleDateString() + "-" + ranges.selection.endDate.toLocaleDateString()
        this.setState({
            dateRange: {
                startDate: ranges.selection.startDate,
                endDate: ranges.selection.endDate,
                key: 'selection',
            },
            dataFormatada: ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString(),
            detalhes: detalhes
        })
    }

    showDatePicker = () => {
        this.setState({ "datePickerShow": !this.state.datePickerShow })
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption })
    }

    handleChange4a20Item = (selectedOption, field) => {
        var obj = this.state.detalhes
        obj[field] = selectedOption.value
        var optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        this.setState({ "optionSelected": optionObj, "detalhes": obj })
        if (typeof (this.props.fn) === "function") {
            this.props.fn()
        }
    }

    handleChangeAgrupar = (selectedOption) => {
        var obj = this.state.detalhes
        obj['agruparOption'] = selectedOption
        obj['agrupar'] = selectedOption.value
        var optionObj = this.state.optionSelected
        optionObj['agrupar'] = selectedOption
        this.setState({ "optionSelected": optionObj, "detalhes": obj })
    }

    handleChange4a20Cliente = (selectedOption, field) => {
        var obj = this.state.detalhes
        obj[field] = selectedOption.value
        var optionObj = this.state.optionSelected
        optionObj['monitorado'] = ''
        optionObj['item'] = ''
        optionObj[field] = selectedOption

        let userInfo = auth.getUserInfo()
        var bodyFormData = new FormData()

        bodyFormData.append('cliente_id', selectedOption.value)

        axios({
            method: 'post',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado/filtro/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(response => {
                let optionsMonitorado = []
                response.data.forEach((c) => {
                    optionsMonitorado.push({ "value": c.id, "label": c.nome })
                })
                this.setState({ "monitorados": optionsMonitorado, "optionSelected": optionObj, "detalhes": obj })
            })
            .catch(error => console.log(error))
    }

    handleChange4a20Monitorado = (selectedOption, field) => {
        let obj = this.state.detalhes
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj['item'] = ''
        optionObj[field] = selectedOption

        let userInfo = auth.getUserInfo()

        axios({
            method: 'get',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado-equipamento/' + selectedOption.value + '/',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                let optionsItem = []
                response.data.forEach((c) => {
                    optionsItem.push({ "value": c, "label": c })
                })
                this.setState({ "itens": optionsItem, "optionSelected": optionObj, "detalhes": obj })
            })
            .catch((error) => console.log(error))
    }

    manipularData = (sentido) => {
        if (!this.state.detalhes.data)
            return

        let diffTime = Math.abs(this.state.dateRange.endDate.getTime() - this.state.dateRange.startDate.getTime())
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

        diffDays = (diffDays === 0) ? 1 : diffDays
        diffDays = (sentido === "avancar") ? diffDays : -Math.abs(diffDays)

        let newStartDate = new Date(this.state.dateRange.startDate.setDate(this.state.dateRange.startDate.getDate() + diffDays))
        let newEndDate = new Date(this.state.dateRange.endDate.setDate(this.state.dateRange.endDate.getDate() + diffDays))

        let detalhes = this.state.detalhes
        detalhes.data = newStartDate.toLocaleDateString() + "-" + newEndDate.toLocaleDateString()

        this.setState({
            dateRange: {
                startDate: newStartDate,
                endDate: newEndDate,
                key: 'selection',
            },
            dataFormatada: newStartDate.toLocaleDateString() + " - " + newEndDate.toLocaleDateString(),
            detalhes: detalhes
        })

    }

    render() {
        const { optionSelected } = this.state
        const { DataChange, altForm } = this.props

        const clienteOption = Object.keys(optionSelected.cliente).length ? optionSelected.cliente : DataChange.cliente;
        const monitoradoOption = Object.keys(optionSelected.monitorado).length ? optionSelected.monitorado : DataChange.monitorado;
        const itemOption = Object.keys(optionSelected.item).length ? optionSelected.item : DataChange.item;
        const agruparOption = Object.keys(optionSelected.agrupar).length ? optionSelected.agrupar : DataChange.agrupar;

        return (
            <div className="col-md-12" >
                {altForm ?
                    <div className="mt-3">
                        <div className="row">
                            <div className="col-md-5" >
                                <label>Periodo</label>
                                <InputGroup className="hdv-force-hight">
                                    <InputGroupAddon addonType="prepend">
                                        <Button onClick={() => this.manipularData("voltar")} color="info">
                                            <i className="fa fa-angle-double-left"></i>
                                        </Button>
                                    </InputGroupAddon>
                                    <Input className="hdv-force-hight" placeholder="Selecione o periodo no botão 'Exibir Calendário' " onChange={() => false} disabled value={this.state.dataFormatada} />
                                    <InputGroupAddon addonType="append">
                                        <Button onClick={this.showDatePicker} color={(this.state.datePickerShow) ? "danger" : "primary"}>
                                            <i className={(this.state.datePickerShow) ? "fa fa-calendar-times-o" : "fa fa-calendar-check-o"}></i>
                                            {(this.state.datePickerShow) ? "Fechar Calendário" : "Exibir Calendário"}
                                        </Button>
                                    </InputGroupAddon>
                                    <InputGroupAddon addonType="append">
                                        <Button onClick={() => this.manipularData("avancar")} color="info">
                                            <i className="fa fa-angle-double-right"></i>
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                                <span className={(this.props.validacao.data === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                                <div className={(this.state.datePickerShow) ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                                    <DateRangePicker
                                        locale={rdrLocales.pt}
                                        ranges={[this.state.dateRange]}
                                        onChange={this.handleSelect}
                                    />
                                </div>
                            </div>
                            <div className="col-md-1"></div>

                            <div className="col-md-3 mx-0">
                                <label>Cliente</label>
                                <Select
                                    value={clienteOption}
                                    onChange={(e) => this.handleChange4a20Cliente(e, 'cliente')}
                                    options={this.state.clientes}
                                    isDisabled={this.props.cliente}
                                />
                                <span className={(this.props.validacao.cliente === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                            </div>

                            <div className="col-md-3">
                                <label>Monitorado</label>
                                <Select
                                    value={monitoradoOption}
                                    onChange={(e) => this.handleChange4a20Monitorado(e, 'monitorado')}
                                    options={this.state.monitorados}
                                />
                                <span className={(this.props.validacao.monitorado === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ marginTop: '7px' }}>
                                <label>Equipamento</label>
                                <Select
                                    value={itemOption}
                                    onChange={(e) => this.handleChange4a20Item(e, 'item')}
                                    options={this.state.itens}
                                />
                                <span className={(this.props.validacao.item === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                            </div>

                            <div className="col-md-3" style={{ marginTop: '7px' }}>
                                <label>Agrupar por</label>
                                <Select
                                    value={agruparOption}
                                    placeholder=""
                                    onChange={(e) => this.handleChangeAgrupar(e)}
                                    options={[
                                        { label: 'Hora', value: 'hour' },
                                        { label: 'Dia', value: 'day' },
                                        { label: 'Mês', value: 'month' }
                                    ]}
                                />
                                <span className={(this.props.validacao.agrupar === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                            </div>
                        </div>
                    </div>
                    :
                    DataChange.manter_equipamento ?
                        <div className="mt-3">
                            <div className="row">
                                <div className="col-md-4 mx-0">
                                    <label>Cliente</label>
                                    <Select
                                        value={clienteOption}
                                        onChange={(e) => this.handleChange4a20Cliente(e, 'cliente')}
                                        options={this.state.clientes}
                                    />
                                    <span className={(this.props.validacao.cliente === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                                </div>
                                <div className="col-md-4">
                                    <label>Monitorado</label>
                                    <Select
                                        value={monitoradoOption}
                                        onChange={(e) => this.handleChange4a20Monitorado(e, 'monitorado')}
                                        options={this.state.monitorados}
                                    />
                                    <span className={(this.props.validacao.monitorado === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                                </div>

                                <div className="col-md-4" >
                                    <label>Equipamento</label>
                                    <Select
                                        value={itemOption}
                                        onChange={(e) => this.handleChange4a20Item(e, 'item')}
                                        options={this.state.itens}
                                    />
                                    <span className={(this.props.validacao.item === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                                </div>
                            </div>
                        </div>
                        :
                        null}
            </div>
        )
    }
}

export default HeaderReport