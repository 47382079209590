import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom'
import "react-tabs/style/react-tabs.css";
import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import Aviso from '../../components/Aviso'

class Form extends Component {

    state = {
        detalhes: { "nome": "" },
        id_editando_contato: false,
        id_editando_endereco: false,
        formerrors: [],
        redirect: false,
        path: "",
        aviso: { show: false, args: null, message: '' },
        cooldown: false,
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }
    }

    getData = () => {
        let resource = "operadora"
        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        axios.get(requestURL, config_me)
            .then((response) => {
                delete response.data.id
                this.setState({ detalhes: response.data });
            })
    }

    handleChange = (e) => {
        let obj = this.state.detalhes
        obj[e.target.id] = e.target.value
        document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""
        this.setState({ "detalhes": obj })
    }

    saveAction = (novo_cadastro) => {
        this.setState({ cooldown: true })
        let userInfo = auth.getUserInfo();
        let resource = "operadora"
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/';
            verb = 'post'
        }
        let bodyFormData = new FormData();

        for (let key in this.state.detalhes) {
            bodyFormData.append(key, this.state.detalhes[key]);
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (novo_cadastro === true) {
                    if (this.props.match.params.id) {
                        const aviso = { show: true, message: `Operadora ${verb === 'patch' ? 'editada' : 'criada'} com sucesso.`, args: { redirect: true, path: '/base/operadora' } }
                        this.setState({ aviso })

                    } else
                        window.location.reload()

                } else {
                    const aviso = { show: true, message: `Operadora ${verb === 'patch' ? 'editada' : 'criada'} com sucesso.`, args: { redirect: true, path: '/base/operadora' } }
                    this.setState({ aviso })
                }
            })
            .catch((error) => {
                console.log(error)
                let erros = error.response.data
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span)
                            span.innerHTML = erros[i][1]
                    }
                }
            })
        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    render() {
        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        return (
            <div>
                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.setState(this.state.aviso.args)}
                    open={this.state.aviso.show}
                />
                {this.props.match.params.id ? <h4>Editar Operadora</h4> : <h4>Nova Operadora</h4>}

                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Detalhes da Operadora</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <label htmlFor="nome">Nome da Operadora</label>
                            <input type="text" name="nome" id="nome" value={this.state.detalhes.nome} onChange={this.handleChange} />
                            <span id="validacao-nome"></span>
                        </form>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>Salvar</button>
                    <button onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-geenlight" disabled={this.state.cooldown}>Salvar e Cadastrar um Novo</button>
                    <Link to="/base/operadora">
                        <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Form;