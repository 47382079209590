
import React from 'react'
import axios from 'axios'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import { Link, Redirect } from 'react-router-dom'
import { compile, isNaN } from 'mathjs'
import CampoCustomizado from '../../components/CampoCustomizado'


export default class Form extends React.Component {
    state = {
        detalhes: { data: "" },
        optionSelected: { "cliente": null, "monitorado": null, "item": null },
        validacao: { cliente: true, monitorado: true, item: true },
        clientes: [],
        monitorados: [],
        itens: [],
        data: null,
        validacao_campo: { descricao: true, identificador: true, formula: true, decimal: true, operador_especial: true, prioridade: true },
        campoCustomizado: { descricao: '', identificador: '', formula: '', unidade: '', decimal: '2', operador_especial: 'padrao', prioridade: '1' },
        teste: { resultado: '0', invalido: false, erro: '', status: false },
        redirect: { to: '', status: false }
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            let userInfo = auth.getUserInfo()

            axios({
                method: 'GET',
                url: `${cfg.base_api_url + cfg.api_version}/${userInfo.empresa}/campo-customizado/${this.props.match.params.id}/`,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then(res => {
                    const { campoCustomizado } = this.state
                    campoCustomizado['formula'] = res.data.formula
                    campoCustomizado['descricao'] = res.data.descricao
                    campoCustomizado['identificador'] = res.data.identificacao
                    campoCustomizado['unidade'] = res.data.unidade
                    campoCustomizado['decimal'] = res.data.decimal
                    campoCustomizado['operador_especial'] = res.data.operador_especial
                    campoCustomizado['prioridade'] = res.data.prioridade

                    this.setState({ campoCustomizado })
                })
                .catch(error => console.log(error))

        }
        this.buscarDados()
    }

    buscarDados = () => {
        this.setState({ carregandoDados: true })
        let userInfo = auth.getUserInfo()

        let bodyFormData = new FormData()
        bodyFormData.append('id_dispositivo', this.props.match.params.imei)

        axios({
            method: 'POST',
            url: `${cfg.base_api_url + cfg.api_version}/${userInfo.empresa}/reporte-ultimo-evento-calculado/`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(res => this.setState({ data: res.data }))
            .catch(error => console.log(error))
        this.setState({ carregandoDados: false })
    }

    handleChange4a20Cliente = (selectedOption, field) => {
        let obj = this.state.detalhes
        let validacao = this.state.validacao
        validacao[field] = true
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        let userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()

        bodyFormData.append('setor__aplicacao__cliente_id', selectedOption.value)

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado/filtro/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(response => {
                let optionsMonitorado = []
                response.data.forEach((c) => {
                    optionsMonitorado.push({ "value": c.id, "label": c.nome })
                })
                this.setState({ "monitorados": optionsMonitorado, "optionSelected": optionObj, "detalhes": obj })
            })
            .catch(error => console.log(error))
    }

    handleChange = (e) => {
        let { campoCustomizado, teste } = this.state
        campoCustomizado[e.target.name] = e.target.value
        if (e.target.name === 'descricao') {
            let identificador = String(e.target.value).toLowerCase().replace(/\s/g, '_')
            identificador = identificador.replace(/[^\w\s]/gi, '')
            if (/^[0-9].+$/.test(identificador))
                identificador = identificador.substring(1)

            campoCustomizado['identificador'] = identificador
        }
        if (e.target.name === 'formula') {
            teste['status'] = false
            this.setState({ teste })
        }
        this.setState({ campoCustomizado })
    }

    handleChange4a20Monitorado = (selectedOption, field) => {
        let obj = this.state.detalhes
        let validacao = this.state.validacao
        validacao[field] = true
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        let userInfo = auth.getUserInfo()

        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado-equipamento/' + selectedOption.value + '/',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                let optionsItem = []
                response.data.forEach((c) => {
                    optionsItem.push({ "value": c, "label": c })
                })
                this.setState({ "itens": optionsItem, "optionSelected": optionObj, "detalhes": obj })
            })
            .catch((error) => console.log(error))
    }

    handleChange4a20Item = (selectedOption, field) => {
        let obj = this.state.detalhes
        let validacao = this.state.validacao
        obj[field] = selectedOption.value
        validacao[field] = true
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        this.setState({ "optionSelected": optionObj, "detalhes": obj })
    }

    handleChangeCampo = (selectedOption, field) => {
        let { campos } = this.state
        campos[field] = selectedOption
        this.setState({ campos })
    }

    saveAction() {
        const { campoCustomizado, validacao_campo } = this.state

        let valid = true
        const keys = Object.keys(validacao_campo)
        keys.map(key => {
            if (!campoCustomizado[key]) {
                validacao_campo[key] = false
                valid = false
            }
            return null
        })

        this.setState({ validacao_campo })

        if (!valid) return

        let verb
        let url
        let userInfo = auth.getUserInfo()
        if (this.props.match.params.id) {
            verb = this.props.match.params.id ? "PATCH" : "POST"
            url = `${cfg.base_api_url + cfg.api_version}/${userInfo.empresa}/campo-customizado/${this.props.match.params.id}/`
        } else {
            verb = "POST"
            url = `${cfg.base_api_url + cfg.api_version}/${userInfo.empresa}/campo-customizado/`
        }

        let bodyFormData = new FormData()

        bodyFormData.append('equipamento', this.props.match.params.imei)
        bodyFormData.append('descricao', campoCustomizado.descricao)
        bodyFormData.append('identificacao', campoCustomizado.identificador)
        bodyFormData.append('formula', campoCustomizado.formula)
        bodyFormData.append('unidade', campoCustomizado.unidade)
        bodyFormData.append('decimal', campoCustomizado.decimal)
        bodyFormData.append('operador_especial', campoCustomizado.operador_especial)
        bodyFormData.append('prioridade', campoCustomizado.prioridade)


        axios({
            method: verb,
            url: url,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(response => {
                const { redirect } = this.state
                redirect['status'] = true
                redirect['to'] = `/equipamento/${this.props.match.params.imei}/status/`
                this.setState({ redirect })
            })
            .catch(error => console.log(error))
    }

    adicionarNaFormula = (variavel) => {
        const { campoCustomizado } = this.state
        campoCustomizado['formula'] += ` ${variavel}`
        this.setState({ campoCustomizado })
    }

    testar = () => {
        const { campoCustomizado, data, teste } = this.state
        teste['resultado'] = ''
        teste['invalido'] = false
        teste['status'] = false
        teste['erro'] = ''
        this.setState({ teste })

        let formula = campoCustomizado['formula']
        formula = formula.replace(/,/g, '.')
        if (formula.length === 0) return

        try {
            let variaveis = Array.from(formula.matchAll(/\[(.*?)\]/g)).map(v => {
                let split = v[1].split('_')
                let ultimo = split[split.length - 1]
                let value = null

                if (/^\d+$/.test(ultimo)) {
                    split.pop()
                    let index = split.join('_')
                    value = data[index][ultimo - 1]
                }
                else
                    value = data[v[1]]

                return { id: `[${v[1]}]`, value: Number(value) }
            })

            variaveis.map(v => formula = formula.replace(v.id, v.value.toString()))

            const code = compile(formula)
            const resultado = code.evaluate()
            if (isNaN(resultado) || resultado === Infinity) {
                teste['invalido'] = true
                teste['erro'] = 'Operação Inválida!'
            }

            else {
                teste['resultado'] = resultado
                teste['invalido'] = false
                teste['status'] = true
            }

            this.setState({ teste })
        } catch (e) {
            teste['invalido'] = true
            teste['status'] = false
            teste['erro'] = 'Operação Inválida!'
            this.setState({ teste })
        }
    }

    render() {
        const { data, redirect, validacao_campo } = this.state

        if (redirect.status) return <Redirect to={redirect.to} />
        console.log(this.state)
        return (
            <>
                {this.props.match.params.id ? <h4>Editar Campo Customizado</h4> : <h4>Novo Campo Customizado</h4>}

                <div className="row">
                    <div className="col-md-8">
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="required" htmlFor="descricao">Descrição do parâmetro</label>
                                    <input
                                        type="text"
                                        name="descricao"
                                        value={this.state.campoCustomizado.descricao}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                    <span id="validacao-descricao" className={!validacao_campo.descricao ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                </div>
                                <div className="col-md-6">
                                    <label className="required" htmlFor="identificador">Identificador</label>
                                    <input
                                        type="text"
                                        name="identificador"
                                        value={this.state.campoCustomizado.identificador}
                                        readOnly={true}
                                    />
                                    <span id="validacao-identificador" className={!validacao_campo.identificador ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                    {this.state.campoCustomizado.invalido ? <span id="validacao-identificador">Identificador inválido!</span> : null}
                                </div>
                            </div>
                            <label className="required" htmlFor="formula">Fórmula</label>
                            <input
                                type="text"
                                name="formula"
                                value={this.state.campoCustomizado.formula}
                                placeholder="Exemplo: [ct_pulso_1] * 2"
                                onChange={(e) => this.handleChange(e)}
                            />
                            <span id="validacao-formula" className={!validacao_campo.formula ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                            <div className="row">
                                <div className="col-md-9">
                                    <label className="required" htmlFor="unidade">Unidade</label>
                                    <input
                                        type="text"
                                        name="unidade"
                                        value={this.state.campoCustomizado.unidade}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <label className="required" htmlFor="unidade">Decimal</label>
                                    <input
                                        type="number"
                                        name="decimal"
                                        value={this.state.campoCustomizado.decimal}
                                        onKeyPress={(e) => e.preventDefault()}
                                        onChange={(e) => this.handleChange(e)}
                                        max="6"
                                        min="2"
                                    />
                                    <span id="validacao-formula" className={!validacao_campo.decimal ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-9">

                                    <label className="required" htmlFor="operador_especial">Operador Especial</label>
                                    <select value={this.state.campoCustomizado.operador_especial} onChange={(e) => this.handleChange(e)} name="operador_especial" id="operador_especial">
                                        <option value="padrao">Padrão</option>
                                        <option value="soma">Incremental</option>
                                    </select>
                                    <span id="validacao-formula" className={!validacao_campo.operador_especial ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                </div>
                                <div className="col-md-3">
                                    <label className="required" htmlFor="unidade">Prioridade</label>
                                    <input
                                        type="number"
                                        name="prioridade"
                                        value={this.state.campoCustomizado.prioridade}
                                        onKeyPress={(e) => e.preventDefault()}
                                        onChange={(e) => this.handleChange(e)}
                                        max="99"
                                        min="1"
                                    />
                                    <span id="validacao-formula" className={!validacao_campo.prioridade ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                </div>
                            </div>

                        </form>
                        <div style={{ display: 'flex', margin: '25px 0' }}>
                            <button
                                onClick={this.testar}
                                className="hdv-btn-forms hdv-btn-blue">
                                Testar fórmula
                            </button>
                            {this.state.teste.invalido ?
                                <span style={{ margin: '0 20px' }} id="validacao-formula">{this.state.teste.erro}</span>
                                :
                                <span style={{ margin: '0 20px', fontSize: '18px' }}><b>{parseFloat(this.state.teste.resultado).toFixed(parseInt(this.state.campoCustomizado.decimal))} {this.state.campoCustomizado.unidade}</b></span>
                            }
                        </div>
                    </div>
                    <div className="col-md-4 campo-customizado">
                        <h5>Campos</h5>
                        <small>Com base no último reporte</small>
                        <CampoCustomizado
                            data={data}
                            funcao={(variavel) => this.adicionarNaFormula(variavel)}
                            bracket={true}
                        />
                    </div>

                </div>

                <div className="hdv-btn-group">
                    {(this.props.match.params.id) ? "" : <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-geen" disabled={!this.state.teste.status}>Salvar e continuar</button>}
                    {(this.props.match.params.id) ? <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-geen" disabled={!this.state.teste.status}>Salvar</button> : ""}
                    <Link to={`/equipamento/${this.props.match.params.imei}/status/`}>
                        <button className="hdv-btn-forms hdv-btn-yellow ">Cancelar</button>
                    </Link>
                </div>
            </>
        )
    }
}