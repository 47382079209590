import React, { Component } from 'react'
import HeaderMultiReport from '../../components/HeaderMultiReport'
import ReactLoading from 'react-loading'
import CsvDownloader from 'react-csv-downloader'
import axios from 'axios'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import { Link, Redirect } from 'react-router-dom'
import Aviso from '../../components/Aviso'
import ReactEcharts from "echarts-for-react"
import domToImage from 'dom-to-image'
import pdfMake from 'pdfmake'
import Select from 'react-select'
import helper_fn from '../../utils/helper'
import CurrencyInput from 'react-currency-input-field'

pdfMake.fonts = {
   Roboto: {
      normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
   }
}

const BRAZILIANREAL = new Intl.NumberFormat('pt-BR', {
   style: 'currency',
   currency: 'BRL',
});

class Form extends Component {
   userInfo = auth.getUserInfo()
   config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
   baseUrl = `${cfg.base_api_url + cfg.api_version}`

   state = {
      selectedOption: null,
      detalhesDefault: {
         descricao: "",
         unidade: { label: 'm3', value: 'm3' },
         data: "",
         modal: false,
         agrupar: "",
         cliente: "",
         disponivel_cliente_final: false,
         dispositivos: [],
         valor_unidade: '0',
      },
      hidrometrosOption: [],
      configuracaoSelecionada: null,
      validacaoFormulario: { data: true, agrupar: true, descricao: true, dispositivos: true },
      aviso: { show: false, args: null, message: '' },
      carregando: false,
      dataCsv: [],
      relatorioGerado: false,
      chart: null,
      table: null,
      csv: { data: [], columns: [] },
   }

   componentDidMount() {
      if (this.props.match.params.id) {
         this.getData()
      }
   }

   getData() {
      axios({
         method: 'GET',
         url: this.baseUrl + '/' + this.userInfo.empresa + `/relatorio/consumo/${this.props.match.params.id}`,
         headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
      })
         .then(res => {
            if (res.data?.equipamento) {
               let dispositivos = []
               let { detalhesDefault } = this.state

               JSON.parse(res.data.equipamento)?.map(equipamento => {
                  dispositivos.push({
                     id: equipamento.id,
                     campo: equipamento.field,
                     posicao: equipamento.position !== false ? equipamento.position : false
                  })
               })

               detalhesDefault['descricao'] = res.data.descricao
               detalhesDefault['dispositivos'] = dispositivos

               if (Boolean(res.data.cliente)) {
                  detalhesDefault['disponivel_cliente_final'] = true
                  detalhesDefault['cliente'] = { label: "", value: res.data.cliente }
               }

               this.setState({ detalhesDefault })
            }
         })
   }

   saveAction = () => {
      let { detalhesDefault } = this.state
      let notValid = false
      let validateForm = this.state.validacaoFormulario
      let dispositivos = []
      let verb, url

      const except = ['modal', 'data', 'agrupar', 'dispositivos', 'unidade', 'disponivel_cliente_final', 'cliente', 'valor_unidade']
      for (let key in detalhesDefault) {
         if (!detalhesDefault[key] && !except.includes(key)) {
            validateForm[key] = false
            notValid = true
         } else validateForm[key] = true
      }

      if (notValid === true) {
         this.setState({ validacaoFormulario: validateForm })
         return
      }

      this.setState({ cooldown: true })

      detalhesDefault.dispositivos.map(dispositivo => {
         dispositivos.push({
            id: dispositivo.id,
            field: dispositivo.campo,
            position: dispositivo.posicao ? Number(dispositivo.posicao) : false
         })
      })

      const bodyFormData = new FormData()
      bodyFormData.append('descricao', detalhesDefault.descricao)
      bodyFormData.append('equipamento', JSON.stringify(dispositivos))

      if (detalhesDefault.cliente) {
         bodyFormData.append('cliente_id', detalhesDefault.cliente.value)
      }

      if (this.props.match.params.id) {
         verb = 'PATCH'
         url = this.baseUrl + '/' + this.userInfo?.empresa + `/relatorio/consumo/${this.props.match.params.id}/`
      } else {
         verb = 'POST'
         url = this.baseUrl + '/' + this.userInfo?.empresa + `/relatorio/consumo/`
      }

      axios({
         method: verb,
         url: url,
         data: bodyFormData,
         headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
      }).then(res => {
         const aviso = { show: true, message: `Relatorio salvo com sucesso`, args: { redirect: true, path: "/relatorio-consumo" } }
         this.setState({ aviso, relatorioGerado: false, chart: null, table: null })
      }).catch((error) => console.log(error))

      setTimeout(() => this.setState({ cooldown: false }), 2000)
   }

   buscarDadosRelatorio = async () => {
      const { detalhesDefault } = this.state
      let dispositivos = []
      let notValid = false
      let validateForm = this.state.validacaoFormulario
      let chart = { series: [], xAxis: [] }

      this.setState({ carregando: true, relatorioGerado: false, chart: null, table: null })

      const except = ['modal', 'descricao', 'dispositivos', 'unidade', 'disponivel_cliente_final', 'cliente', 'valor_unidade']
      for (let key in detalhesDefault) {
         if (!detalhesDefault[key] && !except.includes(key)) {
            validateForm[key] = false
            notValid = true
         } else validateForm[key] = true
      }
      if (notValid === true) {
         this.setState({ validacaoFormulario: validateForm, carregando: false })
         return
      }

      if (detalhesDefault.dispositivos.length > 0) {
         detalhesDefault.dispositivos.map(dispositivo => {
            dispositivos.push({
               id: dispositivo.id,
               field: dispositivo.campo,
               position: dispositivo.posicao ? Number(dispositivo.posicao) : false
            })
         })

         await axios({
            method: 'POST',
            url: this.baseUrl + '/' + this.userInfo?.empresa + '/reporte/consumo/',
            data: {
               "timestamp_dispositivo_ini": detalhesDefault.data?.split('-')[0].split('/').reverse().join('-') + ' 00:00:00',
               "timestamp_dispositivo_fim": detalhesDefault.data?.split('-')[1].split('/').reverse().join('-') + ' 23:59:59',
               "grupo": detalhesDefault.agrupar,
               "dispositivos": dispositivos
            },
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
         })
            .then(res => {
               console.log(res.data)
               let data = res.data
               let chartData = []
               let csv = { data: [], columns: [] }
               let total = res.data?.data?.TOTAL
               let table = { total: total, itens: [] }
               const keys = []

               Object.keys(data.config.RANGE).map(key => keys.push({ key, ...data.config.RANGE[key] }))
               keys.sort((a, b) => {
                  return new Date(a.MYTIMEZONE.end).getTime() - new Date(b.MYTIMEZONE.end).getTime()
               })

               keys.map(key => chart.xAxis.push(Number(key.key)))
               chart.xAxis.map((key, index) => {
                  if (data.data.TOTAL_GROUP[key]) {
                     chartData.push(data.data.TOTAL_GROUP[key].diff)
                  } else {
                     chartData.push(0)
                  }
               })

               if (res.data.data.SENSOR) {
                  for (let key in res.data.data.SENSOR) {
                     let keys = Object.keys(res.data.data.SENSOR[key].value)

                     let value = Number(res.data.data.SENSOR[key].total).toFixed(2)
                     let start = res.data.data.SENSOR[key].value[keys[0]].ini
                     let end = res.data.data.SENSOR[key].value[keys[keys.length - 1]].end
                     let monitorado = res.data.data.SENSOR[key]?.place ?? ""
                     let split = key.split("_")
                     const id = split[0]
                     split.shift()
                     
                     table.itens.push({
                        id: id,
                        place: monitorado,
                        label: id,
                        campo: `${split.join("_")}`,
                        consumo: value,
                        valor_inicial: parseFloat(start).toFixed(2),
                        valor_final: parseFloat(end).toFixed(2),
                        porcentagem: (Number(value) !== 0 ? (value / total) * 100 : 0).toFixed(2)
                     })

                     csv.data.push({ c0: monitorado, c1: key, c2: String(parseFloat(start).toFixed(2)).replace('.', ','), c3: String(parseFloat(end).toFixed(2)).replace('.', ','), c4: String(value).replace('.', ','), c5: BRAZILIANREAL.format(Number(Number(value).toFixed(2)) * parseFloat(detalhesDefault.valor_unidade.replace('.', '').replace(',', '.'))) })
                  }
               }
               //csv.data.push({ c3: 'Total', c4: total })
               chart.series.push({ type: 'line', data: chartData })

               csv.columns = [
                  { id: 'c0', displayName: 'Monitorado' },
                  { id: 'c1', displayName: 'Dispositivo' },
                  { id: 'c2', displayName: 'Leitura Inicial' },
                  { id: 'c3', displayName: 'Leitura Final' },
                  { id: 'c4', displayName: 'Consumo' },
                  { id: 'c5', displayName: 'Valor' }
               ]

               this.setState({ relatorioGerado: true, carregando: false, chart: chart, table: table, csv: csv })
            })
            .catch(err => console.log(err))
      }
      this.setState({ carregando: false })
   }

   formatTooltip = (params) => {
      const { detalhesDefault } = this.state
      if (Array.isArray(params)) {
         let formatted = ""
         
         for (let i = 0; i < params.length; i++) {
            formatted += `<div style="display: flex; align-items: center;"><div style="background-color: ${params?.[i]?.color}; height: 14px; width: 14px; border-radius: 7px; margin-right: 5px"></div>`
            //formatted += `${params[i]?.seriesName}:&nbsp; <b>${Number(Number(params[i].data).toFixed(2)).toLocaleString("pt-BR")}</b>`
            formatted += `${params[i]?.seriesName}:&nbsp; <b>${(detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(params[i].data).toFixed(2))) : Number(Number(params[i].data).toFixed(2)).toLocaleString("pt-BR")}</b>`
            formatted += (i !== (params.length - 1)) ? "</div>\n" : "</div>"
         }

         return formatted
      }
      return ""
   }

   convertDecimalHoursToHourMinuteSecond = (value) => {
      let decimalTimeString = value
      let decimalTime = parseFloat(decimalTimeString)
      decimalTime = decimalTime * 60 * 60
      let hours = Math.floor((decimalTime / (60 * 60)))
      decimalTime = decimalTime - (hours * 60 * 60)
      let minutes = Math.floor((decimalTime / 60))
      decimalTime = decimalTime - (minutes * 60)
      let seconds = Math.round(decimalTime)
      if (hours < 10)
         hours = "0" + hours

      if (minutes < 10)
         minutes = "0" + minutes

      if (seconds < 10)
         seconds = "0" + seconds

      return ("" + hours + ":" + minutes + ":" + seconds)
   }

   handleChange = (key, value) => {
      let { detalhesDefault } = this.state
      detalhesDefault[key] = value

      if (key == 'disponivel_cliente_final' && value == true) {
         detalhesDefault['dispositivos'] = []
      }

      this.setState({ detalhesDefault: detalhesDefault })
   }

   deletar = (index) => {
      let { detalhesDefault } = this.state
      detalhesDefault['dispositivos'].splice(index, 1)
      this.setState({ detalhesDefault })
   }

   adicionar = (itens) => {
      let { detalhesDefault, validacaoFormulario } = this.state
      let bodyFormData = new FormData()
      let dispositivos = []
      let dispositivosOk = true
      validacaoFormulario['dispositivos'] = true

      for (let dispositivo of itens) {
         if (!dispositivo.campo) {
            dispositivosOk = false
            break
         } else {
            dispositivos.push({
               id: dispositivo.id,
               field: dispositivo.campo,
               value: dispositivo.value,
               position: dispositivo.posicao ? Number(dispositivo.posicao) : false
            })
         }
      }

      if (dispositivosOk) {
         bodyFormData.append('equipamento', JSON.stringify(dispositivos))

         if (this.props.match.params.id) {
            axios({
               method: 'PATCH',
               url: this.baseUrl + '/' + this.userInfo.empresa + `/relatorio/consumo/${this.props.match.params.id}/`,
               data: bodyFormData,
               headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
               .then(res => {
                  detalhesDefault['dispositivos'] = itens
                  this.setState({ detalhesDefault, relatorioGerado: false, chart: null, table: null })
                  this.toggleModal()
               })
               .catch((error) => console.log(error))
         } else {
            detalhesDefault['dispositivos'] = itens
            this.setState({ detalhesDefault })
            this.toggleModal()
         }
      } else {
         validacaoFormulario['dispositivos'] = false
         this.setState({ validacaoFormulario })
      }
   }

   toggleAviso = () => {
      const { aviso } = this.state
      aviso['show'] = !aviso['show']
      this.setState({ aviso })
   }

   toggleModal = () => {
      let { detalhesDefault, validacaoFormulario } = this.state
      for (let key in validacaoFormulario) {
         validacaoFormulario[key] = true
      }

      detalhesDefault['modal'] = false
      this.setState({ validacaoFormulario, detalhesDefault })
   }

   gerarGrafico = () => {
      const { chart, detalhesDefault } = this.state
      const axisType = { day: 'Dias', month: 'Meses', hour: 'Horas', irregular: 'Período Específico', fixo: 'Período Fixo' }
      const labelDesc = { day: '(Dia)', month: '(Mês)', hour: '(Hora)', irregular: '(Período Específico)', fixo: '(Período Fixo)' }
      let series = []

      series = chart?.series?.map((serie, index) => {
         if (serie.data.length > 0) {
            let label = {
               show: true,
               position: 'top',
               color: "rgba(0, 0, 0, 0.85)",
               fontWeight: '600',
               textBorderColor: '#fff',
               textBorderWidth: 2,
               distance: 5,
               fontSize: 12,
               rotate: chart.xAxis.length > 20 ? 55 : 0,
               formatter: (params) => !isNaN(params.value) ? (detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(params.data).toFixed(2))) : Number(Number(params.data).toFixed(2)).toLocaleString("pt-BR") : (detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(params.value).toFixed(2))) : Number(Number(params.value).toFixed(2)).toLocaleString("pt-BR")
            }

            return {
               name: `Total ${labelDesc[detalhesDefault.agrupar]}`,
               data: serie.data,
               type: serie.type,
               itemStyle: { color: '#4287f5', opacity: 1 },
               lineStyle: { width: 2 },
               labelLayout: { hideOverlap: true },
               label: label,
               areaStyle: { opacity: 0.5 }
            }
         }
         return null
      }).filter(s => s !== null)

      return (
         <div className="chart-item" style={{ padding: '3px' }}>
            <h5 style={{ textAlign: 'center' }}>Relatório de Consumo</h5>

            <p style={{ opacity: '0.85', margin: '12px 0 0 0' }}>Relatório: {detalhesDefault?.descricao}</p>
            <p style={{ opacity: '0.85', margin: '0' }}>Período: {detalhesDefault.data?.replace('-', ' à ')}</p>

            <div className="d-flex justify-content-between mt-2">
               <strong style={{ fontSize: '20px' }}>Consumo Total</strong>
               <span style={{ fontSize: '20px', opacity: '0.9', fontWeight: 'bold' }}>
                  {(detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(this.state.table.total).toFixed(2))) : Number(Number(this.state.table.total).toFixed(2)).toLocaleString("pt-BR")}
                  {detalhesDefault.unidade?.value ? ` (${detalhesDefault.unidade?.value})` : null}
               </span>
            </div>
            <div className="hdv-chart">
               {series?.length > 0 ?
                  <ReactEcharts
                     style={{ height: '360px', width: '100%' }}
                     option={{
                        series: series,
                        xAxis: {
                           data: chart?.xAxis,
                           name: axisType[detalhesDefault.agrupar],
                           nameTextStyle: { fontWeight: '600', fontSize: '12' },
                           nameLocation: 'center',
                           nameGap: 22
                        },
                        yAxis: { type: 'value' },
                        grid: { left: 60, right: 20, bottom: 60 },
                        tooltip: {
                           show: true,
                           trigger: "axis",
                           axisPointer: { type: "shadow", label: { show: true } },
                           formatter: (params) => this.formatTooltip(params)
                        }
                     }} />
                  : <p style={{ margin: '12px 0', textAlign: 'center', color: '#a33131', fontWeight: 'bold' }}>Erro ao carregar gráfico</p>}
            </div>
         </div>
      )
   }

   /*exportarPDF = async () => {
      let infoEmpresa
      await axios.get(this.baseUrl + `/${this.userInfo.empresa}/configuracao-sistema`, this.config_me)
         .then(res => { infoEmpresa = res.data?.data })

      fetch((await domToImage.toPng(document.querySelector('section#hdv-multi-report-print')))).then(async (data) => {
         const pdfOptions = {
            content: [{
               table: {
                  widths: ['*', 120],
                  body: [[{
                     text: [infoEmpresa?.email_contato, '\n', infoEmpresa?.endereco, '\n', infoEmpresa?.telefone, "\n"],
                     border: [false, false, false, true]
                  },
                  { image: 'logo', style: 'logo', width: 80, border: [false, false, false, true] },
                  ]]
               },
            },
            { image: 'chart', pageBreak: 'after', width: 516 },
            ],
            images: {
               'logo': document.querySelector('div.hdv-logomarca img').src,
               'chart': data.url
            },
            styles: {
               logo: { margin: [40, -18, 70, 0] }
            }
         }

         const win = window.open('', '_blank')
         pdfMake.createPdf(pdfOptions).open({}, win)
      })
   }*/

   getBase64ImageFromURL = (url) => {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
    
        img.onload = () => {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
    
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
    
          var dataURL = canvas.toDataURL("image/png");
    
          resolve(dataURL);
        };
    
        img.onerror = error => {
          reject(error);
        };
    
        img.src = url;
      });
   }

   exportarPDF = async () => {

      const { detalhesDefault, validacaoFormulario, relatorioGerado } = this.state
      let chart = true
      let logo = true

      let infoEmpresa
      await axios.get(this.baseUrl + `/${this.userInfo.empresa}/configuracao-sistema`, this.config_me)
         .then(res => { infoEmpresa = res.data?.data })
      
      let prepare_data = []

      this.state.table?.itens?.forEach((evt, key) => {

          prepare_data.push([
                  {text: evt?.place ?? '---'},
                  {text: evt.label},
                  {text: evt.campo},
                  {text: String(evt.valor_inicial).replace(".", ",")},
                  {text: String(evt.valor_final).replace(".", ",")},
                  {text: String(evt.porcentagem).replace(".", ",")},
                  {text: String((detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(evt.consumo).toFixed(2))) : Number(Number(evt.consumo).toFixed(2)).toLocaleString("pt-BR")) + " " + (detalhesDefault.unidade?.value ? detalhesDefault.unidade?.value : null)},
                  {text: BRAZILIANREAL.format(Number(Number(evt.consumo).toFixed(2)) * parseFloat(detalhesDefault.valor_unidade.replace('.', '').replace(',', '.')))}
              ]
          )
      })
      
      let total_volume = (detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(this.state.table.total).toFixed(2))) : Number(Number(this.state.table.total).toFixed(2)).toLocaleString("pt-BR")
      total_volume = String(total_volume) + " " + (detalhesDefault.unidade?.value ? detalhesDefault.unidade?.value : null)

      prepare_data.push(
              [
                  {text: 'Resumo do Período', colSpan: 6, fillColor: '#d5d5d5'}, 
                  {},
                  {},
                  {},
                  {},
                  {},
                  {text: total_volume, fillColor: '#d5d5d5' },
                  {text: BRAZILIANREAL.format(Number(Number(this.state.table.total).toFixed(2)) * parseFloat(detalhesDefault.valor_unidade.replace('.', '').replace(',', '.'))), fillColor: '#d5d5d5' },
              ]
      )
      console.log(prepare_data)
      let header = []
      let content = [
      {
         columns: [
            {
               width: '*',
               text: 'Relatório de Consumo',
               margin: [0, 50, 0, 0],
               fontSize: 18,
               bold: true,
            },
            {
					width: 360,
               margin: [0,0,10,0],
               alignment: 'right',
					text: [infoEmpresa?.email_contato, '\n', infoEmpresa?.endereco, '\n', infoEmpresa?.telefone, "\n"]
				},
            (logo) ? {
               width: 100,
               heigth: 100,
               image: await this.getBase64ImageFromURL(document.querySelector('div.hdv-logomarca img').src),
            } : null,
         ]
      },
      {
          text: ".",
          color: "white"
      },
      {
         table: {
            headerRows: 1,
               widths: ["*"],
            body: [
               ["Relatório: "+detalhesDefault?.descricao+" - Período: " + detalhesDefault.data?.replace('-', ' à ')],
            ]
         },
         layout: {
            hLineWidth: function (i, node) {
               return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
               return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
               return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
            },
            vLineColor: function (i, node) {
               return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
            },
            }
      },
      {
          text: ".",
          color: "white"
      },
      {
         columns: [
            (chart) ? {
               width: 821,
               heigth: 500,
               image: await domToImage.toPng(document.querySelector('.chart-item')),
            } : null,
         ]
      },
      {
          text: ".",
          color: "white"
      },
      {
         style: 'tableExample',
         color: '#444',
            fontSize: 8,
         table: {
            widths: ['*','*','*','*','*','*','*','*'],
            headerRows: 1,
            // keepWithHeaderRows: 1,
            body: [
                     [
                        {text: 'Monitorado', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Dispositivo', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Campo', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Valor Inicial', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Valor Final', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: '%', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Consumo (m3)', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Valor (R$)', alignment: 'center', fillColor: '#b7b7b7'}
                     ],
                     ...prepare_data
            ]
         }
      },
      ]
      let footer = []

      let fileDefinition = {
          pageSize: 'A4',
          pageOrientation: 'landscape',
          pageMargins: [ 10, 10, 10, 20 ],
          
          header: [header],
          content: [content],
          footer: [footer],
      }

      pdfMake.createPdf(fileDefinition).open()

  }

   render() {
      const { detalhesDefault, validacaoFormulario, relatorioGerado } = this.state

      if (this.state.redirect)
         return <Redirect to="/relatorio-consumo" />

      return (
         <div>

            <Aviso
               toggle={() => this.toggleAviso}
               mensagem={this.state.aviso.message}
               action={() => this.setState(this.state.aviso.args)}
               continue={Boolean(this.props.match.params.id) ? () => {
                  let { aviso } = this.state
                  aviso = { show: false, args: null, message: '' }
                  this.setState({ aviso })
               }
                  :
                  null}
               open={this.state.aviso.show}
            />

            <h3>Relatório de Consumo</h3>
            <div id="download-pdf"></div>
            <div className="hdv-header-filters py-0 pt-2 mx-0" style={{ height: 'fit-content' }}>
               <div className="row">
                  <div className="col-md-5  hdv-form-default">
                     <label>Descrição</label>
                     <input
                        type="text"
                        onChange={(e) => this.handleChange('descricao', e.target.value)}
                        style={{ border: '1px solid #d0d0d0' }}
                        value={detalhesDefault.descricao}
                     />
                     <span className={!this.state.validacaoFormulario.descricao ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                  </div>

                  <div className="col-md-1"></div>
                  <div className="col-md-2 px-0 pt-3">
                     <label htmlFor="disponivel_cliente_final">Disponível para cliente final</label>
                     <input
                        type="checkbox"
                        id="disponivel_cliente_final"
                        onChange={(e) => this.handleChange('disponivel_cliente_final', e.target.checked)}
                        checked={detalhesDefault.disponivel_cliente_final}
                     />
                  </div>
                  <div className="col-md-2 hdv-form-default">
                     <label>Unidade do Gráfico</label>
                     <Select
                        value={detalhesDefault.unidade}
                        onChange={(e) => this.handleChange('unidade', e)}
                        options={[
                           { label: 'm3', value: 'm3' },
                           { label: 'kW', value: 'kW' },
                           { label: 'kW/h', value: 'kW/h' },
                           { label: 'horas', value: 'horas' },
                        ]}
                     />
                  </div>
                  <div className="col-md-2 hdv-form-default">
                     <label>Custo por Unidade</label>
                     <CurrencyInput
                        id="value-unidade"
                        name="value-unidade"
                        placeholder=""
                        decimalsLimit={4}
                        groupSeparator={''}
                        decimalSeparator={','}
                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                        style={{ border: '1px solid #d0d0d0' }}
                        prefix ={'R$ '}
                        value={detalhesDefault.valor_unidade}
                        onValueChange={(value, name) => {this.handleChange('valor_unidade', (value == undefined) ? '0' : value)}}
                     />
                  </div>
               </div>

               <HeaderMultiReport
                  DataChange={detalhesDefault}
                  toggleModal={this.toggleModal}
                  dispositivos={detalhesDefault.dispositivos}
                  adicionar={this.adicionar}
                  validacao={validacaoFormulario}
                  deletar={this.deletar}
                  handleChange={this.handleChange}
               />

               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                  <div>
                     <button
                        disabled={!this.state.relatorioGerado}
                        className={!this.state.relatorioGerado ? "hdv-btn-forms hdv-btn-red hdv-block-button" : "hdv-btn-forms hdv-btn-red"}
                        onClick={this.exportarPDF} > <i className="fa fa-file-pdf-o fa-20px"></i> Exportar PDF
                     </button>

                     <button
                        style={{ backgroundColor: '#4da845', width: 'fit-content', padding: '10px', margin: '12px 8px', borderRadius: '4px', border: 'none', cursor: 'pointer' }}
                        className={(this.state.relatorioGerado ? "" : "hdv-block-button")}
                        disabled={!this.state.relatorioGerado}>
                        {this.state.relatorioGerado
                           ?
                           <CsvDownloader
                              style={{ border: 'none' }}
                              columns={this.state.csv.columns}
                              datas={this.state.csv.data}
                              filename={`relatorio_consumo_${detalhesDefault?.descricao}.csv`}
                              separator=";">
                              <p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '0 10px' }}>
                                 Exportar CSV
                              </p>
                           </CsvDownloader>
                           :
                           <p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '0 10px' }}>
                              Exportar CSV
                           </p>
                        }
                     </button>
                  </div>
                  <div className="hdv-chart-options">
                     {this.state.carregando ?
                        <ReactLoading className="hdv-report-loading" type="cylon" color="#589bd4" width={50} />
                        :
                        null
                     }

                     <button
                        className="hdv-btn-forms hdv-btn-geen m-0 mx-2 hdv-automacao-add-new-button d-flex align-items-center"
                        disabled={this.state.carregando}
                        onClick={() => {
                           let { detalhesDefault } = this.state
                           detalhesDefault['modal'] = true
                           this.setState({ detalhesDefault })
                        }} >
                        Gerenciar Dispositivos
                     </button>

                     <button
                        disabled={this.state.carregando}
                        className="hdv-btn-forms hdv-btn-blue hdv-automacao-add-new-button m-0 d-flex align-items-center"
                        onClick={this.buscarDadosRelatorio}>
                        Gerar Relatório
                     </button>
                  </div>
               </div>
               <br />
            </div>
            <div className="hdv-multi-report-dispositivos">
               {detalhesDefault.dispositivos?.length > 0 ?
                  <details>
                     <summary>Dispositivos</summary>
                     <section className="row mx-1 d-flex justify-content-between">
                        {detalhesDefault.dispositivos?.map((dispositivo, index) => (<>
                           <div
                              className="hdv-multi-report-item my-1 mx-1"
                              style={{ width: '48%' }}
                              key={index}>
                              <span>
                                 {dispositivo.id}
                              </span>
                              <span>
                                 | <b>[{dispositivo.posicao !== false ? `${dispositivo?.campo}_${dispositivo?.posicao}` : dispositivo?.campo}]</b>
                              </span>
                           </div>
                        </>
                        ))}
                     </section>
                  </details>
                  :
                  null
               }
            </div>
            <hr />
            {relatorioGerado ?
               <section id="hdv-multi-report-print" className="mb-4">
                  <div className="hdv-relatorio-consumo col-md-12 mx-0 mt-3" style={{ padding: '8px 0' }}>
                     {this.gerarGrafico()}
                  </div>

                  <table className="table table-sm hdv-multi-report-table">
                     <thead>
                        <tr>
                           <th>Monitorado</th>
                           <th>Dispositivo</th>
                           <th>Campo</th>
                           <th>Valor Inicial</th>
                           <th>Valor Final</th>
                           <th>Consumo ({detalhesDefault?.unidade?.value})</th>
                           <th>Valor (R$)</th>
                           <th colSpan="3" >%</th>
                        </tr>
                     </thead>
                     <tbody>
                        {
                           this.state.table?.itens?.map((item, index) => (
                              <React.Fragment key={index}>
                                 <tr>
                                    <td>{item?.place ?? '---'}</td>
                                    <td>{item.label}</td>
                                    <td>{item.campo}</td>
                                    <td>{String(item.valor_inicial).replace(".", ",")}</td>
                                    <td>{String(item.valor_final).replace(".", ",")}</td>
                                    <td> {(detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(item.consumo).toFixed(2))) : Number(Number(item.consumo).toFixed(2)).toLocaleString("pt-BR")}</td>
                                    <td>{BRAZILIANREAL.format(Number(Number(item.consumo).toFixed(2)) * parseFloat(detalhesDefault.valor_unidade.replace('.', '').replace(',', '.')))}</td>
                                    <td colSpan="3">
                                       <span className="d-flex">
                                          <progress value={item.porcentagem} max="100"></progress> <small>{item.porcentagem}%</small>
                                       </span>
                                    </td>
                                 </tr>

                                 {
                                    index === this.state.table.itens.length - 1
                                       ?
                                       <tr key={index + 1}>
                                          <td colSpan="5"><strong>Total</strong></td>
                                          <td> {(detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(this.state.table.total).toFixed(2))) : Number(Number(this.state.table.total).toFixed(2)).toLocaleString("pt-BR")}</td>
                                          <td>{BRAZILIANREAL.format(Number(Number(this.state.table.total).toFixed(2)) * parseFloat(detalhesDefault.valor_unidade.replace('.', '').replace(',', '.')))}</td>
                                          <td colSpan="3">
                                             {String(this.state.table.total) !== '0'
                                                ?
                                                <span className="d-flex">
                                                   <progress value="100" max="100"></progress> <small>100%</small>
                                                </span>
                                                :
                                                <span className="d-flex">
                                                   <progress value="0" max="100"></progress> <small>100%</small>
                                                </span>
                                             }
                                          </td>
                                       </tr>
                                       :
                                       null
                                 }
                              </React.Fragment>
                           ))
                        }
                     </tbody>
                  </table>
               </section>
               : null
            }

            <div className="hdv-btn-group">
               <Link to="/relatorio-consumo">
                  <button className="hdv-btn-forms hdv-btn-yellow ">Cancelar</button>
               </Link>

               <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>
                  Salvar
               </button>
            </div>
         </div>
      )
   }
}

export default Form