import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Animated } from "react-animated-css";
import qs from "query-string";
import './style.css';
import waterdrop from "../../assets/waterdrop-login.png"
import logo from "../../assets/ghidro-logo-footer.png"
import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';

class Login extends Component {

    state = {
        email: "",
        msgbox: '',
        redirectToReferrer: false,
        showmessage: false,
        logomarca: logo,
        loadcomplete: false,
        config: { "logomarca": "logomarcas/kronlogo.png", "descricao_horario_atendimento": "Seg. a Sex. das 8:00 às 18:00 ", "email_contato": "contato@ghidro.com.br ", "endereco": "Rua Carlos Ferrari, 18, Sala 610 – Centro – Garça/SP", "facebook": "https://www.facebook.com/ghidromonitoramento/", "link_criar_conta": "https://www.ghidro.com.br", "linkedin": "https://www.linkedin.com/company/g-hidro-monitoramento/", "telefone": "(14) 98161-0616", "youtube": "https://www.youtube.com/channel/UCj1BOaOQTuPMrTgPewSwgvw" },
        cores: { "degrade_top_primeira_cor": "#F44E3B", "degrade_top_segunda_cor": "#2f577b", "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7", "fonte_grupo_menu_lateral": "#c7c7c7" }
    }

    componentWillMount = () => {
        let requestArray = []
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/configuracao-login/?domain=' + window.location.hostname, config_me))

        let token = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token

        axios.all(requestArray)
            .then(axios.spread((config) => {

                let cores = { "degrade_top_primeira_cor": "#F44E3B", "degrade_top_segunda_cor": "#2f577b", "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7", "fonte_grupo_menu_lateral": "#c7c7c7" }
                let logomarca = ''
                let config_nova = ''

                if (config.status === 200) {
                    cores = config.data.cores
                }

                if (config.status === 200) {
                    logomarca = config.data.config.logomarca
                    config_nova = config.data.config
                }

                this.setState({ cores: cores, opacity: 1, logomarca: logomarca, loadcomplete: true, 'config': config_nova, 'token': token })
                if (config.status === 200) {
                    document.title = (config.data.config.titulo_sistema) ? (config.data.config.titulo_sistema) : "Hidroview v4"
                } else {
                    document.title = "Hidroview v4"
                }
            }));
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const requestURL = cfg.base_api_url + cfg.api_version + '/recuperar-senha/'
        const config = { headers: { 'Content-Type': 'application/json' } }

        let bodyFormData = new FormData();
        bodyFormData.append("email", this.state.email)

        axios.post(requestURL, bodyFormData, config)
            .then((response) => {

                this.setState({ redirectToReferrer: true })

            })
            .catch((error) => {

                this.setState({ showmessage: true, msgbox: error.response.data.error[0] })
                setTimeout(() => {
                    this.setState({ showmessage: false, msgbox: '' })
                }, 5000);
                return

            });
    }

    render() {
        console.log(this.state)
        const { from } = this.props.location.state || { from: { pathname: "/login/" } }
        const { redirectToReferrer } = this.state

        if (redirectToReferrer) {
            return <Redirect to={from} />
        }

        if (auth.getToken() !== null) {
            return <Redirect to={from} />
        }
        return (
            <div className={(this.state.loadcomplete) ? "body-page" : "hdv-noshow-item"} style={{ 'background': 'linear-gradient(to bottom, ' + this.state.cores.login_degrade_primeira_cor_background + ' 0%, ' + this.state.cores.login_degrade_primeira_cor_background + ' 56%, ' + this.state.cores.login_degrade_segunda_cor_background + ' 100%)' }}>
                <Animated className="mensagem-erro" animationIn="fadeInDown" animationOut="fadeOutDown" animateOnMount={false} isVisible={this.state.showmessage}>
                    <div>
                        {this.state.msgbox} <br />
                        <i className="fa fa-exclamation mensagem-icone fa-white"></i>
                    </div>
                </Animated>
                <div className="center-login" style={{ 'backgroundColor': this.state.cores.login_informacao_background }}>
                    <div className="gota hdv-noshow-item">
                        <img srcSet={waterdrop} alt="" />
                    </div>
                    <div className="logomarca">
                        <img width="200px" src={this.state.logomarca} alt="" />
                        <div className="subtitulo-logo" style={{ 'color': this.state.cores.login_informacao_fonte_cor }}>{this.state.config.descricao_sistema}</div>
                    </div>
                    <div className="rodape">
                        <div className="texto-rodape" style={{ 'color': this.state.cores.login_informacao_fonte_cor }}>
                            {this.state.config.endereco} <br />
                            {this.state.config.telefone} <br />
                            {this.state.config.email_contato} <br />
                            {this.state.config.descricao_horario_atendimento} <br />
                        </div>
                    </div>
                    <div className="sociais">
                        <a target="_blank" rel="noopener noreferrer" href={this.state.config.facebook}>
                            <i className="fa fa-facebook-f facebook-social fa-white"></i>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href={this.state.config.linkedin}>
                            <i className="fa fa-linkedin lkdn-social fa-white"></i>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href={this.state.config.youtube}>
                            <i className="fa fa-youtube tube-social fa-white"></i>
                        </a>
                    </div>
                    <div className="form-login" style={{ 'backgroundColor': this.state.cores.login_formulario_background }}>
                        <div style={{ 'color': this.state.cores.login_formulario_fonte_cor }} className="titulo">RECUPERAR SENHA DE USUÁRIO</div>
                        <div style={{ 'color': this.state.cores.login_formulario_fonte_cor, 'fontSize': '12px', 'padding': '5px 20px 0px 20px' }}>Você receberá uma nova senha temporária no e-mail, ao acessar o sistema novamente troque pela senha que desejar.</div>
                        <form>
                            <label style={{ 'color': this.state.cores.login_formulario_fonte_cor }} className="labels">DIGITE SEU E-MAIL:</label>
                            <input type="text" id="email" onChange={e => this.setState({ email: e.target.value })} onKeyPress={this.handleKeyPress} className="campo-form" />
                            <div>
                                <div style={{ 'marginBottom': '30px' }} className="btn-login" onClick={this.handleSubmit}>Recuperar</div>
                            </div>
                        </form>
                        <div className="esqueci links" style={{ 'color': this.state.cores.login_formulario_fonte_link }}><Link className="alogin" to="/login">Entrar no Sistema</Link></div>
                        <div className="criar-conta links" style={{ 'color': this.state.cores.login_formulario_fonte_link }}><a className="alogin" target="_blank" rel="noopener noreferrer" href={this.state.config.link_criar_conta}>Criar minha conta</a></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;