import React, { Component, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import HeaderReportDefault from '../../components/HeaderReportDefault'
import PdfDocument from "../../utils/GerarPdf";
import Select from 'react-select';
import ReactLoading from 'react-loading';
import moment from 'moment'
import CsvDownloader from 'react-csv-downloader';
import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';

import {
	BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line, LineChart
} from 'recharts';

import { PDFDownloadLink } from '@react-pdf/renderer';
const PNG = require('save-svg-as-png');

class CustomizedLabel extends PureComponent {
	render() {
		const { x, y, stroke, value, entrada_tipo_selecionada, fn } = this.props
		return <text x={x + 12} y={y} dy={0} fill={stroke} fontSize={11} textAnchor="middle">
			{Number(value) === 0 ? 0 : (entrada_tipo_selecionada === 2) ? fn(Number(value).toFixed(3)) : Number(value).toFixed(3)}
		</text>
	}
}

class RelatorioHidrometro extends Component {
	state = {
		selectedOption: null,
		detalhesDefault: { "data": "", "cliente": "", "monitorado": "", "item": null },
		detalhesExtra: {},
		hidrometrosOption: [],
		configuracaoSelecionada: null,
		reporte: [],
		data: [],
		resumo: { "horario0005": 0, "horario0611": 0, "horario1217": 0, "horario1823": 0, "total": 0 },
		resumo_vaz: { "maxima": 0, "media": 0, "minima": 0 },
		tickFormatter: (tick) => moment(tick * 1000).format('HH:mm'),
		ticks: [
			moment("2019-04-02 00:00:00").format('X'),
			moment("2019-04-02 01:00:00").format('X'),
			moment("2019-04-02 02:00:00").format('X'),
			moment("2019-04-02 03:00:00").format('X'),
			moment("2019-04-02 04:00:00").format('X'),
			moment("2019-04-02 05:00:00").format('X'),
			moment("2019-04-02 06:00:00").format('X'),
			moment("2019-04-02 07:00:00").format('X'),
			moment("2019-04-02 08:00:00").format('X'),
			moment("2019-04-02 09:00:00").format('X'),
			moment("2019-04-02 10:00:00").format('X'),
			moment("2019-04-02 11:00:00").format('X'),
			moment("2019-04-02 12:00:00").format('X'),
			moment("2019-04-02 13:00:00").format('X'),
			moment("2019-04-02 14:00:00").format('X'),
			moment("2019-04-02 15:00:00").format('X'),
			moment("2019-04-02 16:00:00").format('X'),
			moment("2019-04-02 17:00:00").format('X'),
			moment("2019-04-02 18:00:00").format('X'),
			moment("2019-04-02 19:00:00").format('X'),
			moment("2019-04-02 20:00:00").format('X'),
			moment("2019-04-02 21:00:00").format('X'),
			moment("2019-04-02 22:00:00").format('X'),
			moment("2019-04-02 23:00:00").format('X'),
		],
		tickDomain: { start: moment("2019-04-02 00:00:00").format('X'), end: moment("2019-04-02 23:00:00").format('X') },
		labelFormatter: (label) => moment.unix(label).format('HH:mm:ss'),
		unidade_selecionada: "",
		validacao_formulario: { data: true, cliente: true, monitorado: true, item: true, canal: true },
		carregando_dados: false,
		relatorio_gerado: false,
		gerando_pdf: false,
		pdfData: { vazao_image: false, volume_image: false, entrada_tipo_selecionada: false },
		detalhes_da_empresa: { "logomarca": false, "endereco": "", "telefone": "", "email": "", "descricao_horario_atendimento": "" },
		abrir_pdf: false,
		custo_total: '0',
		existeRelatorio: false,
		existeCsv: false,
		dataCsv: [],
		create_pdf_link: false,
		tipo_entrada_selecionada: false,
	}

	busca_entradas_hidrometro = () => {
		if (this.state.detalhesDefault.item != null) {

			let userInfo = auth.getUserInfo();
			let bodyFormData = new FormData();

			bodyFormData.append('hidrohub__imei', this.state.detalhesDefault.item);

			axios({
				method: 'post',
				url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/filtro/',
				data: bodyFormData,
				headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
			})
				.then((response) => {
					let optionsEntradasHidrometro = []
					response.data.entradalogica.forEach((c) => {
						console.log(c)
						/*if(c.tipo_entrada_logica_id != 3){*/
						optionsEntradasHidrometro.push({ "value": c.canal, "label": "#" + c.canal + " - " + c.descricao + " (" + c.tipo_entrada_logica__descricao + ")" })
						/*}*/
					})

					this.setState({ "hidrometrosOption": optionsEntradasHidrometro, configuracaoSelecionada: response.data, runupdate: false })
				})
				.catch((error) => {
					console.log(error);
				})
		}

	}

	calcula_os_valores_do_grafico = (reporte, diffDays) => {

		let new_reporte = []
		let vaz_reporte = []
		let i = 0

		//console.log(reporte)
		//SE ESTIVER VENDO MAIS DE 1 DIA IREI TRAZER O MAIOR VALOR DO DIA
		if (diffDays > 1 && diffDays <= 31) {
			let temp_reporte = []
			let temp_reporte_vaz = []
			let sum_to_check = 1
			reporte.reverse()
			for (i = 0; i < reporte.length; i++) {
				if (i >= (reporte.length - 1)) {
					sum_to_check = 0
				}
				if (temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] === undefined && sum_to_check === 1) {
					temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] = reporte[i]['vol']
				} else if (temp_reporte[moment(reporte[i + sum_to_check]['t'] * 1000).format('YYYY-MM-DD')] === undefined && sum_to_check === 1) {
					temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] = reporte[i + sum_to_check]['vol'] - temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')]
				} else if (sum_to_check === 0) {
					temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] = reporte[i]['vol'] - temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')]
				}
			}

			sum_to_check = 1
			let dados_vazao_dentro_hr = false
			for (i = 0; i < reporte.length; i++) {
				if (i >= (reporte.length - 1)) {
					sum_to_check = 0
				}
				if (temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] === undefined && sum_to_check === 1) {
					if (reporte[i]['vaz'] > 0) {
						temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] = { 'vaz': reporte[i]['vaz'], 'count': 1 }
					}
				} else if (temp_reporte_vaz[moment(reporte[i + sum_to_check]['t'] * 1000).format('YYYY-MM-DD')] === undefined && sum_to_check === 1) {
					if (reporte[i]['vaz'] > 0) {
						dados_vazao_dentro_hr = false
						dados_vazao_dentro_hr = temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')]
						dados_vazao_dentro_hr.vaz = dados_vazao_dentro_hr.vaz + reporte[i]['vaz']
						dados_vazao_dentro_hr.count = dados_vazao_dentro_hr.count + 1
					}
					temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] = dados_vazao_dentro_hr.vaz / dados_vazao_dentro_hr.count
				} else if (sum_to_check === 0) {
					if (reporte[i]['vaz'] > 0) {
						dados_vazao_dentro_hr = false
						dados_vazao_dentro_hr = temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')]
						dados_vazao_dentro_hr.vaz = dados_vazao_dentro_hr.vaz + reporte[i]['vaz']
						dados_vazao_dentro_hr.count = dados_vazao_dentro_hr.count + 1
					}
					temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] = dados_vazao_dentro_hr.vaz / dados_vazao_dentro_hr.count
				} else {
					if (reporte[i]['vaz'] > 0) {
						dados_vazao_dentro_hr = false
						dados_vazao_dentro_hr = temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')]
						dados_vazao_dentro_hr.vaz = dados_vazao_dentro_hr.vaz + reporte[i]['vaz']
						dados_vazao_dentro_hr.count = dados_vazao_dentro_hr.count + 1
						temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] = dados_vazao_dentro_hr
					}
				}
			}

			for (let key in temp_reporte) {
				new_reporte.push({ t: moment(key + " 00:00:00").format('X'), vol: temp_reporte[key] })
				vaz_reporte.push({ t: moment(key + " 00:00:00").format('X'), vaz: (typeof (temp_reporte_vaz[key]) === "undefined") ? 0 : temp_reporte_vaz[key] })
			}
		} else if (diffDays > 31) {
			let temp_reporte = []
			let temp_reporte_vaz = []
			let sum_to_check = 1
			reporte.reverse()
			for (i = 0; i < reporte.length; i++) {
				if (i >= (reporte.length - 1)) {
					sum_to_check = 0
				}
				if (temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM')] === undefined && sum_to_check === 1) {
					temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM')] = reporte[i]['vol']
				} else if (temp_reporte[moment(reporte[i + sum_to_check]['t'] * 1000).format('YYYY-MM')] === undefined && sum_to_check === 1) {
					temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM')] = reporte[i + sum_to_check]['vol'] - temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM')]
				} else if (sum_to_check === 0) {
					temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM')] = reporte[i]['vol'] - temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM')]
				}
			}

			sum_to_check = 1
			let dados_vazao_dentro_hr = false
			for (i = 0; i < reporte.length; i++) {
				if (i >= (reporte.length - 1)) {
					sum_to_check = 0
				}
				if (temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM')] === undefined && sum_to_check === 1) {
					if (reporte[i]['vaz'] > 0) {
						temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM')] = { 'vaz': reporte[i]['vaz'], 'count': 1 }
					}
				} else if (temp_reporte_vaz[moment(reporte[i + sum_to_check]['t'] * 1000).format('YYYY-MM')] === undefined && sum_to_check === 1) {
					if (reporte[i]['vaz'] > 0) {
						dados_vazao_dentro_hr = false
						dados_vazao_dentro_hr = temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM')]
						dados_vazao_dentro_hr.vaz = dados_vazao_dentro_hr.vaz + reporte[i]['vaz']
						dados_vazao_dentro_hr.count = dados_vazao_dentro_hr.count + 1
					}
					temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM')] = dados_vazao_dentro_hr.vaz / dados_vazao_dentro_hr.count
				} else if (sum_to_check === 0) {
					if (reporte[i]['vaz'] > 0) {
						dados_vazao_dentro_hr = false
						dados_vazao_dentro_hr = temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM')]
						dados_vazao_dentro_hr.vaz = dados_vazao_dentro_hr.vaz + reporte[i]['vaz']
						dados_vazao_dentro_hr.count = dados_vazao_dentro_hr.count + 1
					}
					if (typeof (temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')]) != "undefined") {
						temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM')] = dados_vazao_dentro_hr.vaz / dados_vazao_dentro_hr.count
					}
				} else {
					if (reporte[i]['vaz'] > 0) {
						dados_vazao_dentro_hr = false
						dados_vazao_dentro_hr = temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM')]
						dados_vazao_dentro_hr.vaz = dados_vazao_dentro_hr.vaz + reporte[i]['vaz']
						dados_vazao_dentro_hr.count = dados_vazao_dentro_hr.count + 1
						temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM')] = dados_vazao_dentro_hr
					}
				}
			}

			for (let key_month in temp_reporte) {
				new_reporte.push({ t: moment(key_month + "-01 00:00:00").format('X'), vol: temp_reporte[key_month] })
				vaz_reporte.push({ t: moment(key_month + "-01 00:00:00").format('X'), vaz: (typeof (temp_reporte_vaz[key_month]) === "undefined") ? 0 : temp_reporte_vaz[key_month] })
			}
		} else {
			let temp_reporte = []
			let temp_reporte_vaz = []
			let sum_to_check = 1
			let temp_value = 0
			reporte.reverse()
			for (i = 0; i < reporte.length; i++) {
				if (i >= (reporte.length - 1)) {
					sum_to_check = 0
				}
				if (temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] === undefined && sum_to_check === 1) {
					temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] = 0
					temp_value = reporte[i]['vol']
				} else if (temp_reporte[moment(reporte[i + sum_to_check]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] === undefined && sum_to_check === 1) {
					//temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] = reporte[i + sum_to_check]['vol'] - temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')]
					temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] = reporte[i + sum_to_check]['vol'] - temp_value
				} else if (sum_to_check === 0) {
					//temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] = reporte[i]['vol'] - temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')]
					temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] = reporte[i]['vol'] - temp_value
				}
			}

			sum_to_check = 1
			let dados_vazao_dentro_hr = false
			for (i = 0; i < reporte.length; i++) {
				if (i >= (reporte.length - 1)) {
					sum_to_check = 0
				}

				if (temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] === undefined && sum_to_check === 1) {
					if (reporte[i]['vaz'] > 0) {
						temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] = { 'vaz': reporte[i]['vaz'], 'count': 1 }
						console.log("Aberto: ", moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:mm:ss'), "Valor: ", temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')])
					}
				} else if (temp_reporte_vaz[moment(reporte[i + sum_to_check]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] === undefined && sum_to_check === 1) {
					if (reporte[i]['vaz'] > 0) {
						dados_vazao_dentro_hr = false
						dados_vazao_dentro_hr = temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')]
						dados_vazao_dentro_hr.vaz = dados_vazao_dentro_hr.vaz + reporte[i]['vaz']
						dados_vazao_dentro_hr.count = dados_vazao_dentro_hr.count + 1
					}
					console.log("Fechou: ", moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:mm:ss'), "Valor: ", temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')])
					temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] = dados_vazao_dentro_hr.vaz / dados_vazao_dentro_hr.count
				} else if (sum_to_check === 0) {
					if (reporte[i]['vaz'] > 0 && typeof (temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')]) !== "undefined") {
						dados_vazao_dentro_hr = false
						dados_vazao_dentro_hr = temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')]
						dados_vazao_dentro_hr.vaz = dados_vazao_dentro_hr.vaz + reporte[i]['vaz']
						dados_vazao_dentro_hr.count = dados_vazao_dentro_hr.count + 1
					}
					if (typeof (temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')]) != "undefined") {
						temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] = 0
						if (dados_vazao_dentro_hr.count > 1)
							temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] = dados_vazao_dentro_hr.vaz / dados_vazao_dentro_hr.count
					} else {
						if (dados_vazao_dentro_hr.count <= 1)
							temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] = 0
					}
					console.log("Finalizou: ", moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:mm:ss'), "Valor: ", temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')])
				} else {
					if (reporte[i]['vaz'] > 0) {
						dados_vazao_dentro_hr = false
						dados_vazao_dentro_hr = temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')]
						dados_vazao_dentro_hr.vaz = dados_vazao_dentro_hr.vaz + reporte[i]['vaz']
						dados_vazao_dentro_hr.count = dados_vazao_dentro_hr.count + 1
						temp_reporte_vaz[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] = dados_vazao_dentro_hr
					}
					console.log("-->>> Valor entre os horarios: ", reporte[i]['vaz'])
				}
			}

			//let position = 0
			for (let key_one_day in temp_reporte) {
				new_reporte.push({ t: moment(key_one_day).format('X'), vol: temp_reporte[key_one_day], date_format: moment(key_one_day).format('YYYY-MM-DD HH:mm:ss') })
				vaz_reporte.push({ t: moment(key_one_day).format('X'), vaz: (typeof (temp_reporte_vaz[key_one_day]) === "undefined") ? 0 : temp_reporte_vaz[key_one_day] })
				//position++
			}
		}

		return { "vazao": vaz_reporte, "volume": new_reporte }

	}

	buscar_dados_relatario = () => {

		this.setState({ carregando_dados: true, create_pdf_link: false })

		let not_validate = false
		let valiate_form = this.state.validacao_formulario
		for (let key in this.state.detalhesDefault) {
			console.log(this.state.detalhesDefault[key])
			if (this.state.detalhesDefault[key] === "" || this.state.detalhesDefault[key] === null) {
				valiate_form[key] = false
				not_validate = true
			} else {
				valiate_form[key] = true
			}

		}

		if (this.state.selectedOption === null) {
			valiate_form['canal'] = false
			not_validate = true
		} else {
			valiate_form['canal'] = true
		}

		if (not_validate === true) {
			this.setState({ validacao_formulario: valiate_form, carregando_dados: false })
			return
		}

		setTimeout(() => this.setState({ carregando_dados: false }), 3000)

		let userInfo = auth.getUserInfo();
		let bodyFormData = new FormData()
		let ticks = this.state.ticks
		let tickFormatter = this.state.tickFormatter
		let labelFormatter = this.state.labelFormatter
		/*let new_reporte = []
		let vaz_reporte = []*/
		let i = 0

		let data = this.state.detalhesDefault.data.split('-')
		let data_ini = data[0].split('/')
		let data_fim = data[1].split('/')

		let startDateChart = new Date(data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " 00:00:00");
		let endDateChart = new Date(data_fim[2] + "-" + data_fim[1] + "-" + data_fim[0] + " 23:00:00");

		let diffTime = Math.abs(endDateChart.getTime() - startDateChart.getTime());
		let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		let diffMonth = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
		diffMonth = diffMonth - 1
		if (diffDays > 1 && diffDays < 31) {
			ticks = []
			for (i; i < diffDays; i++) {
				let temp_date = new Date(data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " 00:00:00");
				ticks.push(temp_date.setDate(temp_date.getDate() + i) / 1000)
			}
			tickFormatter = (tick) => moment(tick * 1000).format('D')
			labelFormatter = (label) => moment.unix(label).format('D/M/YYYY')
		} else if (diffDays > 31) {
			ticks = []
			for (i; i < diffMonth; i++) {
				let temp_date = new Date(data_ini[2] + "-" + data_ini[1] + "-01 00:00:00");
				ticks.push(temp_date.setMonth(temp_date.getMonth() + i) / 1000)
			}
			tickFormatter = (tick) => moment(tick * 1000).format('M')
			labelFormatter = (label) => moment.unix(label).format('M/YYYY')
		} else if (moment.tz(moment(), userInfo.timezone).format('D') === data_ini[0]) {
			ticks = []
			for (i; i < parseInt(moment.tz(moment(), userInfo.timezone).format('HH')); i++) {
				let hour = String(i)
				if (i < 10) {
					hour = "0" + hour
				}
				ticks.push(moment(data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " " + hour + ":00:00").format('X'))
			}
			tickFormatter = (tick) => moment(tick * 1000).format('HH:mm')
			labelFormatter = (label) => moment.unix(label).format('HH:mm:ss')
		} else {
			ticks = []
			for (i; i < 24; i++) {
				let hour = String(i)
				if (i < 10) {
					hour = "0" + hour
				}
				ticks.push(moment(data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " " + hour + ":00:00").format('X'))
			}
			tickFormatter = (tick) => moment(tick * 1000).format('HH:mm')
			labelFormatter = (label) => moment.unix(label).format('HH:mm:ss')
		}

		bodyFormData.append('timestamp_dispositivo_ini', data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " 00:00:00");
		bodyFormData.append('timestamp_dispositivo_fim', data_fim[2] + "-" + data_fim[1] + "-" + data_fim[0] + " 23:59:00");
		bodyFormData.append('id_dispositivo', this.state.detalhesDefault.item);

		axios({
			method: 'post',
			url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reporte/pulso/',
			data: bodyFormData,
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
		})
			.then((response) => {
				let reporte = []

				response.data.forEach((rp) => {
					//reporte.push({"t": rp.timestamp_servidor, "vol": rp.pulso[this.state.selectedOption.value - 1], "vaz": rp.ct_pulso[this.state.selectedOption.value - 1]})
					if (typeof (rp.pulso) !== "undefined") {
						reporte.push({ "t": rp.timestamp_dispositivo, "vol": rp.pulso[this.state.selectedOption.value - 1], "vaz": rp.ct_pulso[this.state.selectedOption.value - 1] })
					} else {
						reporte.push({ "t": rp.timestamp_dispositivo, "vol": 0, "vaz": 0 })
					}
				})

				let dados_graficos = this.calcula_os_valores_do_grafico(reporte, diffDays)
				let resumo = { total: 0, horario0005: 0, horario0611: 0, horario1217: 0, horario1823: 0 }
				dados_graficos.volume.forEach((date) => {
					resumo.total = resumo.total + date.vol

					if (moment(date.t * 1000).format('H') >= 0 && moment(date.t * 1000).format('H') <= 5) {
						resumo.horario0005 = resumo.horario0005 + date.vol
					} else if (moment(date.t * 1000).format('H') >= 6 && moment(date.t * 1000).format('H') <= 11) {
						resumo.horario0611 = resumo.horario0611 + date.vol
					} else if (moment(date.t * 1000).format('H') >= 12 && moment(date.t * 1000).format('H') <= 17) {
						resumo.horario1217 = resumo.horario1217 + date.vol
					} else if (moment(date.t * 1000).format('H') >= 18 && moment(date.t * 1000).format('H') <= 23) {
						resumo.horario1823 = resumo.horario1823 + date.vol
					}
				})

				let vazoes = []
				dados_graficos.vazao.forEach((vaz_hr) => {
					if (vaz_hr.vaz > 0)
						vazoes.push(vaz_hr.vaz)
				})

				const custo_total = parseFloat(resumo.total).toFixed(3) * this.state.configuracaoSelecionada.entradalogica[0].custo_unidade

				let sum = (vazoes.length > 0) ? vazoes.reduce((previous, current) => current += previous) : 0;
				let med_vaz = sum / vazoes.length;
				let resumo_vaz = { maxima: Math.max.apply(null, vazoes), media: med_vaz, minima: Math.min.apply(null, vazoes) }

				let data_to_csv = []
				if (dados_graficos.volume.length > 0) {
					data_to_csv = response.data.map(dt => {
						return {
							c1: String(this.convertToDate(dt.timestamp_dispositivo)).substring(5, 0),
							c2: String(this.convertToDate(dt.timestamp_dispositivo)).slice(6, 14),
							c3: Number(dt.pulso[0]).toFixed(3),
							c4: Number(dt.pulso[1]).toFixed(3),
							c5: Number(dt.ct_pulso[0]) > 998 ? 0 : Number(dt.ct_pulso[0]).toFixed(3), //Number.isInteger(Number(dt.ct_pulso[0])) ? dt.ct_pulso[0] : Number(dt.ct_pulso[0]).toFixed(3),
							c6: Number(dt.ct_pulso[1]) > 998 ? 0 : Number(dt.ct_pulso[1]).toFixed(3),
						}
					})
				}

				this.setState({
					reporte: dados_graficos.volume,
					vaz_reporte: dados_graficos.vazao,
					data: response.data,
					resumo: resumo,
					resumo_vaz: resumo_vaz,
					ticks: ticks,
					custo_total: custo_total,
					tickFormatter: tickFormatter,
					tickDomain: { start: parseInt((dados_graficos.volume.length > 0) ? dados_graficos.volume[0].t : 0), end: parseInt((dados_graficos.volume.length > 0) ? dados_graficos.volume[dados_graficos.volume.length - 1].t : 0) },
					labelFormatter: labelFormatter,
					carregando_dados: false,
					relatorio_gerado: (dados_graficos.volume.length > 0) ? true : false,
					existeRelatorio: (dados_graficos.volume.length > 0) ? true : false,
					existeCsv: (data_to_csv.length > 0) ? true : false,
					dataCsv: data_to_csv
				})

			}).catch((error) => console.log(error))
	}

	generateDataCsv = () => {
		if (this.state.existeRelatorio) {
			let data = false
			data = this.state.data.map(dt => {
				return {
					c1: String(this.convertToDate(dt.timestamp_dispositivo)).substring(5, 0),
					c2: String(this.convertToDate(dt.timestamp_dispositivo)).slice(6, 14),
					c3: Number.isInteger(Number(dt.ct_pulso[0])) ? dt.ct_pulso[0] : Number(dt.ct_pulso[0]).toFixed(3),
					c4: dt.ct_pulso[1] > 998 ? 0 : dt.ct_pulso[1],
					c5: Number(dt.pulso[0]).toFixed(3),
					c6: Number(dt.pulso[1]).toFixed(3),
				}
			})
			this.setState({ existeCsv: true, dataCsv: data })
		}
	}

	convertToDate(stamp) {
		return moment(stamp * 1000).format('DD/MM H:mm:ss')
	}

	zoom_chart = (e) => {
		this.setState({ tickDomain: { start: this.state.data[e.startIndex].timestamp_dispositivo, end: this.state.data[e.endIndex].timestamp_dispositivo } })
	}

	convertDecimalHoursToHourMinuteSecond = (value) => {
		let decimalTimeString = value;
		let decimalTime = parseFloat(decimalTimeString);
		decimalTime = decimalTime * 60 * 60;
		let hours = Math.floor((decimalTime / (60 * 60)));
		decimalTime = decimalTime - (hours * 60 * 60);
		let minutes = Math.floor((decimalTime / 60));
		decimalTime = decimalTime - (minutes * 60);
		let seconds = Math.round(decimalTime);
		if (hours < 10) {
			hours = "0" + hours;
		}
		if (minutes < 10) {
			minutes = "0" + minutes;
		}
		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		return ("" + hours + ":" + minutes + ":" + seconds);
	}

	handleChangeHidrometroSelect = (selectedOption, field) => {

		let entrada_tipo_selecionada = this.state.configuracaoSelecionada.entradalogica.filter(entrada => {
			if (entrada.canal === selectedOption.value) {
				return true
			}
			return false
		})

		this.setState({ selectedOption, unidade_selecionada: entrada_tipo_selecionada[0].tipo_entrada_logica__unidade_totalizadora, entrada_tipo_selecionada: entrada_tipo_selecionada[0].tipo_entrada_logica_id, data: [] });
		return

		/*if (this.state.data) {
			let reporte = []
			let data = this.state.detalhesDefault.data.split('-')

			if(data.length <= 1){
				this.setState({ selectedOption, unidade_selecionada: unidade, entrada_tipo_selecionada: entrada_tipo_selecionada[0].tipo_entrada_logica_id });
				return
			}

			let data_ini = data[0].split('/')
			let data_fim = data[1].split('/')

			let startDateChart = new Date(data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " 00:00:00");
			let endDateChart = new Date(data_fim[2] + "-" + data_fim[1] + "-" + data_fim[0] + " 23:00:00");

			let diffTime = Math.abs(endDateChart.getTime() - startDateChart.getTime());
			let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

			this.state.data.forEach((rp) => {
				reporte.push({ "t": rp.timestamp_servidor, "v": rp.pulso[selectedOption.value - 1] })
			})

			//reporte = reporte.reverse()

			//SE ESTIVER VENDO MAIS E 1 DIA IREI TRAZER O MAIOR VALOR DO DIA
			if (diffDays > 1) {
				let temp_reporte = []
				let sum_to_check = 1
				reporte.reverse()
				for (i = 0; i < reporte.length; i++) {
					if (i >= (reporte.length - 1)) {
						sum_to_check = 0
					}
					if (temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] === undefined && sum_to_check === 1) {
						temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] = reporte[i]['v']
					} else if (temp_reporte[moment(reporte[i + sum_to_check]['t'] * 1000).format('YYYY-MM-DD')] === undefined && sum_to_check === 1) {
						temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] = reporte[i]['v'] - temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')]
					} else if (sum_to_check === 0) {
						temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] = reporte[i]['v'] - temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')]
					}
				}
				for (let key in temp_reporte) {
					new_reporte.push({ t: moment(key + " 00:00:00").format('X'), vol: temp_reporte[key] })
				}
			} else {
				let temp_reporte = []
				let sum_to_check = 1
				reporte.reverse()
				for (i = 0; i < reporte.length; i++) {
					if (i >= (reporte.length - 1)) {
						sum_to_check = 0
					}
					if (temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] === undefined && sum_to_check === 1) {
						temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] = reporte[i]['v']
					} else if (temp_reporte[moment(reporte[i + sum_to_check]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] === undefined && sum_to_check === 1) {
						temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] = reporte[i]['v'] - temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')]
					} else if (sum_to_check === 0) {
						temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')] = reporte[i]['v'] - temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD HH:00:00')]
					}
				}
				for (let key_one_day in temp_reporte) {
					new_reporte.push({ t: moment(key_one_day).format('X'), v: temp_reporte[key_one_day] })
				}
			}

			let resumo = { total: 0, horario0005: 0, horario0611: 0, horario1217: 0, horario1823: 0 }
			new_reporte.forEach((date) => {
				resumo.total = resumo.total + date.v
				if (moment(date.t * 1000).format('H') >= 0 && moment(date.t * 1000).format('H') <= 5) {
					resumo.horario0005 = resumo.horario0005 + date.v
				} else if (moment(date.t * 1000).format('H') >= 6 && moment(date.t * 1000).format('H') <= 11) {
					resumo.horario0611 = resumo.horario0611 + date.v
				} else if (moment(date.t * 1000).format('H') >= 12 && moment(date.t * 1000).format('H') <= 17) {
					resumo.horario1217 = resumo.horario1217 + date.v
				} else if (moment(date.t * 1000).format('H') >= 18 && moment(date.t * 1000).format('H') <= 23) {
					resumo.horario1823 = resumo.horario1823 + date.v
				}
			})

			this.setState({ selectedOption, unidade_selecionada: unidade, reporte: new_reporte, resumo: resumo, entrada_tipo_selecionada: entrada_tipo_selecionada[0].tipo_entrada_logica_id });
		} else {
			this.setState({ selectedOption, unidade_selecionada: unidade, entrada_tipo_selecionada: entrada_tipo_selecionada[0].tipo_entrada_logica_id });
		}*/

	}

	getBase64FromImageUrl = (url) => {
		//let img = new Image();

		//img.setAttribute('crossOrigin', 'anonymous');

		/*img.onload = () => {
			let canvas = document.createElement("canvas");
			canvas.width =this.width;
			canvas.height =this.height;

			let ctx = canvas.getContext("2d");
			ctx.drawImage(this, 0, 0);

			let dataURL = canvas.toDataURL("image/png");

			//console.log(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
		};*/

		let img = document.getElementById('logomarca')

		let canvas = document.createElement("canvas");
		canvas.width = img.width;
		canvas.height = img.height;

		let ctx = canvas.getContext("2d");
		ctx.drawImage(img, 0, 0);

		let dataURL = canvas.toDataURL("image/png");

		return dataURL;

	}



	generateImage = () => {
		this.setState({ relatorio_gerado: false, gerando_pdf: true })

		/*let chartSVGVaz = ReactDOM.findDOMNode(this.vazChart).children[0];
		let svgURLVaz = new XMLSerializer().serializeToString(chartSVGVaz);
		let svgBlobVaz = new Blob([svgURLVaz], {type: "image/svg+xml;charset=utf-8"});

		let chartSVGVol = ReactDOM.findDOMNode(this.volChart).children[0];
		let svgURLVol = new XMLSerializer().serializeToString(chartSVGVol);
		let svgBlobVol = new Blob([svgURLVol], {type: "image/svg+xml;charset=utf-8"});

		let url_vazao = URL.createObjectURL(svgBlobVaz);
		let url_volume = URL.createObjectURL(svgBlobVol);*/

		let pdfData = this.state.pdfData
		pdfData.entrada_tipo_selecionada = this.state.entrada_tipo_selecionada

		if (this.state.entrada_tipo_selecionada !== 2) {
			PNG.svgAsPngUri(ReactDOM.findDOMNode(this.vazChart).children[0], {}, (pngBase64Vaz) => {
				pdfData.vazao_image = pngBase64Vaz
				this.setState(pdfData)
			});
		} else {
			pdfData.vazao_image = false
			this.setState(pdfData)
		}

		PNG.svgAsPngUri(ReactDOM.findDOMNode(this.volChart).children[0], {}, (pngBase64Vol) => {
			pdfData.volume_image = pngBase64Vol
			this.setState(pdfData)
		});

		let requestArray = []
		let userInfo = auth.getUserInfo();
		let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

		requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/configuracao-sistema/', config_me))

		axios.all(requestArray)
			.then(axios.spread((config) => {
				console.log(config)
				let detalhes_da_empresa = this.state.detalhes_da_empresa

				if (config.status === 200) {
					detalhes_da_empresa.logomarca = cfg.base_api_url + 'media/' + config.data.data.logomarca
					detalhes_da_empresa.endereco = config.data.data.endereco
					detalhes_da_empresa.telefone = config.data.data.telefone
					detalhes_da_empresa.email = config.data.data.email
					detalhes_da_empresa.descricao_horario_atendimento = config.data.data.descricao_horario_atendimento
				}

				detalhes_da_empresa.logomarca = this.getBase64FromImageUrl(detalhes_da_empresa.logomarca)

				this.setState({ detalhes_da_empresa, create_pdf_link: true })

				setTimeout(() => {

					let elm = document.getElementById('download-pdf').firstChild
					window.open(elm.href, "_blank")

					this.setState({ gerando_pdf: false, relatorio_gerado: true })

				}, 5000)
			}))
	}

	retorna_pdf_link = () => {
		return (
			<PDFDownloadLink document={<PdfDocument data={{ "charts": this.state.pdfData, "resumo_volume": this.state.resumo, "resumo_vazao": this.state.resumo_vaz, "detalhes_da_empresa": this.state.detalhes_da_empresa, "unidade_selecionada": this.state.unidade_selecionada }} />} fileName="report.pdf">
			</PDFDownloadLink>
		)
	}

	render() {
		const { selectedOption } = this.state;
		console.log(this.state)

		const columns = [
			{ id: 'c1', displayName: 'Data' },
			{ id: 'c2', displayName: 'Hora' },
			{ id: 'c3', displayName: 'Hidrometro 1' },
			{ id: 'c4', displayName: 'Hidrometro 2' },
			{ id: 'c5', displayName: 'Vazao 1' },
			{ id: 'c6', displayName: 'Vazao 2' }
		]

		const { dataCsv } = this.state

		return (
			<div className="hdv-render-div" >
				<div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
					<h3>Relatório de Entradas Digitais</h3>
					<div id="download-pdf">
						{(this.state.create_pdf_link === true) ? this.retorna_pdf_link() : ""}
					</div>
					<div className="row hdv-header-filters">
						<HeaderReportDefault DataChange={this.state.detalhesDefault} fn={this.busca_entradas_hidrometro} validacao={this.state.validacao_formulario} />
						<div className="col-md-12 hdv-bloco-top-space">
							<label>Entrada Digital</label>
							<Select
								value={selectedOption}
								onChange={this.handleChangeHidrometroSelect}
								options={this.state.hidrometrosOption}
							/>
							<span className={(this.state.validacao_formulario.canal === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
						</div>
						<div className="col-md-12">
							<button
								disabled={this.state.carregando_dados}
								className={(this.state.carregando_dados === true) ? "hdv-header-filters-button hdv-generate-report hdv-block-button" : "hdv-header-filters-button hdv-generate-report"}
								onClick={this.buscar_dados_relatario} >Gerar Relatório</button>
							<button
								disabled={!this.state.relatorio_gerado}
								className={(this.state.relatorio_gerado === false) ? "hdv-header-filters-button hdv-generate-pdf hdv-block-button" : "hdv-header-filters-button hdv-generate-pdf hdv-block-pdf-border"}
								onClick={this.generateImage} > <i className="fa fa-file-pdf-o fa-20px"></i> Exportar PDF</button>

							<button
								style={{ backgroundColor: '#4da845', width: 'fit-content', height: '42px', margin: '15px', borderRadius: '4px', border: 'none', cursor: 'pointer' }}
								className={(this.state.existeCsv ? "" : "hdv-block-button")}
								disabled={!this.state.existeCsv}>
								{this.state.existeCsv
									? <CsvDownloader
										style={{ border: 'none' }}
										columns={columns}
										datas={dataCsv}
										filename="rel_entradas_digitais.csv"
										separator=";">
										<p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '8px 10px' }}>
											<i className="fa fa-file fa-20px"></i>
											Exportar CSV</p>
									</CsvDownloader>
									:
									<p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '8px 10px' }}>
										<i className="fa fa-file fa-20px"></i>
										Exportar CSV</p>
								}
							</button>

							<ReactLoading className={(this.state.gerando_pdf === true) ? "hdv-report-loading-left" : "hdv-noshow-item"} type="cylon" color="skyblue" height={15} width={50} />
							<ReactLoading className={(this.state.carregando_dados === true) ? "hdv-report-loading" : "hdv-noshow-item"} type="cylon" color="#589bd4" height={15} width={50} />
						</div>
						<br />
					</div>

					<div className={(this.state.data.length > 0) ? "row" : "row hdv-noshow-item"}>
						<div className="col-md-2" style={{ padding: '0 0' }}>
							<br />
							<div className="hdv-resumo-relatorio">Resumo</div>
							<div className="hdv-resumo-content">
								<div className="col-md-12 hdv-resumo-titulo">Valor Total: <strong>{(this.state.entrada_tipo_selecionada === 2) ? this.convertDecimalHoursToHourMinuteSecond(parseFloat(this.state.resumo.total).toFixed(3)) : parseFloat(this.state.resumo.total).toFixed(3)}</strong> {this.state.unidade_selecionada}</div>
								<div className="col-md-12 hdv-resumo-titulo">Custo Total: <strong>{Number(this.state.custo_total).toFixed(2)}</strong> R$</div>
								{/* <div className="col-md-12 hdv-resumo-titulo">Valor das 00H às 05H: <strong>{(this.state.entrada_tipo_selecionada === 2) ? this.convertDecimalHoursToHourMinuteSecond(parseFloat(this.state.resumo.horario0005).toFixed(3)) : parseFloat(this.state.resumo.horario0005).toFixed(3)}</strong> {this.state.unidade_selecionada}</div>
								<br />
								<div className="col-md-12 hdv-resumo-titulo">Valor das 06H às 11H: <strong>{(this.state.entrada_tipo_selecionada === 2) ? this.convertDecimalHoursToHourMinuteSecond(parseFloat(this.state.resumo.horario0611).toFixed(3)) : parseFloat(this.state.resumo.horario0611).toFixed(3)}</strong> {this.state.unidade_selecionada}</div>
								<br />
								<div className="col-md-12 hdv-resumo-titulo">Valor das 12H às 17H: <strong>{(this.state.entrada_tipo_selecionada === 2) ? this.convertDecimalHoursToHourMinuteSecond(parseFloat(this.state.resumo.horario1217).toFixed(3)) : parseFloat(this.state.resumo.horario1217).toFixed(3)}</strong> {this.state.unidade_selecionada}</div>
								<br />
								<div className="col-md-12 hdv-resumo-titulo">Valor das 18H às 23H: <strong>{(this.state.entrada_tipo_selecionada === 2) ? this.convertDecimalHoursToHourMinuteSecond(parseFloat(this.state.resumo.horario1823).toFixed(3)) : parseFloat(this.state.resumo.horario1823).toFixed(3)}</strong> {this.state.unidade_selecionada}</div> */}
							</div>
						</div>
						<div className="col-md-10" style={{ padding: '0 0' }}>

							<div style={{ width: '100%', height: 500 }}>
								<ResponsiveContainer>
									<BarChart
										ref={(chart) => this.volChart = chart}
										width={500}
										height={300}
										data={this.state.reporte}
										margin={{
											top: 5, right: 30, left: 20, bottom: 5,
										}}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="t"
											type="number"
											tickFormatter={this.state.tickFormatter}
											domain={[this.state.tickDomain.start, this.state.tickDomain.end]}
											ticks={this.state.ticks}
											padding={{ left: 50, right: 50, top: 50 }}
										/>
										<YAxis
											tickFormatter={(value) => {
												if (this.state.entrada_tipo_selecionada === 2) {
													return this.convertDecimalHoursToHourMinuteSecond(parseFloat(value).toFixed(3).toString())
												} else {
													return value
												}
											}}
										/>
										<Tooltip
											labelFormatter={this.state.labelFormatter}
											formatter={(value) => {
												if (this.state.entrada_tipo_selecionada === 2) {
													return this.convertDecimalHoursToHourMinuteSecond(parseFloat(value).toFixed(3).toString())
												} else {
													return parseFloat(value).toFixed(3).toString() + " " + this.state.unidade_selecionada
												}
											}}
										/>
										<Legend />
										<Bar dataKey="vol" fill="dodgerblue" label={<CustomizedLabel fn={this.convertDecimalHoursToHourMinuteSecond} entrada_tipo_selecionada={this.state.entrada_tipo_selecionada} />} />
									</BarChart>
								</ResponsiveContainer>
							</div>

						</div>
						<div className={(this.state.entrada_tipo_selecionada === 2) ? "hdv-noshow-item" : "col-md-2"} style={{ padding: '0 0' }}>
							<br />
							<div className="hdv-resumo-relatorio">Resumo</div>
							<div className="hdv-resumo-content">
								<div className="col-md-12 hdv-resumo-titulo">Máximo: <strong>{parseFloat(this.state.resumo_vaz.maxima).toFixed(3)}</strong> {this.state.unidade_selecionada}</div>
								<div className="col-md-12 hdv-resumo-titulo">Média: <strong>{parseFloat(this.state.resumo_vaz.media).toFixed(3)}</strong> {this.state.unidade_selecionada}</div>
								<div className="col-md-12 hdv-resumo-titulo">Mínimo: <strong>{parseFloat(this.state.resumo_vaz.minima).toFixed(3)}</strong> {this.state.unidade_selecionada}</div>
							</div>
						</div>
						<div className={(this.state.entrada_tipo_selecionada === 2) ? "hdv-noshow-item" : "col-md-10"} style={{ padding: '0 0' }}>
							<h5 style={{ textAlign: 'center' }}>Vazão</h5>
							<div style={{ width: '100%', height: 300 }}>
								<ResponsiveContainer>
									<LineChart
										ref={(chart) => this.vazChart = chart}
										width={500}
										height={300}
										data={this.state.vaz_reporte}
										margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="t"
											type="number"
											tickFormatter={this.state.tickFormatter}
											domain={[this.state.tickDomain.start, this.state.tickDomain.end]}
											ticks={this.state.ticks}
											padding={{ left: 20, right: 20, top: 10 }}
										/>
										<YAxis />
										<Tooltip
											labelFormatter={this.state.labelFormatter}
											formatter={(value) => parseFloat(value).toFixed(3).toString() + " " + this.state.unidade_selecionada}
										/>
										<Legend onClick={(e) => console.log(e)} />
										<Line type="monotone" dataKey="vaz" stroke="#8884d8" activeDot={{ r: 8 }} label={<CustomizedLabel fn={this.convertDecimalHoursToHourMinuteSecond} entrada_tipo_selecionada={this.state.entrada_tipo_selecionada} />} />
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RelatorioHidrometro;