import React, { Component } from 'react'
import Select from 'react-select'
import { InputGroup, InputGroupAddon, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangePicker } from 'react-date-range'
import * as rdrLocales from 'react-date-range/dist/locale'

import axios from 'axios'
import auth from '../utils/auth'
import cfg from '../utils/config'
import CampoCustomizado from '../components/CampoCustomizado'
import helper from '../utils/helper'


class HeaderMultiReport extends Component {
   baseUrl = `${cfg.base_api_url + cfg.api_version}`
   userInfo = auth.getUserInfo()
   config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

   state = {
      mounted: 0,
      optionSelected: { "descricao": "", "agrupar": {}, "ultimoReporte": null },
      datePickerShow: false,
      checkClose: false,
      dateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
      dataFormatada: "",
      detalhes: { data: "" },
      clientes: [],
      monitorados: [],
      itens: [],
      addDispositivo: {
         modal: false,
         filtrados: [],
         selecionados: [],
         campo: null,
         posicao: null,
         filtros: { cliente: null, monitorado: null },
      },
      editDispositivo: { modal: false, index: null, ultimoReporte: null, campo: "", posicao: "" },
      campoOption: [
         { label: 'pulso', value: 'pulso' },
         { label: 'ct_pulso', value: 'ct_pulso' },
      ]
   }

   checarCalendarioAberto = (e) => {
      let domPath = helper.pegar_path_elemento_dom(e.target)
      if (this.state.checkClose === true) {

          let retArray = domPath.filter((path) => {
              if (path.includes("hdv-datepicker")) {
                  return true
              }
              return false
          })

          if (retArray.length === 0) {
              this.setState({ "datePickerShow": false, checkClose: false })
          }

      } else this.setState({ checkClose: true })

      return true
  }

   componentWillReceiveProps(props) {
      let dispositivos = props.dispositivos ?? []
      let { addDispositivo } = this.state

      addDispositivo['selecionados'] = dispositivos
      this.setState({ addDispositivo })
   }

   componentDidMount = () => {
      window.addEventListener('click', this.checarCalendarioAberto, false)

      let requestArray = []
      let userInfo = auth.getUserInfo()
      let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

      requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/', config_me))

      axios.all(requestArray)
         .then(axios.spread((cliente) => {
            let optionsCliente = []

            optionsCliente.push({ label: 'Nenhum', value: null })
            if (cliente.data.length > 0) {
               cliente.data.forEach((c) => {
                  optionsCliente.push({ "value": c.id, "label": c.nome_fantasia + ' - ' + c.cpf_cnpj })
               })

               if (this.props.DataChange.cliente?.value) {
                  const cliente = optionsCliente.find(c => c.value === this.props.DataChange.cliente?.value)
                  if (cliente && this.state.mounted > 0) {
                     this.handleChangeCliente(cliente)
                  }
               }
            }

            this.setState({ "detalhes": this.props.DataChange, "clientes": optionsCliente })
         }))
   }

   handleSelect = (ranges) => {
      let detalhes = this.state.detalhes
      detalhes.data = ranges.selection.startDate.toLocaleDateString() + "-" + ranges.selection.endDate.toLocaleDateString()
      this.setState({
         dateRange: {
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
            key: 'selection',
         },
         dataFormatada: ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString(),
         detalhes: detalhes
      })
   }

   showDatePicker = () => {
      this.setState({ "datePickerShow": !this.state.datePickerShow })
   }

   handleChange = (field, value) => {
      let { optionSelected } = this.state
      optionSelected[field] = value
      this.props.handleChange(field, typeof value === "object" ? value.value : value)
      this.setState({ optionSelected })
   }

   handleChangeCliente = (selectedOption) => {
      let { addDispositivo } = this.state
      addDispositivo['filtros']['cliente'] = selectedOption
      addDispositivo['filtros']['monitorado'] = null
      this.props.handleChange('cliente', selectedOption)

      if (selectedOption.value !== null) {
         let userInfo = auth.getUserInfo()
         const bodyFormData = new FormData()
         const filterData = new FormData()

         bodyFormData.append('cliente_id', selectedOption.value)
         filterData.append('item__monitorado__cliente_id', selectedOption.value)

         axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado/filtro/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
         })
            .then((response) => {
               let optionsMonitorado = []
               optionsMonitorado.push({ label: 'Nenhum', value: null })

               response.data.forEach((c) => {
                  optionsMonitorado.push({ "value": c.id, "label": c.nome })
               })
               this.setState({ "monitorados": optionsMonitorado })
            })
            .catch((error) => console.log(error))

         axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reporte-lista-equipamento-revenda/?page=0&pageSize=500&filter=%5B%5D',
            data: filterData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
         }).then(res => {
            let { addDispositivo } = this.state
            let filtrados = []

            res.data?.results?.map(item => {
               filtrados.push({ label: item.imei, value: item.imei, id: item.id })
            })

            addDispositivo['filtrados'] = filtrados
            this.setState({ addDispositivo })
         }).catch((error) => console.log(error))

         this.handleChangeMonitorado({ label: 'Nenhum', value: null })
      } else {
         let { addDispositivo } = this.state
         addDispositivo['filtrados'] = []
         this.setState({ addDispositivo })
      }
   }

   handleChangePadrao = (e) => {
      let { editDispositivo, addDispositivo } = this.state
      addDispositivo.selecionados[editDispositivo?.index]['padrao'] = e.target.checked

      this.setState({ editDispositivo })
   }

   handleChangeMonitorado = (selectedOption) => {
      let { addDispositivo } = this.state
      addDispositivo['filtros']['monitorado'] = selectedOption

      if (selectedOption.value !== null) {
         let userInfo = auth.getUserInfo()
         const filterData = new FormData()

         if (addDispositivo.filtros.cliente?.value) {
            filterData.append('item__monitorado__cliente_id', addDispositivo.filtros.cliente.value)
         }
         filterData.append('item__monitorado__id', selectedOption.value)

         axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado-equipamento/' + selectedOption.value + '/',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
         })
            .then((response) => {
               let optionsItem = []
               response.data.forEach((c) => optionsItem.push({ "value": c, "label": c }))
               this.setState({ "itens": optionsItem })
            })
            .catch((error) => console.log(error))

         axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reporte-lista-equipamento-revenda/?page=0&pageSize=100&filter=%5B%5D',
            data: filterData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
         }).then(res => {
            let { addDispositivo } = this.state
            let filtrados = []

            res.data?.results?.map(item => {
               filtrados.push({ label: item.imei, value: item.imei })
            })

            addDispositivo['filtrados'] = filtrados
            this.setState({ addDispositivo })
         }).catch((error) => console.log(error))
      } else {
         let { addDispositivo } = this.state
         addDispositivo['filtros']['monitorado'] = null
         this.setState({ addDispositivo })
      }
   }

   manipularData = (sentido) => {
      if (!this.state.detalhes.data)
         return

      let diffTime = Math.abs(this.state.dateRange.endDate.getTime() - this.state.dateRange.startDate.getTime())
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      diffDays = (diffDays === 0) ? 1 : diffDays
      diffDays = (sentido === "avancar") ? diffDays : -Math.abs(diffDays)

      let newStartDate = new Date(this.state.dateRange.startDate.setDate(this.state.dateRange.startDate.getDate() + diffDays))
      let newEndDate = new Date(this.state.dateRange.endDate.setDate(this.state.dateRange.endDate.getDate() + diffDays))

      let detalhes = this.state.detalhes
      detalhes.data = newStartDate.toLocaleDateString() + "-" + newEndDate.toLocaleDateString()

      this.setState({
         dateRange: { startDate: newStartDate, endDate: newEndDate, key: 'selection' },
         dataFormatada: newStartDate.toLocaleDateString() + " - " + newEndDate.toLocaleDateString(),
         detalhes: detalhes
      })
   }

   handleDispositivos = (e, item) => {
      e.stopPropagation()
      let { addDispositivo } = this.state
      let selected = addDispositivo.selecionados

      selected.push({
         id: item.label,
         value: item.id,
         campo: addDispositivo.campo,
         posicao: addDispositivo.posicao ?? false,
         padrao: false
      })

      addDispositivo['selecionados'] = selected
      this.setState({ addDispositivo })
   }

   selecionarTodos = () => {
      let { addDispositivo } = this.state
      let filtrados = addDispositivo.filtrados
      let selecionados = addDispositivo.selecionados

      filtrados.map(item => {
         if (selecionados.filter(s => s.id === item.label).length === 0) {
            selecionados.push({
               id: item.label,
               campo: addDispositivo.campo,
               posicao: addDispositivo.posicao ?? false,
               padrao: false
            })
         }
      })

      addDispositivo['selecionados'] = selecionados
      this.setState({ addDispositivo })
   }

   adicionar = () => {
      this.props.adicionar(this.state.addDispositivo.selecionados)
      if (document.querySelector('.hdv-multi-report-dispositivos details'))
         document.querySelector('.hdv-multi-report-dispositivos details').open = true
   }

   editar = (index, dispositivo) => {
      let { editDispositivo } = this.state
      this.toggleEditModal('modal', true)
      editDispositivo['dispositivo'] = dispositivo
      editDispositivo['index'] = index

      const bodyFormData = new FormData()
      bodyFormData.append('id_dispositivo', dispositivo?.id)

      axios.post(this.baseUrl + `/${this.userInfo.empresa}/reporte-ultimo-evento-calculado/`, bodyFormData, this.config_me)
         .then(res => {
            editDispositivo['ultimoReporte'] = res.data
            this.setState({ editDispositivo })
         })
         .catch(err => console.log(err))
   }

   cancelar = () => {
      this.props.toggleModal()
   }

   toggleEditModal = (key, value) => {
      let { editDispositivo } = this.state
      editDispositivo[key] = value
      this.setState({ editDispositivo })
   }

   salvarDispositivo = () => {
      let { addDispositivo, editDispositivo } = this.state
      this.toggleEditModal('modal', false)

      let selecionados = addDispositivo.selecionados
      let padrao = selecionados.filter(s => s.padrao)?.[0]

      if (padrao) {
         for (let i = 0; i < selecionados.length; i++) {
            if (!selecionados[i].campo) {
               selecionados[i].campo = padrao.campo
            }
            if (!selecionados[i].posicao && selecionados[i].posicao === false) {
               selecionados[i].posicao = padrao.posicao
            }
         }
      }

      addDispositivo['selecionados'] = selecionados
      addDispositivo.campo = selecionados[editDispositivo.index].campo
      addDispositivo.posicao = selecionados[editDispositivo.index].posicao
      editDispositivo['padrao'] = false

      this.setState({ addDispositivo, editDispositivo })
   }

   isClienteDisabed = () => {
      const { DataChange } = this.props
      const { addDispositivo } = this.state
      let isDisabled = false

      if (Boolean(addDispositivo.filtros.cliente?.value) && addDispositivo.selecionados.length > 0) {
         isDisabled = true
      }

      if (DataChange.disponivel_cliente_final && !addDispositivo.filtros.cliente) {
         isDisabled = false
      }

      return isDisabled
   }

   render() {
      const { optionSelected, addDispositivo, editDispositivo } = this.state
      const { DataChange } = this.props
      const agruparOption = optionSelected.agrupar

      return (
         <>
            <Modal isOpen={DataChange.modal} toggle={this.props.toggleModal} className="modal-large">
               <ModalHeader toggle={this.props.toggleModal}>
                  Gerenciar Dispositivos
               </ModalHeader>
               <ModalBody>
                  <div className="row">
                     <div className="col-md-6">
                        <div className="row">
                           <div className="col-md-6">
                              <label>Cliente</label>
                              <Select
                                 isDisabled={this.isClienteDisabed()}
                                 value={addDispositivo.filtros.cliente}
                                 onChange={(e) => this.handleChangeCliente(e)}
                                 options={this.state.clientes}
                              />
                           </div>
                           <div className="col-md-6">
                              <label>Monitorado</label>
                              <Select
                                 value={addDispositivo.filtros.monitorado}
                                 onChange={(e) => this.handleChangeMonitorado(e)}
                                 options={this.state.monitorados}
                              />
                           </div>
                           <div className="col-md-12">
                              <label className="mt-2">Dispositivos</label>
                              <ul id="multi-item-list" style={{ height: '300px' }}>
                                 {addDispositivo.filtrados.map((item, index) => {
                                    const selected = addDispositivo.selecionados?.filter(i => i.id === item.value).length > 0

                                    return (
                                       <React.Fragment key={index}>
                                          {
                                             index === 0
                                                ?
                                                <button key="button" onClick={this.selecionarTodos}>Selecionar todos</button>
                                                :
                                                null
                                          }
                                          <label className="d-flex" style={{ margin: '0' }}>
                                             <span className={selected ? "multi-item-selected" : ""}>
                                                {item.label}
                                             </span>
                                             <button className="multi-item-add" onClick={(e) => this.handleDispositivos(e, item)}>
                                                +
                                             </button>
                                          </label>
                                       </React.Fragment>
                                    )
                                 })
                                 }
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6 row">
                        <div className="col-md-12" id="multi-item-selected">
                           <label>Selecionados</label>
                           {!this.props.validacao.dispositivos ?
                              <>
                                 <br />
                                 <span className={"hdv-required-field"}>* Dispositivo(s) inválido(s): Insira os campos e canais.</span>
                              </>
                              :
                              null
                           }
                           {
                              this.state.addDispositivo.selecionados.map((dispositivo, index) => (
                                 <div className="hdv-multi-report-item" key={index}>
                                    <p>
                                       {dispositivo.id}&nbsp;
                                       <span>[{dispositivo?.posicao !== false ? `${dispositivo?.campo}_${dispositivo?.posicao}` : dispositivo?.campo}]</span>
                                    </p>
                                    <div style={{ display: 'flex' }}>
                                       <button
                                          onClick={() => this.editar(index, dispositivo)}
                                          className={dispositivo.campo ? "hdv-automacao-delete-button" : "hdv-automacao-delete-button warning-animation"}>
                                          <i className="fa fa-pen fa-2x"></i>
                                       </button>
                                       <button
                                          onClick={() => this.props.deletar(index)}
                                          className="hdv-automacao-delete-button">
                                          <i className="fa fa-times fa-2x"></i>
                                       </button>
                                    </div>
                                 </div>
                              ))
                           }
                        </div>
                     </div>
                     <hr />
                  </div>
               </ModalBody>
               <ModalFooter>
                  <Button color="secondary" onClick={this.cancelar}>Cancelar</Button>
                  <Button color="success" onClick={this.adicionar}>Concluir</Button>
               </ModalFooter>
            </Modal>

            <Modal isOpen={editDispositivo.modal} toggle={() => this.toggleEditModal('modal', false)}>
               <ModalHeader toggle={() => this.toggleEditModal('modal', false)}>
                  Editar Dispositivo {addDispositivo.selecionados?.[editDispositivo?.index]?.id}
               </ModalHeader>
               <ModalBody>
                  <div className="d-flex justify-content-between">
                     <label>Selecionar campo</label>
                     <label>
                        <span>Definir campo como padrão </span>
                        <input
                           type="checkbox"
                           checked={addDispositivo.selecionados[editDispositivo?.index]?.padrao}
                           onChange={(e) => this.handleChangePadrao(e)}
                        />
                     </label>
                  </div>

                  <div className="campo-customizado px-2" style={{ height: '240px' }}>
                     <CampoCustomizado
                        funcao={(variavel) => {
                           let { addDispositivo, editDispositivo } = this.state
                           let split = variavel.split('_')
                           let campo, posicao

                           if (isNaN(split[split.length - 1])) {
                              campo = variavel
                              posicao = false
                           } else {
                              posicao = split[split.length - 1]
                              split.pop()
                              campo = split.join('_')
                           }

                           addDispositivo.selecionados[editDispositivo.index].campo = campo
                           addDispositivo.selecionados[editDispositivo.index].posicao = posicao
                           this.setState({ addDispositivo })
                        }}
                        data={editDispositivo.ultimoReporte}
                        bracket={false}
                     />
                  </div>
               </ModalBody>
               <ModalFooter>
                  <Button color="primary" onClick={this.salvarDispositivo}>OK</Button>
               </ModalFooter>
            </Modal>

            <div className="col-md-12">
               <div className="row">
                  <div className="col-md-8"></div>
                  <div className="col-md-5 px-0">
                     <label className="my-0 pt-3">Periodo</label>
                     <InputGroup className="hdv-force-hight">
                        <InputGroupAddon addonType="prepend">
                           <Button onClick={() => this.manipularData("voltar")} color="info">
                              <i className="fa fa-angle-double-left"></i>
                           </Button>
                        </InputGroupAddon>
                        <Input className="hdv-force-hight" placeholder="Selecione o periodo no botão 'Exibir Calendário' " onChange={() => false} disabled value={this.state.dataFormatada} />
                        <InputGroupAddon addonType="append">
                           <Button onClick={this.showDatePicker} color={(this.state.datePickerShow) ? "danger" : "primary"}>
                              <i className={(this.state.datePickerShow) ? "fa fa-calendar-times-o" : "fa fa-calendar-check-o"}></i>
                              {(this.state.datePickerShow) ? "Fechar Calendário" : "Exibir Calendário"}
                           </Button>
                        </InputGroupAddon>
                        <InputGroupAddon addonType="append">
                           <Button onClick={() => this.manipularData("avancar")} color="info">
                              <i className="fa fa-angle-double-right"></i>
                           </Button>
                        </InputGroupAddon>
                     </InputGroup>
                     <span className={!this.props.validacao.data ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                     <div className={(this.state.datePickerShow) ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                        <DateRangePicker locale={rdrLocales.pt} ranges={[this.state.dateRange]} onChange={this.handleSelect} />
                     </div>
                  </div>
                  <div className="col-md-3 px-0"></div>
                  <div className="col-md-4 hdv-form-default px-0">
                     <label className="mt-2">Agrupar por</label>
                     <Select
                        value={agruparOption}
                        onChange={(e) => this.handleChange('agrupar', e)}
                        options={[
                           { label: 'Hora', value: 'hour' },
                           { label: 'Dia', value: 'day' },
                           { label: 'Mês', value: 'month' },
                           { label: 'Período Específico (Dia X do mês selecionado até o mesmo dia do mês seguinte)', value: 'irregular' },
                           { label: 'Período Fixo', value: 'fixo' }
                        ]}
                     />
                     <span className={!this.props.validacao.agrupar ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                  </div>

                  <div className="col-md-2"></div>
               </div>
            </div>
         </>
      )
   }
}

export default HeaderMultiReport