import React, { Component } from 'react';
import ReactTable from "react-table";
import { Link, Redirect } from "react-router-dom"
import _ from "underscore"
import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import 'react-table/react-table.css'
import Aviso from '../../components/Aviso'

class Grid extends Component {

    state = {
        selected: [],
        selectAll: 0,
        data: [],
        pages: null,
        loading: true,
        filtered: true,
        enableDeletar: false,
        enableEditar: false,
        enableConvite: false,
        redirectToEdit: false,
        aviso: { show: false, args: null, message: '' },
    };

    toggleRow = (id) => {
        let selecionados = this.state.selected
        let editar = false
        let deletar = false

        if (selecionados.includes(id)) {
            selecionados = selecionados.filter(item => item !== id)
        } else {
            selecionados.push(id)
        }

        if (selecionados.length === 1) {
            editar = true
        }

        if (selecionados.length >= 1) {
            deletar = true
        }

        this.setState({
            enableEditar: editar,
            enableConvite: editar,
            enableDeletar: deletar,
            selected: selecionados,
            selectAll: 2
        });
    }

    toggleSelectAll = () => {
        let selecionados = [];
        let editar = false
        let deletar = false

        if (this.state.selectAll === 0) {
            this.state.data.forEach(x => {
                selecionados.push(x.id);
            });
        }

        if (selecionados.length === 1) {
            editar = true
        }

        if (selecionados.length >= 1) {
            deletar = true
        }

        this.setState({
            enableEditar: editar,
            enableConvite: editar,
            enableDeletar: deletar,
            selected: selecionados,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    getClientes = _.debounce((state, instance) => {
        if (state) {
            if ("pageSize" in state) {
                this.setState({ loading: true });
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                let userInfo = auth.getUserInfo();

                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/?page=' + state.page + '&pageSize=' + state.pageSize + '&filter=' + encodeURI(JSON.stringify(state.filtered)), config_me)
                    .then( (response) => {
                        this.setState({ selected: [], data: response.data.results, loading: false, pages: response.data.pages, filtered: true });
                    })
            }
        }
    }, 500)

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    deletar = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        this.setState({ loading: true, enableDeletar: false, enableEditar: false });

        this.state.selected.map((value, index) =>
            requestArray.push(axios.delete(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + value + '/', config_me))
        );

        axios.all(requestArray)
            .then(axios.spread((acct, perms) => this.refReactTable.fireFetchData()));
    }

    editar = () => {
        let toGo = "cliente/" + this.state.selected[0] + "/editar"
        let from = { pathname: toGo }
        this.setState({ redirectToEdit: from });
    }

    reenvio_convite = () => {

        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        let userInfo = auth.getUserInfo();

        this.setState({ loading: true, enableDeletar: false, enableEditar: false, enableConvite: false });

        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reenvio-convite/' + this.state.selected[0] + '/', config_me)
            .then((response) => {
                const aviso = { show: true, message: 'Convite enviado.', args: {} }
                this.setState({ selected: [], loading: false, aviso })

            })
            .catch((error) => {
                alert(error.response.data.msg)
                this.setState({ loading: false, enableDeletar: true, enableEditar: true, enableConvite: true })
            })

    }

    render() {

        const { redirectToEdit } = this.state;
        if (redirectToEdit) {
            return <Redirect to={redirectToEdit} />;
        }

        const columns = [{
            Header: x => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={this.state.selectAll === 1}
                        ref={input => {
                            if (input) {
                                input.indeterminate = this.state.selectAll === 2;
                            }
                        }}
                        onChange={() => this.toggleSelectAll()}
                    />
                );
            },
            sortable: false,
            width: 55,
            accessor: "",
            filterable: false,
            Cell: ({ original }) => {
                return (
                    <div className="hdv-aligncenter-checkboxgrid">
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={(this.state.selected.includes(original.id) === true ? true : false)}
                            onChange={() => this.toggleRow(original.id)}
                        />
                    </div>
                );
            }
        }, {
            Header: 'Razão Social',
            accessor: 'razao_social'
        }, {
            Header: 'Nome Fantasia',
            accessor: 'nome_fantasia'
        }, {
            Header: 'Pessoa',
            accessor: 'pessoa_tipo',
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="">Mostrar Todos</option>
                    <option value="fisica">Pessoa Física</option>
                    <option value="juridica">Pessoa Jurídica</option>
                </select>
        }, {
            Header: 'CPF/CNPJ',
            accessor: 'cpf_cnpj'
        }, {
            Header: 'Situação',
            accessor: 'status',
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="">Mostrar Todos</option>
                    <option value="ativo">Ativo</option>
                    <option value="bloqueado">Bloqueado</option>
                </select>
        }]
        return (
            <div>

                <h4>Cadastro de Clientes</h4>

                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.toggleAviso()}
                    open={this.state.aviso.show}
                />

                <div className="hdv-btn-group">
                    <Link to="/cliente/novo">
                        <button className="hdv-btn-forms hdv-btn-geen ">Novo</button>
                    </Link>
                    {/*<Button size="sm" onClick={() => { if(this.state.enableEditar === true) { this.editar() }}} disabled={(this.state.enableEditar === true ? false : true) } className="btn-add-margin" color={(this.state.enableEditar === true ? "warning" : "secondary") }>Editar</Button>
                    <Button size="sm" onClick={() => { if(this.state.enableDeletar === true) { this.deletar() }}} disabled={(this.state.enableDeletar === true ? false : true) } className="btn-add-margin" color={(this.state.enableDeletar === true ? "danger" : "secondary") }>Excluir</Button>*/}
                    <button onClick={() => { if (this.state.enableEditar === true) { this.editar() } }} className={"hdv-btn-forms hdv-btn-yellow " + (this.state.enableEditar === true ? "" : "hdv-btn-disable")}>Editar</button>
                    <button onClick={() => { if (this.state.enableDeletar === true) { this.deletar() } }} className={"hdv-btn-forms hdv-btn-red " + (this.state.enableDeletar === true ? "" : "hdv-btn-disable")}>Excluir</button>
                    <button onClick={() => { if (this.state.enableConvite === true) { this.reenvio_convite() } }} className={"hdv-btn-forms hdv-btn-blue " + (this.state.enableConvite === true ? "" : "hdv-btn-disable")}>Reenviar Convite</button>
                </div>

                <ReactTable
                    ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                    manual
                    filterable
                    data={this.state.data}
                    pages={this.state.pages}
                    loading={this.state.loading}
                    onFetchData={this.getClientes}
                    columns={columns}
                    defaultPageSize={10}
                    previousText={'Anterior'}
                    nextText={'Próximo'}
                    loadingText={'Carregando...'}
                    noDataText={'Nenhum registro para exibir'}
                    pageText={'Página'}
                    ofText={'de'}
                    rowsText={'linhas'}
                />

            </div>
        );
    }
}

export default Grid;
