import React, { Component } from 'react';
import ReactTable from "react-table";
import _ from "underscore"
import axios from 'axios'
import { Link } from 'react-router-dom'
import ModalUltimoPacote from '../../components/ModalUltimoPacote'
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import moment from 'moment-timezone';
import Select, { components } from 'react-select'
import { Modal, Button, ModalHeader, ModalBody } from 'reactstrap'

class ListaEquipamentos extends Component {

    state = {
        data: [],
        pages: 1,
        loading: true,
        filtered: true,
        selected: [],
        releModal: false,
        nitModal: false,
        eqSelecionado: false,
        opSelecionada: false,
        clientes: []
    }

    showMenu = (e, dataAttr) => {
        document.querySelectorAll('.hdv-menu-comandos').forEach(e => {
            if (String(e.dataset.id) != String(dataAttr))
                e.classList.add('hdv-noshow-item')
        })
        document.querySelector(`div[data-id="${dataAttr}"]`).classList.toggle('hdv-noshow-item')
    }

    getData = _.debounce((state, instance) => {
        if (state) {
            if ("pageSize" in state) {
                this.setState({ loading: true });
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                let userInfo = auth.getUserInfo();

                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reporte-lista-equipamento-web-monitorado/?page=' + state.page + '&pageSize=' + state.pageSize + '&filter=' + encodeURI(JSON.stringify(state.filtered)), config_me)
                    .then((response) => {
                        this.setState({ selected: [], data: response.data.results, loading: false, pages: response.data.pages, filtered: true });
                    })
            }
        }
    }, 500)

    componentDidMount() {
        const userInfo = auth.getUserInfo()

        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(res => {
                let optionsCliente = [""]

                if (res?.data?.length > 0) {
                    res?.data?.forEach((c) => { optionsCliente.push(c.nome_fantasia) })
                }

                this.setState({ clientes: optionsCliente })
            })
            .catch(err => console.log(err))
    }

    comandoRele = (imei, status) => {
        document.getElementById(imei).style.display = 'none'
        let userInfo = auth.getUserInfo();
        let bodyFormData = new FormData();

        bodyFormData.append('rele', status)

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/comando/' + imei + '/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(response => { console.log(response) })
            .catch(error => { console.log(error) })
    }

    handleSelect = (e) => {
        this.setState({ opSelecionada: e })
    }

    getReleData = (modelo) => {
        this.setState({ opSelecionada: null })
        let path

        switch (String(modelo)) {
            default:
                path = 'comando'
                break
            case '1':
                path = 'comando'
                break
            case '5':
                path = 'comando_konnect'
                break
            case '6':
                path = 'comando-ks'
                break
            case '11':
                path = 'comando-nit20li'
                break
            case '12':
                path = 'comando-bin'
                break
            case '14':
                path = 'comando-heth'
                break
            case '15':
                path = 'comando-its'
                break
            case "24":
                path = 'comando_konnectl'
                break
            case "25":
                path = 'comando-afiralink'
                break
        }

        const { eqSelecionado } = this.state
        let userInfo = auth.getUserInfo()
        
        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + `/${path}/${eqSelecionado.imei}/`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(async res => {
            let options = []
            let status
            if (Array.isArray(res.data?.status)) {
                status = res.data?.status
            } else {
                status = []
                status.push(res.data?.status)
            }
            status.map((rele, i) => {
                options.push({ value: i + 1, label: `Rele ${i + 1}`, estado: rele })

                return null
            })
            this.setState({
                releOptions: options,
                opSelecionada: options?.[0] ?? null
            })
        }).catch(err => console.error(err))
    }

    atualizarRele = (modelo) => {
        let { eqSelecionado, opSelecionada } = this.state
        const userInfo = auth.getUserInfo()
        let url, path

        switch (String(modelo)) {
            default:
                path = 'comando'
                break
            case '1':
                path = 'comando'
                break
            case '5':
                path = 'comando_konnect'
                break
            case '6':
                path = 'comando-ks'
                break
            case '11':
                path = 'comando-nit20li'
                break
            case '12':
                path = 'comando-bin'
                break
            case '14':
                path = 'comando-heth'
                break
            case '15':
                path = 'comando-its'
                break
            case '19':
                path = 'comando-mestria'
                break
            case "24":
                path = 'comando_konnectl'
                break
            case "25":
                path = 'comando-afiralink'
                break
            case "27":
                path = 'comando-bin'
                break
        }

        let data = new FormData()
        data.append('rele', opSelecionada.value)
        data.append('status', opSelecionada.estado ? "0" : "1")

        opSelecionada['estado'] = !opSelecionada.estado
        this.setState({ opSelecionada })

        if (!path) return
        url = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + `/${path}/${eqSelecionado.imei}/`

        axios({
            method: 'POST',
            url: url,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(() => this.getReleData(modelo))
            .catch(err => console.error(err))
    }

    render() {
        const columns = [{
            Header: 'Cliente',
            accessor: 'item__monitorado__cliente__nome_fantasia',
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}>
                    {this.state.clientes.map(cliente => <option value={cliente}>{cliente}</option>)}
                </select>
            /*Cell: ({ original }) => {
                if (original.item__monitorado__cliente__id != null){
                    return (<Link to={"/cliente/" + original.item__monitorado__cliente__id + "/editar/"} >{original.item__monitorado__cliente__nome_fantasia}</Link>)
                }else{
                    return "-"
                }
            }*/
        }, {
            Header: 'Monitorado',
            accessor: 'item__monitorado__nome',
            /*Cell: ({ original }) => {
                if (original.item__monitorado__id != null){
                    return (<Link to={"/monitorado/" + original.item__monitorado__id + "/editar/"} >{original.item__monitorado__nome}</Link>)
                }else{
                    return "-"
                }
            }*/
        }, {
            Header: 'Imei',
            accessor: 'imei',
            Cell: ({ original }) => {
                return (<Link to={"/equipamento/" + original.imei + "/status/"} >{original.imei}</Link>)
            }
        }, {
            Header: 'Última comunicação',
            accessor: 'ultimo_evento.data_hora_dispositivo',
            filterable: false,
            sortable: false,
            Cell: ({ original }) => {
                if (original.ultimo_evento.data_hora_dispositivo) {
                    let data_in_timezone_user = moment.tz(original.ultimo_evento.data_hora_dispositivo, 'UTC')
                    let data_formatada = data_in_timezone_user.tz(auth.getUserInfo().timezone).format('DD/MM/YYYY HH:mm:ss')
                    return (<ModalUltimoPacote imei={original.imei} label={data_formatada} />)
                } else {
                    return <div>-</div>
                }
            }
        }, {
            Header: 'Modelo',
            accessor: 'modelo__modelo',
            maxWidth: 110,
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}>
                    <option value="">Todos</option>
                    <option value="Hidrohub">Hidrohub</option>
                    <option value="KONECT">Konect</option>
                    <option value="HG Virtual">HG Virtual</option>
                    <option value="HG4">HG4</option>
                    <option value="JE-02">JE-02</option>
                    <option value="HGETH">HGETH</option>
                    <option value="ITC 100">ITC 100</option>
                    <option value="NIT 20 LI">NIT 20 LI</option>
                    <option value="KS-3000">KS-3000</option>
                    <option value="ITS 312">ITS 312</option>
                </select>
        }, {
            Header: 'Operadora',
            accessor: 'operadora__nome',
            maxWidth: 110
        }, {
            Header: 'Relé',
            accessor: 'ultimo_evento.rele',
            filterable: false,
            Cell: ({ original }) => {
                if (Array.isArray(original?.ultimo_evento?.rele)) {
                    let html = original.ultimo_evento.rele.map((element, key) => {
                        if (element === 1) {
                            return (
                                <span key={key} className="rele-bullet rele-bullet-on"></span>
                            )
                        } else {
                            return (
                                <span key={key} className="rele-bullet rele-bullet-off"></span>
                            )
                        }
                    })
                    return (
                        <div style={{ paddingTop: "6px" }}>
                            {html}
                        </div>
                    )
                } else {
                    let status = String(original.ultimo_evento.rele) === '1'
                    if (status) {
                        return (
                            <div style={{ paddingTop: "6px" }}>
                                <span className="rele-bullet rele-bullet-on"></span>
                            </div>
                        )
                    } else {
                        return (
                            <div style={{ paddingTop: "6px" }}>
                                <span className="rele-bullet  rele-bullet-off"></span>
                            </div>
                        )
                    }
                }
            },
            maxWidth: 85
        }, {
            Header: '',
            accessor: 'acao',
            filterable: false,
            sortable: false,
            Cell: ({ original }) => {
                return (
                    <div
                        onClick={async () => {
                            await this.setState({ releModal: true, eqSelecionado: original })
                            this.getReleData(original.modelo__id)
                        }} className="hdv-action-list" style={{ textAlign: "center" }}>
                        <i className="fa fa-check-circle"></i> {(Array.isArray(original?.ultimo_evento?.rele)) ? "Alterar Relés" : "Alterar Relé"}
                    </div>
                )
            }
        }]

        const { Option } = components
        const IconOption = (props) => (
            <Option {...props}>
                <b id="font">{props.data.label}</b>
                <span>
                    <i style={{ fontSize: '16px' }} className="fa fa-square"></i>
                    <b style={{ color: '#222', fontWeight: 'normal' }}>{props.data.estado ? "Ligado" : "Desligado"}</b>
                </span>
            </Option>
        )

        const colourStyles = {
            option: (styles, { data }) => {
                return {
                    ...styles,
                    color: data.estado ? '#2bb33f' : "#cf2b2b",
                    display: 'flex',
                    justifyContent: 'space-between'
                }
            },
        }

        const { opSelecionada, eqSelecionado } = this.state
        return (
            <>
                <Modal
                    isOpen={this.state.releModal}
                    onClosed={() => this.setState({ opSelecionada: false, eqSelecionado: false })}
                    toggle={() => this.setState({ releModal: false })} >
                    <ModalHeader toggle={() => this.setState({ releModal: false })}>
                        Alterar Relés - {eqSelecionado.imei}
                    </ModalHeader>
                    <ModalBody>
                        {eqSelecionado ?
                            <div className="row">
                                <div className="col-md-9">
                                    <label>Selecione o Relé</label>
                                    <Select
                                        components={{ Option: IconOption }}
                                        placeholder=""
                                        value={this.state.opSelecionada}
                                        options={this.state.releOptions}
                                        onChange={(e) => this.handleSelect(e)}
                                        styles={colourStyles}
                                    />
                                </div>
                                <div className="col-md-3">
                                    {opSelecionada ?
                                        <label>Estado<br />
                                            <input
                                                style={{ marginTop: '8px' }}
                                                type="checkbox"
                                                checked={this.state.opSelecionada.estado}
                                                onChange={() => this.atualizarRele(eqSelecionado.modelo__id)}
                                            />
                                        </label>
                                        : null
                                    }
                                </div>
                            </div>
                            : null}
                        <div className="float-right" style={{ marginTop: '35px' }}>
                            <Button
                                color="primary"
                                onClick={() => this.setState({ releModal: false })}>
                                Concluído
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
                <div className="hdv-render-div">
                    <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                        <h4>Última Comunicação</h4>
                        <br />
                        <ReactTable
                            ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                            manual
                            filterable
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            onFetchData={this.getData}
                            columns={columns}
                            defaultPageSize={10}
                            previousText={'Anterior'}
                            nextText={'Próximo'}
                            loadingText={'Carregando...'}
                            noDataText={'Nenhum registro para exibir'}
                            pageText={'Página'}
                            ofText={'de'}
                            rowsText={'linhas'}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default ListaEquipamentos;