const config = {
    //base_api_url: "http://3.236.75.204/",
    //base_api_url: "http://127.0.0.1:8000/",
    //base_api_url: "http://10.0.0.113:8000/",
    //base_api_url: "http://192.168.86.175:8000/",
    //base_api_url: "http://10.0.0.140:8005/",
    base_api_url: "https://api.hidroview.com.br/",
    //base_api_url: 'http://localhost:8000/',
    api_version: "v1",
    client_id: "6CiYtWNVxFxN28eS1VggRrGdpBouIGL3JYUKsNde",
    client_secret: "YCC7cebn6KgsnIiilouJddOlSjqP0gR6ebatZ5OQJHSLgiCGv2KvUvgadKJ92clx58B3IQSm52CPEaCgxcV4fIxCkBrVsXQZ1nswtIeQS3ZjnmTGSpmFPeOVjlpNVGxv",
    grant_type: "password"
}

export default config;