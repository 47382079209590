import  React, { Component } from 'react';
import BulletChart from '../BulletChart'

class MaximoAtual extends Component {

    state = {
        params: {
            descricao: "Maximo x Atual",
            comunicacao: false,
            data: {
                size: 100,
                bars: [
                    { title: 'Máximo', value: 100, css: '' },
                    { title: 'Atual', value: 50, css: 'blue' }
                ],
                markers: [
                    { title: 'Limite', value: 75, css: 'orange' },
                ],
                ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                showLegend: true
            }

        }
    }

    componentWillMount = () => {
        console.log(this.props.datawgt)
        if(this.props.datawgt){
            let params = this.state.params
            params['descricao'] = this.props.datawgt.descricao
            params.data['bars'] = [
                { title: 'Máximo', value: 100, css: '' },
                { title: 'Atual', value: (100 / parseFloat(this.props.datawgt.params['valor_maximo'])) * parseFloat(this.props.datawgt.value), css: 'blue' }
            ]
            params.data['markers'] = [
                { title: 'Limite', value: (100 / parseFloat(this.props.datawgt.params['valor_maximo'])) * parseFloat(this.props.datawgt.params['valor_alerta']), css: 'orange' },
            ]
            this.setState({params: params})
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if(this.props.datawgt.value !== nextProps.datawgt.value){
            let params = this.state.params
            params['descricao'] = nextProps.datawgt.descricao
            params.data['bars'] = [
                { title: 'Máximo', value: 100, css: '' },
                { title: 'Atual', value: (100 / parseFloat(nextProps.datawgt.params['valor_maximo'])) * parseFloat(nextProps.datawgt.value), css: 'blue' }
            ]
            params.data['markers'] = [
                { title: 'Limite', value: (100 / parseFloat(nextProps.datawgt.params['valor_maximo'])) * parseFloat(nextProps.datawgt.params['valor_alerta']), css: 'orange' },
            ]
            this.setState({params: params})
        }
    }

    render() {
        console.log(this.state)
        return (
            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                <div className="hdv-top-bar-bloco">
                {(this.state.params.comunicacao === false) 
                ? 
                <i style={{color: "limegreen", zIndex: 999999, position: "relative"}} alt="Informação Atualizada"  title="Informação Atualizada" class="fa fa-square-full"></i>
                :    
                <i style={{color: "tomato", zIndex: 999999, position: "relative"}} alt="Informação com Atraso"  title="Informação com Atraso" class="fa fa-square-full"></i>
                }
                {decodeURI(this.state.params.descricao)}
                </div>
                <div className="box-gauge">
                    <div className="box-gauge-margintop" style={{width: '90%'}}>
                        <BulletChart data={this.state.params.data} />
                    </div>
                </div>
            </div>
        )
    }

}

export default MaximoAtual;